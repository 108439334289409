import { isArray, isNumber } from 'lodash';
import {
    ScoreEnumInputVariable,
    ScoreSelectionCriteria,
    Session,
    SessionScore,
    SessionScoreAdministration,
    SessionScoreDosage,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
    SessionScoreTemplate,
    SessionState,
    SessionTick,
    SessionType,
    SessionVariables,
    Wavepath,
    WavepathType,
} from 'wavepaths-shared/core';
import { makeScoreExpressionEvaluator } from 'wavepaths-shared/scoreExpressions';
import {
    getSessionDuration,
    getSessionPostludeDuration,
    getSessionPreludeDuration,
} from 'wavepaths-shared/sessionUtils';

import { getDefaultSessionDurationForModality } from './modalitiesOld';
import { MEDICINE_SPECIFIC_VARIABLES, SESSION_TYPE_DEFAULTS } from './sessionDefaults';

const getDefaultVariablesForScore = (score: SessionScoreTemplate): { [variable: string]: number | string } =>
    score.variables?.inputs?.reduce((variables, inputVariable) => {
        let defaultValue = inputVariable.defaultValue;
        if (score.selectionCriteria && inputVariable.name in score.selectionCriteria) {
            const crit = score.selectionCriteria[inputVariable.name as keyof ScoreSelectionCriteria];
            defaultValue = (isArray(crit) ? crit[0] : crit) as string | number;
        }
        return {
            ...variables,
            ...(defaultValue && { [inputVariable.name]: defaultValue }),
        };
    }, {}) ?? {};

export const getDefaultVariablesForSession = ({
    sessionType,
    medicine,
    score,
}: {
    sessionType: SessionType;
    medicine?: SessionScoreModality;
    score?: SessionScoreTemplate;
}): SessionVariables => {
    const vars = medicine && MEDICINE_SPECIFIC_VARIABLES[medicine];
    const defaultVariables: SessionVariables = {
        useEmotionalStateForms: 'true',
        ...vars,
        ...SESSION_TYPE_DEFAULTS[sessionType],
        ...(score && getDefaultVariablesForScore(score)),
    };

    return {
        ...defaultVariables,
        ...(medicine &&
            !defaultVariables.totalDuration &&
            sessionType === SessionType.ONE_ON_ONE && {
                totalDuration: getDefaultSessionDurationForModality(medicine, {
                    administration: defaultVariables.administration as SessionScoreAdministration,
                    dosage: defaultVariables.dosage as SessionScoreDosage,
                    ...(score && { score }),
                }),
            }),
    };
};

export const removeWavepaths = (wavepaths: Wavepath[], toRemoveIndex: number, removeNumber: number): Wavepath[] => {
    if (toRemoveIndex > -1 && toRemoveIndex + removeNumber < wavepaths.length) {
        wavepaths.splice(toRemoveIndex, removeNumber);
    }
    return wavepaths;
};

export const getSessionDurationFromWavepaths = (wavepaths: Wavepath[]): number => {
    let duration = 0;
    for (const w of wavepaths) {
        if (w.type === WavepathType.SCHEDULED) {
            duration = w.plan?.toTime ?? 0;
        }
    }
    return duration;
};

export const isSessionCancelled = (timeElapsedInMinutes: number | null, totalDurationInMinutes?: number): boolean => {
    if (!isNumber(timeElapsedInMinutes) || !isNumber(totalDurationInMinutes)) return true;
    return totalDurationInMinutes - timeElapsedInMinutes > 10;
};

export function getEnumValue(session: Session, varName: string): string | undefined {
    const val = session.variableInputs[varName];
    const vr = session.score.variables?.inputs?.find((i) => i.name === varName) as ScoreEnumInputVariable | undefined;
    return vr?.options.find((o) => o.value === val)?.label;
}

export const getScheduledStart = (session: Session): Session['scheduledStart'] => session.scheduledStart;

export const getScheduledEnd = (session: Session, duration: number): Date | 0 => {
    const scheduledStart = getScheduledStart(session);
    return isNumber(scheduledStart) ? new Date(scheduledStart + duration) : 0;
};

export const getScore = (session: Session): Session['score'] => session.score;

export const getSelectionCriteria = (session: Session): ReturnType<typeof getScore>['selectionCriteria'] =>
    getScore(session).selectionCriteria;

export const getMedicine = (session: Session): SessionScoreModality | SessionScoreModality[] | undefined =>
    getSelectionCriteria(session)?.medicine;

export const getEmotionalIntensity = (session: Session): SessionScoreEmotionalIntensity | undefined =>
    getSelectionCriteria(session)?.emotionalIntensity;

export function getZeroTick(score: SessionScore, variableInputs: SessionVariables): SessionTick {
    const expr = makeScoreExpressionEvaluator(score, variableInputs, []);
    return {
        sessionState: SessionState.PLANNED,
        timeUntilStart: 0,
        absoluteTime: 0,
        effectiveTime: 0,
        timeSinceInit: 0,
        wallClockTime: 0,
        presetVolume: 0,
        contentStage: 0,
        musicalContent: {
            activeLayers: [],
        },
        preludeDuration: getSessionPreludeDuration(expr),
        sessionDuration: getSessionDuration(expr),
        postludeDuration: getSessionPostludeDuration(expr),
        connectedUserCount: 0,
    };
}
