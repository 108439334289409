import { makeStyles, Theme } from '@material-ui/core';
import { uniqueId } from 'lodash';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { ExperimentalWaveFunction, isScheduledGenerativePathScore, PathScore, PathType } from 'wavepaths-shared/core';

import { pointsToSvgPath } from './svgUtils';
import { getCurvePointsFromWave, TimelineWaveProperties } from './timelineWaveUtils';

interface ITimelineWaveStylesProps {
    opacity: 'full' | 'medium' | 'low';
    dashArray: number[];
}
const opacities = { full: 1, medium: 1, low: 1 };
const useStyles = makeStyles<Theme, ITimelineWaveStylesProps>({
    maskPath: {
        stroke: 'white',
        strokeWidth: 2,
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
        vectorEffect: 'non-scaling-stroke',
        opacity: ({ opacity }) => opacities[opacity],
        strokeDasharray: ({ dashArray }) => dashArray.join(','),
    },
});

interface ITimelineWaveProps {
    wave: TimelineWaveProperties;
    opacity: 'full' | 'medium' | 'low';
    padding?: number;
    onClick?: (wave: TimelineWaveProperties, secondsOffset: number) => void;
    index?: number;
    isSelected?: boolean;
}
export const TimelineWave: React.FC<ITimelineWaveProps> = React.memo(
    ({ wave, padding, onClick, opacity = 'full', index, isSelected = false }) => {
        const id = useMemo(() => uniqueId('wave'), []);
        const points = getCurvePointsFromWave(wave, padding);
        const dashArray = getLineDashArray(wave.wave.pathScore);
        const styles = useStyles({ opacity, dashArray });
        const { d: svgPath, closedPath } = pointsToSvgPath(points);

        const backgroundPad = 1;

        const ref = useRef<SVGGElement>(null);

        useLayoutEffect(() => {
            if (isSelected === true && ref.current) {
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            }
        }, [isSelected, ref.current]);

        return (
            <g
                ref={ref}
                transform={`translate(${wave.x} 0)`}
                onClick={(e) => {
                    console.debug('click wave', wave);
                    let secondsOffset = 0;
                    if (wave.wave.plan?.toTime !== undefined && wave.wave.plan?.fromTime !== undefined) {
                        //console.debug(e.currentTarget.getBoundingClientRect());
                        const xRelative = e.clientX - Math.floor(e.currentTarget.getBoundingClientRect().x);
                        //console.debug(xRelative);
                        secondsOffset = Math.floor(
                            ((xRelative / Math.floor(e.currentTarget.getBoundingClientRect().width)) *
                                ((wave.wave.plan?.toTime ?? Number.MAX_SAFE_INTEGER) -
                                    (wave.wave.plan?.fromTime ?? 0))) /
                                1000,
                        );
                        //console.debug('Seconds offset in wave', secondsOffset);
                    }
                    onClick?.(wave, secondsOffset);
                }}
                data-cy={`timelinewave-${index}`}
            >
                <defs data-cy={wave.width}>
                    <linearGradient id={`lineMask-gradient-${id}`} x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" style={{ stopColor: 'rgb(255,255,255)', stopOpacity: 0.5 }} />
                        <stop offset="100%" style={{ stopColor: 'rgb(255,255,255)', stopOpacity: 1 }} />
                    </linearGradient>

                    <linearGradient id={`valenceArousalPathGradient-${id}`}>
                        {points.map(({ x, z }, idx) => (
                            <stop key={'colorStop-' + idx} offset={x / wave.width} stopColor={z} />
                        ))}
                    </linearGradient>

                    <mask id={`lineMask-${id}`}>
                        <path d={svgPath} className={styles.maskPath} style={{ opacity: opacities.medium }} />
                        <path
                            d={closedPath}
                            fill={`url(#lineMask-gradient-${id})`}
                            style={{ opacity: opacities.medium }}
                        />
                    </mask>
                    <mask id={`lineMaskGeneratingStatus-${id}`}>
                        <path d={svgPath} className={styles.maskPath} />
                        <rect x={wave.width} y="0" width={0} height="100" fill="black" />
                    </mask>
                </defs>
                <rect
                    x={-backgroundPad}
                    y={-backgroundPad}
                    width={wave.width * backgroundPad}
                    height={wave.height * backgroundPad}
                    rx={8}
                    ry={8}
                    fill={`url(#valenceArousalPathGradient-${id})`}
                />
                <rect
                    x={-backgroundPad}
                    y={-backgroundPad}
                    width={wave.width * backgroundPad}
                    height={wave.height * backgroundPad}
                    mask={`url(#lineMaskGeneratingStatus-${id})`}
                    fill={'rgba(43,45,63,0.5)'}
                    style={{ mixBlendMode: 'color-burn' }}
                />
            </g>
        );
    },
);

function getLineDashArray(pathScore: PathScore) {
    if (pathScore.type === PathType.EXPERIMENTAL) {
        if (pathScore.selectionCriteria?.function === ExperimentalWaveFunction.Percussive) {
            return [4, 4];
        } else if (pathScore.selectionCriteria?.function === ExperimentalWaveFunction.StructuredSoothe) {
            return [1, 3];
        }
    }
    if (isScheduledGenerativePathScore(pathScore) && pathScore.mode === 'Percussive') return [4, 4];
    return [1, 0];
}
