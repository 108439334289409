import React, { useState } from 'react';
import {
    Acousticness,
    CoreEmotionalAtmosphere,
    PathScore,
    PathScoreModes,
    ScheduledExperimentalPathScore,
    ScheduledGenerativePathScore,
    ScheduledWavepath,
    SootheModes,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { SegmentedControl } from '@/component-library';

import { WavePreviewMode } from '../WaveEditor/WaveEditor';
import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';
import SootheStructuredWaveSelector from './SootheStructuredWaveSelector';
import SootheUnstructuredWaveSelector from './SootheUnstructuredWaveSelector';

export interface SelectorState {
    mode?: PathScoreModes;
    emotion?: CoreEmotionalAtmosphere;
    intensity?: string;
    tone?: Acousticness;
    ambience?: string;
    music?: string;
    duration: number | string;
}

const selectorStateDefault: SelectorState = {
    mode: 'Unstructured',
    emotion: CoreEmotionalAtmosphere.STILLNESS,
    intensity: 'Low',
    tone: 'Mixed',
    ambience: 'None',
    music: 'Any',
    duration: pathScoreFns.AUTO_VALUE,
};

export type SoothePathScore = (ScheduledGenerativePathScore | ScheduledExperimentalPathScore) & {
    therapeuticDirection: TherapeuticDirection.SOOTHE;
    mode: SootheModes;
};

interface SootheWaveSelectorProps {
    pathScores: SoothePathScore[];
    wavepath: ScheduledWavepath;
    previewMode: WavePreviewMode;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isSoothePathScore = (p: PathScore): p is SoothePathScore => p.direction === TherapeuticDirection.SOOTHE;

function SootheWaveSelector({ pathScores, wavepath, previewMode, onUpdateWave }: SootheWaveSelectorProps): JSX.Element {
    const defaultMode = wavepath.pathScore.mode ?? selectorStateDefault.mode;
    const [mode, setMode] = useState(defaultMode);
    const isStructured = mode === 'Structured';
    return (
        <>
            <SegmentedControl
                name="wave-mode"
                heading={'Mode'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Unstructured', 'Structured'] as PathScoreModes[],
                    pathScoreFns.extractModesFromScores(pathScores),
                )}
                value={mode}
                onChange={setMode}
                info={infoCopy.sootheMode}
            />
            {isStructured ? (
                <SootheStructuredWaveSelector
                    pathScores={pathScores}
                    wavepath={wavepath}
                    previewMode={previewMode}
                    onUpdateWave={onUpdateWave}
                />
            ) : (
                <SootheUnstructuredWaveSelector
                    pathScores={pathScores}
                    wavepath={wavepath}
                    previewMode={previewMode}
                    onUpdateWave={onUpdateWave}
                />
            )}
        </>
    );
}

export default SootheWaveSelector;
