import styled from '@emotion/styled';
import { compareAsc, isThisWeek, isToday, isTomorrow } from 'date-fns';
import { format, isThisMonth, isThisYear } from 'date-fns';
import firebase from 'firebase';
import _ from 'lodash';
import React, { useState } from 'react';
import { SessionHeader } from 'wavepaths-shared/core';

import { isNextWeek, weekStartsOn } from '../../dateUtilsV2';
import ScheduledSessionCard from './ScheduledSessionCard';
import SessionCardSkeleton from './SessionCardSkeleton';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--global-spacing);
`;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Heading = styled.h2`
    padding-left: 24px;
    font-family: var(--font-family-sans);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
`;

interface ScheduledSessionsTableProps {
    sessions: SessionHeader[];
    loading: boolean;
    isAdmin: boolean;
    firebaseUser: firebase.User;
}

const compareSessionsByDate = (left: SessionHeader, right: SessionHeader) => {
    if (!left.scheduledStart) return 1;
    if (!right.scheduledStart) return -1;
    return compareAsc(new Date(left.scheduledStart), new Date(right.scheduledStart));
};

interface IHeaderAndSession {
    header: string;
    sessions: SessionHeader[];
}

interface IScheduledSessionHeader extends SessionHeader {
    scheduledStart: number;
}

export const genArrOfHeadersAndSessions = (sessions: SessionHeader[]): IHeaderAndSession[] => {
    const scheduledSessions = sessions.filter((session) => !!session.scheduledStart) as IScheduledSessionHeader[];
    const unscheduledSessions = sessions.filter((session) => !session.scheduledStart);

    //TODO: DO NOT USE BROWSER Clock for data
    const sessionsToday = scheduledSessions.filter((session) => isToday(session.scheduledStart));
    const sessionsTomorrow = scheduledSessions.filter((session) => isTomorrow(session.scheduledStart));
    const sessionsThisWeek = scheduledSessions.filter((session) =>
        isThisWeek(session.scheduledStart, { weekStartsOn }),
    );
    const sessionsNextWeek = scheduledSessions.filter((session) => isNextWeek(session.scheduledStart));
    const sessionsThisMonth = scheduledSessions.filter((session) => isThisMonth(session.scheduledStart));
    const sessionsThisYear = scheduledSessions.filter((session) => isThisYear(session.scheduledStart));

    const restOfThisWeek: SessionHeader[] = _.differenceWith(
        sessionsThisWeek,
        _.flatten([sessionsToday, sessionsTomorrow]),
        _.isEqual,
    );

    const restOfThisMonth: SessionHeader[] = _.differenceWith(
        sessionsThisMonth,
        _.flatten([sessionsTomorrow, sessionsThisWeek, sessionsNextWeek]),
        _.isEqual,
    );

    const restOfTheYear = _.differenceWith(
        sessionsThisYear,
        _.flatten([sessionsTomorrow, sessionsThisWeek, sessionsNextWeek, sessionsThisMonth]),
        _.isEqual,
    );
    const restOfTheYearGroupedByMonth = _.groupBy(restOfTheYear, (session) => format(session.scheduledStart, 'MMMM'));
    const restOfTheYearMapped = _.map(restOfTheYearGroupedByMonth, (sessions, key) => ({ header: key, sessions }));

    const restOfSessions = _.differenceWith(scheduledSessions, sessionsThisYear, _.isEqual);
    const restOfSessionsGroupedByMonthAndYear = _.groupBy(restOfSessions, (session) =>
        format(session.scheduledStart, "MMM ''yy"),
    );
    const restOfSessionsMapped = _.map(restOfSessionsGroupedByMonthAndYear, (sessions, key) => ({
        header: key,
        sessions,
    }));

    return [
        { header: 'Saved For Later', sessions: unscheduledSessions },
        { header: 'Today', sessions: sessionsToday },
        { header: 'Tomorrow', sessions: sessionsTomorrow },
        { header: 'Later this Week', sessions: restOfThisWeek },
        { header: 'Next Week', sessions: sessionsNextWeek },
        { header: 'Later this Month', sessions: restOfThisMonth },
        ...restOfTheYearMapped,
        ...restOfSessionsMapped,
    ];
};

const ScheduledSessionsTable: React.FC<ScheduledSessionsTableProps> = ({
    sessions,
    loading,
    isAdmin,
    firebaseUser,
}) => {
    const [copiedLinkId, setCopiedLinkId] = useState<string>();

    const sessionsSorted = sessions.sort(compareSessionsByDate);
    const headersAndSessions = genArrOfHeadersAndSessions(sessionsSorted).filter(
        ({ sessions }) => sessions.length !== 0,
    );

    if (loading) {
        return (
            <div style={{ width: '100%' }}>
                <SessionCardSkeleton />
            </div>
        );
    }

    if (sessions.length === 0) {
        return <></>;
    }

    return (
        <Container>
            {headersAndSessions.map(({ header, sessions }, idx) => (
                <Section key={`${header}${idx}`}>
                    <Heading>{header}</Heading>
                    {sessions.map((session) => (
                        <div key={`${session.id}`}>
                            <ScheduledSessionCard
                                session={session}
                                isAdmin={isAdmin}
                                copiedLinkId={copiedLinkId}
                                setCopiedLinkId={setCopiedLinkId}
                                firebaseUser={firebaseUser}
                            />
                        </div>
                    ))}
                </Section>
            ))}
        </Container>
    );
};

export default ScheduledSessionsTable;
