import React, { useMemo } from 'react';

interface CustomAvatarProps {
    src?: string;
    size: number;
    initials: string;
    alt?: string;
}

const CustomAvatar: React.FC<CustomAvatarProps> = ({ alt, src, size, initials }) => {
    return (
        <div
            style={{
                width: size,
                height: size,
                borderRadius: '50%',
                color: '#fafafa',
                backgroundColor: src ? 'transparent' : '#bdbdbd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                fontSize: '1.25rem',
                position: 'relative',
            }}
        >
            {src ? (
                <img
                    src={src}
                    alt={alt}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    crossOrigin="anonymous"
                />
            ) : (
                <span>{initials}</span>
            )}
        </div>
    );
};

const ProfileImage = ({ name, url, size = 40 }: { name: string; url?: string; size?: number }) => {
    const initials = useMemo(() => {
        const parts = name.trim().split(/\s+/);
        return ((parts[0]?.[0] || '') + (parts.length > 1 ? parts[1]?.[0] || '' : '')).toUpperCase();
    }, [name]);

    return (
        <CustomAvatar alt={name} src={url} size={size} initials={initials}>
            {initials}
        </CustomAvatar>
    );
};
export default ProfileImage;
