import firebase from 'firebase';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { APISessionStatus } from 'wavepaths-shared/core';

import Lightbox from '@/components/Lightbox';
import SessionCard from '@/components/SessionCard';
import TemplateCard from '@/components/Templates/TemplateCard';
import TemplateCardGrid from '@/components/Templates/TemplateCardGrid';
import { QuickStartTemplateCardLoader, ScoreTemplateCardLoader } from '@/components/Templates/TemplateCardLoader';
import TemplateCardRow from '@/components/Templates/TemplateCardRow';
import { SessionCacheManagerContext } from '@/hooks/useAudioCache';
import { useClients } from '@/hooks/useClients';
import useSavedTemplates from '@/hooks/useSavedTemplates';

import { getRelativeSessionReplayLink } from '../../formUtils/formUtils';
import IconArrowUpRight from '../../images/icons/icon-arrow-up-right.svg';
import MendelPortraitImage from '../../images/mendel-portrait.png';
import MendelSignatureImage from '../../images/mendel-signature.png';
import { LayoutContainer } from '../../LayoutContainer';
import NewsLatestContainer from '../news/NewsLatest';
import useSessions from '../sessions/useSessions';
import { useRecentlyUsedTemplates } from './useRecentlyUsedTemplates';

export const CardRowSessionContainer = styled.div`
    width: 200px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--global-spacing);
`;

const ReturningUserContent = styled.div`
    display: contents;
`;

const Hero = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: var(--global-spacing);
    padding-top: 8px;

    @media (max-width: 860px) {
        flex-direction: column;
    }
`;

const QuickStart = styled.div`
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
`;

const QuickStartTemplate = styled.div`
    width: 280px;
    display: inline-flex;
    padding: 24px;
    background-color: var(--color-primary);
    border-radius: 24px;
`;

const Launchpad = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const LaunchpadItems = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 24px;
    padding: 24px;
    background: var(--color-background-highlight);
    border-radius: 24px;
`;

const LaunchpadItem = styled.div`
    position: relative;
    flex: 1 1 40%;
    min-height: 132px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: border-color 0.15s ease;
    border-radius: 16px;
    text-align: center;
    cursor: pointer;
    &:hover {
        border-color: rgba(0, 0, 0, 0.4);
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        background-image: url('${IconArrowUpRight}');
        background-size: 24px;
        background-repeat: no-repeat;
        opacity: 0.2;
        transition: opacity 0.15s ease;
    }
    &:hover:after {
        opacity: 0.4;
    }
`;

const LaunchpadNumber = styled.h2``;

const LaunchpadLabel = styled.h3`
    font-size: var(--font-size-large);
`;

const RecentSessions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const FirstTimeUserContent = styled.div`
    display: contents;
`;

const Intro = styled.div`
    display: flex;
    gap: var(--global-spacing);

    @media (max-width: 860px) {
        flex-direction: column;
    }
`;

const WelcomeMessage = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 16px;
    padding-left: 32px;
    h1 {
        font-family: var(--font-family-sans);
    }
    p {
        font-size: var(--font-size-small);
        max-width: 440px;
        margin: 0;
    }

    @media (max-width: 860px) {
        padding-left: 0;
    }
`;

const VideoButton = styled.button`
    display: inline-flex;
    align-items: center;
    height: 44px;
    padding: 0 24px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background: transparent;
    margin-top: 8px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    cursor: pointer;
    transition: border-color 0.15s;
    &:hover {
        border-color: rgba(0, 0, 0, 0.8);
    }
`;

const MendelMessage = styled.div`
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    border-radius: 40px;
    background-color: var(--color-background-highlight);

    @media (max-width: 860px) {
        width: 100%;
    }
`;

const MendelMessageBody = styled.div`
    p {
        font-size: var(--font-size-small);
    }
`;

const MendelMessageFooter = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    img {
        width: 80px;
        height: 80px;
    }
`;

const MendelSignOff = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    img {
        width: 85px;
        height: auto;
    }
    p {
        font-size: var(--font-size-small);
        margin: 0;
    }
`;

const GetStarted = styled.div`
    display: flex;
    gap: 32px;
    border-radius: 40px;
    padding: 32px;
    background-color: var(--color-primary);
    color: #fff;
    flex-direction: column;

    @media (min-width: 1260px) {
        flex-direction: row;
    }
`;

const GetStartedHeader = styled.div`
    display: flex;
    flex-direction: column;
    h2 {
        font-size: var(--font-size-xxlarge);
        margin-bottom: var(--font-size-small);
    }
    p {
        font-size: var(--font-size-small);
    }

    @media (min-width: 1260px) {
        width: 280px;
    }
`;

const GetStartedLink = styled.button`
    display: inline;
    padding: 0;
    background: transparent;
    border: none;
    color: #fff;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-medium);
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
`;

const SessionCardRow = styled.div`
    display: flex;
    gap: 24px;
    padding-bottom: 20px;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
`;

function Home({ firebaseUser }: { firebaseUser: firebase.User }) {
    const ongoing = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ACTIVE,
        paginated: true,
    });

    const endedSessions = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ENDED,
        paginated: true,
    });

    const sessionCacheManager = useContext(SessionCacheManagerContext);
    if (!sessionCacheManager) throw new Error('No SessionCacheManagerContext');

    const cachedSessions = sessionCacheManager.sessionList;

    const history = useHistory();

    const [openVideoLightbox, setOpenVideoLightbox] = useState(false);

    const returningUser = !endedSessions.loading && endedSessions.sessions.length > 0;
    const newUser = !endedSessions.loading && endedSessions.sessions.length === 0;

    const savedTemplates = useSavedTemplates({ fbUser: firebaseUser });
    const clients = useClients({ fbUser: firebaseUser, query: '' });
    const newUserTemplateIds = ['open-heart-90', 'bw-heros-journey', 'ambience-journey-medium'];

    const recentlyUsedTemplates = useRecentlyUsedTemplates(4, endedSessions, savedTemplates.savedTemplates);

    return (
        <Container>
            {returningUser && (
                <ReturningUserContent>
                    <Hero>
                        <QuickStart>
                            <h3>Quick start</h3>
                            <QuickStartTemplate>
                                <QuickStartTemplateCardLoader
                                    savedTemplates={savedTemplates.savedTemplates}
                                    fbUser={firebaseUser}
                                    variant="light"
                                />
                            </QuickStartTemplate>
                        </QuickStart>
                        <Launchpad>
                            <h3>Your activity</h3>
                            <LaunchpadItems>
                                <LaunchpadItem
                                    onClick={() => {
                                        history.push({
                                            pathname: '/sessions',
                                        });
                                    }}
                                >
                                    <LaunchpadNumber>{ongoing.totalCount}</LaunchpadNumber>
                                    <LaunchpadLabel>
                                        Active
                                        <br /> Sessions
                                    </LaunchpadLabel>
                                </LaunchpadItem>
                                <LaunchpadItem
                                    onClick={() => {
                                        history.push({
                                            pathname: '/sessions',
                                            search: '?tabIndex=2',
                                        });
                                    }}
                                >
                                    <LaunchpadNumber>{endedSessions.totalCount}</LaunchpadNumber>
                                    <LaunchpadLabel>
                                        Completed
                                        <br /> Sessions
                                    </LaunchpadLabel>
                                </LaunchpadItem>
                                <LaunchpadItem
                                    onClick={() => {
                                        history.push({
                                            pathname: '/templates/saved',
                                        });
                                    }}
                                >
                                    <LaunchpadNumber>{savedTemplates.totalCount}</LaunchpadNumber>
                                    <LaunchpadLabel>
                                        Saved
                                        <br /> Templates
                                    </LaunchpadLabel>
                                </LaunchpadItem>
                                <LaunchpadItem
                                    onClick={() => {
                                        history.push({
                                            pathname: '/clients',
                                        });
                                    }}
                                >
                                    <LaunchpadNumber>{clients.totalCount}</LaunchpadNumber>
                                    <LaunchpadLabel>
                                        Current
                                        <br /> Clients
                                    </LaunchpadLabel>
                                </LaunchpadItem>
                            </LaunchpadItems>
                        </Launchpad>
                    </Hero>
                    {endedSessions.totalCount || ongoing.totalCount ? (
                        <RecentSessions>
                            <h3>Recently used Templates</h3>
                            <TemplateCardGrid>
                                {recentlyUsedTemplates.map((t, idx) =>
                                    t.type === 'saved' ? (
                                        <TemplateCard
                                            key={idx + '-' + t.template.id}
                                            onCardClick={() => {
                                                window.location.href = '/templates/saved/' + t.template.id;
                                            }}
                                            title={t.template.name}
                                            subtitle={t.template.subtitle}
                                            intensity={t.template.intensity}
                                            minDurationMins={Math.ceil(t.template.durationMins.min)}
                                            maxDurationMins={Math.floor(t.template.durationMins.max)}
                                            emotionalities={t.template.emotionalities}
                                            id={t.template.id}
                                        />
                                    ) : (
                                        <ScoreTemplateCardLoader
                                            key={idx + '-' + t.id}
                                            templateId={t.id}
                                            fbUser={firebaseUser}
                                            variant="dark"
                                        />
                                    ),
                                )}
                            </TemplateCardGrid>
                        </RecentSessions>
                    ) : null}
                    {cachedSessions?.length ? (
                        <RecentSessions>
                            <h3>Downloaded Sessions</h3>
                            <SessionCardRow>
                                {[...cachedSessions].reverse().map((s, idx) => (
                                    <SessionCard
                                        key={idx + '-' + s.id}
                                        onCardClick={() => {
                                            history.push({
                                                pathname: getRelativeSessionReplayLink(s.id),
                                            });
                                        }}
                                        title={
                                            String(s.variableInputs.name).length
                                                ? String(s.variableInputs.name)
                                                : s.score.name
                                        }
                                        emotionalities={s.emotionalities as any}
                                        duration={s.variableInputs.totalDuration as number}
                                        variant={'dark'}
                                        intensity={s.score.intensity as any}
                                        id={s.id}
                                    />
                                ))}
                            </SessionCardRow>
                        </RecentSessions>
                    ) : null}
                    <NewsLatestContainer />
                </ReturningUserContent>
            )}
            {newUser && (
                <FirstTimeUserContent>
                    <Intro>
                        <WelcomeMessage>
                            <h1>Welcome to a world of music guided by you</h1>
                            <p>
                                You’re moments away from your first adaptive music Session, generated from a catalogue
                                of sounds crafted by world-class musicians, and guided by you.
                            </p>
                            <VideoButton onClick={() => setOpenVideoLightbox(true)}>
                                Watch our intro video (8mins)
                            </VideoButton>
                        </WelcomeMessage>
                        <MendelMessage>
                            <MendelMessageBody>
                                <p>We hope your first experience with Wavepaths is an overwhelmingly positive one.</p>
                                <p>
                                    If you have any comments or questions, please don’t hesitate to reach me personally
                                    at <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>
                                </p>
                            </MendelMessageBody>
                            <MendelMessageFooter>
                                <img src={MendelPortraitImage} alt="Portrait of Mendel Kaelen" />
                                <MendelSignOff>
                                    <img src={MendelSignatureImage} alt="Mendel's Signature" />
                                    <p>
                                        Mendel Kaelen, PhD <br />
                                        Founder & CEO
                                    </p>
                                </MendelSignOff>
                            </MendelMessageFooter>
                        </MendelMessage>
                    </Intro>
                    <GetStarted>
                        <GetStartedHeader>
                            <h2>Getting Started</h2>
                            <p>
                                Run your first Session using one of our pre-designed Templates and get to grips with the
                                controls.
                            </p>
                            <p>
                                Need something more specific? <br />
                                <GetStartedLink
                                    onClick={() => {
                                        history.push({
                                            pathname: '/templates',
                                        });
                                    }}
                                >
                                    Explore all Templates
                                </GetStartedLink>
                            </p>
                        </GetStartedHeader>
                        <TemplateCardRow>
                            {newUserTemplateIds.map((templateId) => (
                                <ScoreTemplateCardLoader
                                    quickStart
                                    key={templateId}
                                    templateId={templateId}
                                    fbUser={firebaseUser}
                                    variant="light"
                                />
                            ))}
                        </TemplateCardRow>
                    </GetStarted>
                    <Lightbox isOpen={openVideoLightbox} onClose={() => setOpenVideoLightbox(false)}>
                        <iframe src="https://www.loom.com/embed/b9bfaf15519240d6a821f39e0f760b9b" allowFullScreen />
                    </Lightbox>
                </FirstTimeUserContent>
            )}
        </Container>
    );
}

function HomeWithNav({ firebaseUser }: { firebaseUser: firebase.User }) {
    return (
        <LayoutContainer>
            <Home firebaseUser={firebaseUser} />
        </LayoutContainer>
    );
}

export default HomeWithNav;
