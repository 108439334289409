import styled from '@emotion/styled';
import CodeIcon from '@material-ui/icons/CodeRounded';
import { format } from 'date-fns';
import formatDuration from 'format-duration';
import { isNumber, range, startCase } from 'lodash';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import {
    AddStageContentEvent,
    AnonymousUser,
    EndOfSessionFeedback,
    EnterStageEvent,
    FailedToFindStageContentEvent,
    FirebaseUser,
    LegacyMidSessionFeedback,
    RecordSessionFeedbackEvent,
    SessionScore,
    TimestampedSessionEvent,
} from 'wavepaths-shared/core';

import { Button, Typography } from '@/component-library';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';

import { SessionLogLayerTable } from './SessionLogLayerTable';
import { formatPathName, getCEAColourAtIndex, getRelativeTime } from './sessionLogUtils';

interface SessionLogEventProps {
    log: TimestampedSessionEvent[];
    index: number;
    isPastEvent: boolean;
    score: SessionScore;
}
export const SessionLogEvent: React.FC<SessionLogEventProps> = React.memo(({ log, index, isPastEvent, score }) => {
    const event = log[index];

    const getRowColour = () => {
        return getCEAColourAtIndex(index, log);
    };

    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded((e) => !e);

    return (
        <Container isPastEvent={isPastEvent}>
            <Time rowColour={getRowColour()}>
                <Typography variant="body2">{format(event.timestamp, 'HH:mm:ss')}</Typography>
                <Typography variant="body3">+{formatDuration(getRelativeTime(event, log))}</Typography>
            </Time>
            <Actions>
                <Button size="xs" icon={<CodeIcon fontSize="small" />} onClick={toggleExpanded} />{' '}
            </Actions>
            <Content variant="body2" component="div">
                <GenericErrorBoundary>
                    {getEventDescription(log, index, score)}
                    {expanded && (
                        <ReactJson src={event} displayDataTypes={false} collapsed={1} style={{ marginTop: 16 }} />
                    )}
                </GenericErrorBoundary>
            </Content>
        </Container>
    );
});
// TODO: move legacy types here
function getEventDescription(log: TimestampedSessionEvent[], index: number, score: SessionScore) {
    const evt = log[index];
    switch (evt.event) {
        case 'startSession':
            return <>Starting session.</>;
        case 'startSessionTimers':
            return <>Starting timers.</>;
        case 'enterStage':
            return <>Entering {getStageName(evt, score)}.</>;
        case 'addStageContent':
            return (
                <>
                    Set layers for {getStageName(evt, score)}.
                    <SessionLogLayerTable log={log as TimestampedSessionEvent[]} atEventIndex={index} />
                </>
            );
        case 'failedToFindStageContent':
            return <>Failed to find any valid content for {getStageName(evt, score)}.</>;

        case 'reviseSessionPlan':
            return <>Revised timing plan</>;
        case 'recordSessionFeedback':
            return renderFeedback(evt);
        case 'stopSession':
            return <>End session</>;
        case 'stoppedSession':
            return <>Ended session</>;
        default:
            return <>{evt.event}</>;
    }
}

function renderFeedback(evt: RecordSessionFeedbackEvent) {
    if (evt.endOfSession) {
        const feedback = evt.feedback as EndOfSessionFeedback;
        return (
            <div className="sessionLogEvents--content">
                Recorded user end-of-session feedback {renderFbUser(evt.fromUser)}: <br />
                Overall experience: {ratingStars(feedback.rating)}
                <br />
                Technical quality (no glitches): {ratingStars(feedback.technicalQualityRating)}
                <br />
                Music quality : {ratingStars(feedback.musicQualityRating)}
                <br />
                Ease of use: {ratingStars(feedback.easeOfUseRating)}
                <br />
                {feedback.feedback && <>"{feedback.feedback}"</>}
            </div>
        );
    } else {
        switch (evt.feedback.type) {
            case 'feedbackLogged':
                return (
                    <>
                        Logged feedback: {renderFbUser(evt.fromUser)} {startCase(evt.feedback.feedbackType)}
                    </>
                );
            case 'feedbackCategorised':
                return (
                    <>
                        Categorised feedback{' '}
                        {evt.feedback.newTags.length > 0 && <>adding {evt.feedback.newTags.join(', ')}</>}
                        {evt.feedback.removedTags.length > 0 && <>removing {evt.feedback.removedTags.join(', ')}</>}
                    </>
                );
            case 'feedbackTextAdded':
                return <>Added text to feedback: "{evt.feedback.text}"</>;
            case 'feedbackCancelled':
                return <>Cancelled feedback</>;
            case 'feedbackConcluded':
                return <>Concluded feedback</>;
            case 'feedbackSubmitted':
                return (
                    <>
                        Recorded user mid-session feedback {renderFbUser(evt.fromUser)}:{' '}
                        {startCase(evt.feedback.feedbackType)}
                        <br />
                        {evt.feedback.text && `Text: "${evt.feedback.text}"`}
                        <br />
                        {!!evt.feedback.tags.length && `Categories: "${evt.feedback.tags.join(', ')}"`}
                    </>
                );
            default:
                const feedback = evt.feedback as LegacyMidSessionFeedback;
                return (
                    <>
                        Recorded user mid-session feedback {renderFbUser(evt.fromUser)}: {feedback.yay ? 'Yay' : 'Nay'}{' '}
                        {feedback.feedback && <>"{feedback.feedback}"</>}
                    </>
                );
        }
    }
}

function ratingStars(rating?: number) {
    if (isNumber(rating)) {
        const invRating = 5 - rating;
        return (
            <>
                {range(rating).map(() => '★')}
                {range(invRating).map(() => '☆')}
            </>
        );
    } else {
        return '-';
    }
}

function renderFbUser(fbUser?: FirebaseUser | AnonymousUser) {
    if (fbUser && fbUser.type === 'firebase') {
        return (
            <>
                {fbUser.name} &lt;{fbUser.email}&gt;
            </>
        );
    } else {
        return <>Anonymous</>;
    }
}

function getStageName(
    evt: EnterStageEvent | AddStageContentEvent | FailedToFindStageContentEvent,
    score?: SessionScore,
) {
    if (isNumber(evt.sessionStageIndex)) {
        // Legacy logs
        if (!score) {
            return evt.sessionStageIndex;
        }
        const sessionStage = (score as any).session[evt.sessionStageIndex];
        const presetName = evt.stagePreset ? evt.stagePreset.presetName : 'default';
        if (sessionStage.name) {
            return (
                <em>
                    {sessionStage.name} ({sessionStage.preset} {sessionStage.stage} from {presetName})
                </em>
            );
        } else {
            return (
                <em>
                    {sessionStage.preset} {sessionStage.stage} (from {presetName})
                </em>
            );
        }
    } else {
        const { index, pathScore } = evt.sessionStageIndex;
        const sessionPreset = pathScore?.stages[index];
        return (
            <>
                <em>{formatPathName(pathScore)}</em> stage <em>{sessionPreset?.stage}</em>
            </>
        );
    }
}

const Container = styled.div<{ isPastEvent: boolean }>`
    display: grid;
    grid-template-columns: 80px 60px 1fr;
    grid-template-areas: 'time actions content';
    align-items: start;
    opacity: ${({ isPastEvent }) => (isPastEvent ? 0.5 : 1)};
`;

const Time = styled.div<{ rowColour: string }>`
    grid-area: time;
    align-self: stretch;
    border-left: 12px solid ${({ rowColour }) => rowColour};
    padding-left: 8px;
`;

const Actions = styled.div`
    grid-area: actions;
`;

const Content = styled(Typography)`
    grid-area: content;
`;
