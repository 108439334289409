import { Card, CardContent, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQueryParam } from 'use-query-params';
import { APISessionStatus, clientsFreudApi } from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import Confirm from '@/component-library/components/Dialog';
import { ScrollRow, ScrollRowItem } from '@/component-library/components/ScrollRow';
import SectionHeading from '@/component-library/components/SectionHeading';
import TextInput from '@/component-library/components/TextInput';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { SearchBar } from '@/components';
import DashboardPageGuidance from '@/components/Dashboard/DashboardPageGuidance';
import DashboardPageHeading from '@/components/Dashboard/DashboardPageHeading';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import { useDebouncedState } from '@/hooks';
import { useClients } from '@/hooks/useClients';

import { useAuthContext } from '../../auth';
import { deleteClient, upsertClient } from '../../common/api/bonnyApi';
import { LayoutContainer } from '../../LayoutContainer';
import { CardRowSessionContainer } from '../home';
import TherapistEndedSessionCardV2 from '../sessions/EndedSessionsTable/TherapistEndedSessionCardV2';
import useSessions, { ISessionsData } from '../sessions/useSessions';

const NoResultsContainer = styled.div`
    width: 100%;
    grid-column: 1/-1;
    text-align: center;
`;

const Container = styled.div`
    display: grid;
    gap: 5px;
`;

const Clients = React.memo(
    ({
        clients,
        isLoading,
        onDeleteClient,
        ContainerComponent = Container,
    }: {
        clients?: clientsFreudApi[];
        isLoading: boolean;
        onDeleteClient: (clientId: string) => void;
        ContainerComponent?: React.FC;
    }) => {
        const history = useHistory();

        return (
            <ContainerComponent>
                <>
                    {clients &&
                        (clients.length ? (
                            clients.map((x, index) => (
                                <Card key={index} variant="outlined" style={{ cursor: 'pointer' }}>
                                    <CardContent
                                        style={{
                                            paddingBottom: '16px',
                                        }}
                                        onClick={() => history.push(`client/${x.client_id}`)}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>
                                                {x.firstname} {x.lastname}
                                            </div>
                                            <Button
                                                variant="solid-blue"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onDeleteClient(x.client_id);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            ))
                        ) : (
                            <NoResultsContainer>
                                <TypographyV2 color="grey-400" size="text-md">
                                    Sorry, we couldn't find any results
                                </TypographyV2>
                            </NoResultsContainer>
                        ))}
                    {isLoading ? <div>Loading...</div> : <></>}
                </>
            </ContainerComponent>
        );
    },
);

export function ClientsList() {
    const { firebaseUser } = useAuthContext();
    if (firebaseUser === undefined) throw new Error('No firebaseUser');

    const [query, setQuery] = useQueryParam<string | undefined>('query');
    const effectiveQuery = query ?? '';
    const { clients: initialClients, isLoading } = useClients({
        fbUser: firebaseUser,
        query: effectiveQuery,
    });
    const [clients, setClients] = useState(initialClients || []);
    const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);
    const [confirmDeleteState, setConfirmDeleteState] = useState<{
        isOpen: boolean;
        client: clientsFreudApi | null;
    }>({
        isOpen: false,
        client: null,
    });
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    useEffect(() => {
        setClients(initialClients || []);
    }, [initialClients]);

    const addNewClientToList = (newClient: clientsFreudApi) => {
        setClients((prevClients) => [newClient, ...prevClients]);
    };

    const deleteClientFromList = async (clientId: string) => {
        try {
            await deleteClient(firebaseUser, clientId);
            setSnackbarContent('Client successfully deleted');
            setClients((prevClients) => prevClients.filter((client) => client.client_id !== clientId));
        } catch (error) {
            console.error('Failed to delete client', error);
            setSnackbarContent('Failed to delete client');
        }
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchBar
                    value={effectiveQuery}
                    label="Find clients"
                    onChange={setQuery}
                    onClear={() => {
                        setQuery('');
                    }}
                />
                <Button variant="solid-blue" onClick={() => setNewDialogOpen(true)} style={{ marginLeft: '20px' }}>
                    Add new
                </Button>
            </div>
            <Clients
                isLoading={isLoading}
                clients={clients}
                onDeleteClient={(clientId) => {
                    const client = clients.find((c) => c.client_id === clientId);
                    if (client) {
                        setConfirmDeleteState({
                            isOpen: true,
                            client,
                        });
                    }
                }}
            />
            {newDialogOpen && (
                <Dialog open={true} onClose={() => setNewDialogOpen(false)} fullWidth={true} maxWidth={'md'}>
                    <DialogTitle>Add new Client</DialogTitle>
                    <DialogContent>
                        <ClientDetail
                            client={undefined}
                            endedSessions={undefined}
                            onSave={({ client }) => {
                                setNewDialogOpen(false);
                                addNewClientToList(client);
                            }}
                        />
                    </DialogContent>
                </Dialog>
            )}
            <Confirm
                fullWidth={true}
                open={confirmDeleteState.isOpen}
                message={`Are you sure you want to delete ${confirmDeleteState.client?.firstname} ${confirmDeleteState.client?.lastname}?`}
                onClose={() =>
                    setConfirmDeleteState({
                        isOpen: false,
                        client: null,
                    })
                }
                onConfirm={() => {
                    if (confirmDeleteState.client?.client_id) {
                        deleteClientFromList(confirmDeleteState.client.client_id);
                        setConfirmDeleteState({
                            isOpen: false,
                            client: null,
                        });
                    }
                }}
                confirmText={'Yes, DELETE'}
            />
        </>
    );
}

export function ClientsPage() {
    return (
        <LayoutContainer>
            <DashboardPageHeading pageTitle="Clients" />
            <DashboardPageGuidance
                pageTitle="Clients"
                guidanceText="Add Clients here, in order to associate them with individual Sessions. A Client is anyone you’re running a Session for, e.g. a patient or customer."
            />
            <ClientsList />
        </LayoutContainer>
    );
}

export function ClientDetail({
    client,
    endedSessions,
    onSave,
}: {
    client?: clientsFreudApi;
    endedSessions?: ISessionsData;
    onSave?: ({ client }: { client: clientsFreudApi }) => void;
}) {
    const { firebaseUser } = useAuthContext();
    if (firebaseUser === undefined) throw new Error('No firebaseUser');

    const [firstname, _setFirstname, immediateFirstname] = useDebouncedState<string>(client?.firstname ?? '', 1000);
    const [lastname, _setLastname, immediateLastname] = useDebouncedState<string>(client?.lastname ?? '', 1000);
    const [notes, _setNotes, immediateNotes] = useDebouncedState<string>(client?.notes ?? '', 1000);
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const submitNew = () => {
        (async () => {
            setSubmitDisabled(true);
            try {
                const client = await upsertClient(firebaseUser, {
                    firstname,
                    lastname,
                    notes,
                    instrument_blacklist: [],
                });
                setSnackbarContent('Client added');
                console.log(onSave, client);
                onSave && onSave({ client });
            } catch (e) {
                setSnackbarContent('Error saving data');
                setSubmitDisabled(false);
            }
        })();
    };
    const needSavingRef = useRef<boolean>(false);
    useEffect(() => {
        (async () => {
            if (!needSavingRef.current) {
                //TODO: how to ignore "initial" state of variables and only run this without having to use useRef counting
                return;
            }
            needSavingRef.current = false;
            console.debug('Change', { firstname, lastname, notes });
            if (client?.client_id === undefined) return;

            try {
                const clientUpdated = await upsertClient(firebaseUser, {
                    client_id: client?.client_id,
                    firstname,
                    lastname,
                    notes,
                    instrument_blacklist: [],
                });
                onSave && onSave({ client: clientUpdated });
                setSnackbarContent('Changes saved');
            } catch (e) {
                setSnackbarContent('Error saving data');
            }
        })();
    }, [firstname, lastname, notes]);

    const setFirstname: typeof _setFirstname = (x) => {
        needSavingRef.current = true;
        _setFirstname(x);
    };

    const setLastname: typeof _setLastname = (x) => {
        needSavingRef.current = true;
        _setLastname(x);
    };

    const setNotes: typeof _setNotes = (x) => {
        needSavingRef.current = true;
        _setNotes(x);
    };

    return (
        <div>
            <SectionHeading text={`Identification`}></SectionHeading>
            <TextInput
                variant="outlined"
                size="small"
                name="firstname"
                heading="First Name or Nickname"
                onChange={(e) => setFirstname(e.target.value)}
                value={immediateFirstname}
            />
            <TextInput
                variant="outlined"
                size="small"
                name="lastname"
                heading="Last Name or Nickname (optional)"
                onChange={(e) => setLastname(e.target.value)}
                value={immediateLastname}
            />
            <TextInput
                variant="outlined"
                size="small"
                name="notes"
                heading="Notes (optional)"
                onChange={(e) => setNotes(e.target.value)}
                value={immediateNotes}
                multiline={true}
                rows={5}
            />
            {endedSessions !== undefined ? (
                <>
                    <br />
                    <SectionHeading text={'Completed Sessions'}></SectionHeading>
                    <ScrollRow>
                        <>
                            {endedSessions.sessions.map((session, idx) => (
                                <ScrollRowItem key={'session' + idx}>
                                    <CardRowSessionContainer>
                                        <TherapistEndedSessionCardV2 key={session.id} session={session} />
                                    </CardRowSessionContainer>
                                </ScrollRowItem>
                            ))}
                        </>
                    </ScrollRow>
                    {!endedSessions.loading && endedSessions.totalCount == 0 ? (
                        <>
                            <TypographyV2 size="text-sm">No sessions yet</TypographyV2>
                        </>
                    ) : (
                        <></>
                    )}

                    {endedSessions.loading && endedSessions.totalCount == 0 ? (
                        <div key="loading">Loading...</div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
            {client === undefined ? (
                <>
                    <Button disabled={submitDisabled} onClick={() => submitNew()} variant="solid-blue" size={'l'}>
                        Submit
                    </Button>
                </>
            ) : (
                <></>
            )}
        </div>
    );
}

export function ClientDetailPage() {
    const { clientId } = useParams<{ clientId: string }>();
    if (!clientId) throw new Error('No clientId');

    const { firebaseUser } = useAuthContext();
    if (firebaseUser === undefined) throw new Error('No firebaseUser');

    const { clients, isLoading: isClientLoading } = useClients({
        fbUser: firebaseUser,
        client_id: clientId,
        query: '',
    });
    const endedSessions = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ENDED,
        paginated: false,
        client_id: clientId,
    });
    const client = clients && clients.length ? clients[0] : undefined;

    return (
        <LayoutContainer>
            {isClientLoading || client === undefined || endedSessions.loading ? (
                <>Loading...</>
            ) : (
                <ClientDetail client={client} endedSessions={endedSessions} />
            )}
        </LayoutContainer>
    );
}
