import styled from '@emotion/styled';
import { isEqual, isNumber } from 'lodash';
import React, { ReactNode, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import { Session, SessionScore, SessionVariables, TimestampedSessionEvent } from 'wavepaths-shared/core';

import { Button, EvaIcon } from '@/component-library';
import { FeedbackButtons } from '@/components/notifications/FeedbackButtons';
import { getZeroTick } from '@/domain/session';
import { useQueryParams } from '@/hooks/useQueryParams';

import WaveQueueEditor from '../../../common/components/WaveQueueEditor';
import {
    Connection,
    useRemoteCurrentWave,
    useRemoteElapsedTimeSecs,
    useRemoteSessionPlaybackControl,
    useRemoteSessionState,
} from '../../../common/hooks/useSessionTick';
import { AudioEditorTabs, ListVoiceOverStages, VoiceOverStagesContextWrapper } from '../../templateInfo';
import { Queueable } from '../actionQueue/useActionQueue';
import { useRemoteDepth } from '../depthSlider/useDepth';
import DeviceOptionsDialog from '../deviceOptions/DeviceOptionsDialog';
import { InSessionProductTour } from '../InSessionProductTour';
import { NowAndNext } from '../nowAndNext/NowAndNext';
import { Timeline } from '../timeline/Timeline';
import { AutoGuideTracking } from './autoGuideTracking';
import { UseSessionScoreReturn } from './useSessionScore';
import { Voiceovers } from './Voiceovers';
import { isDiscardingEdits, selectNoWave, WaveSelection } from './waveSelection';

export type AutoGuideProps = {
    onStartSession: () => void;
    onEndSession: () => void;
    session: Session;
    log: TimestampedSessionEvent[];
    connection: Connection;
    score: SessionScore;
    sessionScoreState: UseSessionScoreReturn;
    snackbarContent: ReactNode;
    setSnackbarContent: (content: ReactNode) => void;
    variables: SessionVariables;
    queueFunction: (queueable: Queueable) => void;
};

const Container = styled.div<{ shouldShowQueue: boolean }>(({ shouldShowQueue }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: shouldShowQueue ? 'min-content 0 1fr' : 'min-content 1fr auto',
    alignContent: 'space-between',
}));

const Top = styled.div({
    width: '100%',
    backgroundColor: 'rgba(43, 45, 63, 0.05)',
});

const Middle = styled.div({
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'height 0.15s ease',
    padding: '0',
    '@media (min-height: 720px)': {
        padding: '16px 0',
    },
});

const Bottom = styled.div({
    width: '100%',
    display: 'flex',
    justifyItems: 'center',
    overflowY: 'hidden',
});

const CardActions = styled.div<{ shouldShowQueue: boolean }>(({ shouldShowQueue }) => ({
    width: '100%',
    minHeight: 128,
    paddingTop: shouldShowQueue ? 80 : 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
}));

const WaveQueueEditorContainer = styled.div<{ shouldShow: boolean }>(({ shouldShow }) => ({
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'hidden',
    backgroundColor: 'rgba(43, 45, 63, 0.05)',
    padding: '56px 16px 8px 16px',
    opacity: shouldShow ? 1 : 0,
    height: shouldShow ? 'auto' : 0,
    transition: 'opacity 0.15s ease',
    margin: '40px auto 0 auto',
    width: '100%',
    '& > *': {
        maxWidth: '650px', // Apply max-width to children
    },
}));

const QueueToggle = styled(Button)({
    margin: '0',
});

const FeedbackButtonsPositioned = styled(FeedbackButtons)({
    position: 'absolute',
    bottom: 40,
    right: 40,
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
});

export const SAVE_OR_CANCEL_LABEL = 'Please save or cancel your changes first.';

export function AutoGuide({
    session,
    connection,
    log,
    score,
    sessionScoreState,
    variables,
    onStartSession,
    onEndSession,
    snackbarContent,
    setSnackbarContent,
    queueFunction,
}: AutoGuideProps): JSX.Element {
    const params = useQueryParams();
    const isTour = params.has('isTour');
    const { wave: currentWave, index: currentWaveIndex } = useRemoteCurrentWave(score.wavepaths);
    const nextWave = currentWave ? score.wavepaths[currentWaveIndex + 1] : null;
    // const isinintrosession2 = session.type === SessionType.INTRO && session.score.id === 'intro-score-2';
    const isPrelude = currentWave?.type === 'pre';
    const isPostlude = currentWave?.type === 'post';
    const zeroTick = useMemo(() => getZeroTick(score, variables), [score, variables]);
    const { initialised, started, paused } = useRemoteSessionState(zeroTick);
    const { pause, resume } = useRemoteSessionPlaybackControl(connection, queueFunction);
    const isPreludeOrPostlude = !currentWave || isPrelude || isPostlude;
    const [_shouldShowQueue, setShouldShowQueue] = useState<boolean>(false);
    const shouldShowQueue = !isPreludeOrPostlude && !!currentWave?.plan && _shouldShowQueue;
    const [waveSelection, setWaveSelection] = useState<WaveSelection>(selectNoWave());
    const depthState = useRemoteDepth(connection, queueFunction);

    const elapsedTimeSecs = useRemoteElapsedTimeSecs();

    useLayoutEffect(() => {
        if (isEqual(waveSelection, selectNoWave()) && snackbarContent === SAVE_OR_CANCEL_LABEL) {
            setSnackbarContent(null);
        }
    }, [snackbarContent, waveSelection]);

    const setWaveSelectionOrNotify = useCallback(
        (newSelection: WaveSelection, force = false) => {
            if (!force && isDiscardingEdits(newSelection, waveSelection)) {
                setSnackbarContent(SAVE_OR_CANCEL_LABEL);
            } else if (!isPreludeOrPostlude && !!currentWave?.plan) {
                setShouldShowQueue(true);
                setWaveSelection(newSelection);
            }
        },
        [waveSelection, isPreludeOrPostlude, currentWave, setSnackbarContent],
    );
    // const intensityOpacity = showDepthController && !isPreludeOrPostlude && initialised ? 1 : 0;

    const productTourState = isPrelude ? 'not-started' : shouldShowQueue ? 'started-queue-open' : 'started';

    const [renderProductTour, setRenderProductTour] = useState(false);

    useLayoutEffect(() => {
        setTimeout(() => {
            setRenderProductTour(true);
        }, 1000);
    }, [productTourState]);

    return (
        <VoiceOverStagesContextWrapper>
            <Container shouldShowQueue={shouldShowQueue}>
                {isTour && renderProductTour && <InSessionProductTour state={productTourState} />}

                <Top>
                    <Timeline
                        score={score}
                        variables={variables}
                        log={log}
                        waveSelection={waveSelection}
                        setWaveSelection={setWaveSelectionOrNotify}
                        connection={connection}
                        session={session}
                        elapsedTimeMs={elapsedTimeSecs * 1000}
                        isScrollable
                        phasesAlwaysVisible
                    />
                </Top>
                <Middle>
                    <NowAndNext
                        initialised={initialised}
                        started={started}
                        paused={paused}
                        sessionScoreState={sessionScoreState}
                        onStartSession={onStartSession}
                        onEndSession={onEndSession}
                        pause={pause}
                        resume={resume}
                        connection={connection}
                        currentWaveIndex={currentWaveIndex}
                        currentWave={currentWave}
                        nextWave={nextWave}
                        currentDepth={
                            depthState !== 'loading' && isNumber(depthState.currentDepth)
                                ? depthState.currentDepth
                                : null
                        }
                        onSkipWave={
                            sessionScoreState !== 'loading'
                                ? () => sessionScoreState.skipToWave(currentWaveIndex + 1)
                                : undefined
                        }
                        queueFunction={queueFunction}
                    />
                    <CardActions shouldShowQueue={shouldShowQueue}>
                        {!shouldShowQueue && (
                            <Voiceovers
                                isLoading={!initialised}
                                isPreludeOrPostlude={isPreludeOrPostlude}
                                elapsedTimeSecs={elapsedTimeSecs}
                                voiceoverStages={score.voiceover ?? []}
                                onUpsertVoiceOverStage={
                                    sessionScoreState !== 'loading' ? sessionScoreState.upsertVoiceOverStage : undefined
                                }
                            />
                        )}
                        {initialised && !isPreludeOrPostlude ? (
                            <QueueToggle
                                aria-label="Expand waves queue"
                                onClick={() => setShouldShowQueue((isExpanded) => !isExpanded)}
                                variant="solid-blue"
                                className="tour-toggleWaveQueue"
                                style={{
                                    width: 300,
                                    height: 40,
                                    borderRadius: 20,
                                    background: '#2B2D3F',
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: '#ffffff',
                                }}
                            >
                                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    {shouldShowQueue ? (
                                        <EvaIcon key={'close'} name={'arrow-downward-outline'} size={24} fill="white" />
                                    ) : (
                                        <EvaIcon key={'open'} name={'arrow-upward-outline'} size={24} fill="white" />
                                    )}
                                    <span>{shouldShowQueue ? 'Hide all Waves' : 'Show all Waves'}</span>
                                </div>
                            </QueueToggle>
                        ) : (
                            <></>
                        )}
                    </CardActions>
                    <DeviceOptionsDialog
                        sessionId={session.id}
                        broadcastIdentifier={session.broadcastIdentifier}
                        isPlayerAppSupported={true}
                        isWebPlayerSupported={!isSafari && !isIOS}
                    />
                </Middle>
                <Bottom>
                    {sessionScoreState !== 'loading' && currentWave?.plan && shouldShowQueue && (
                        <WaveQueueEditorContainer shouldShow={shouldShowQueue}>
                            <AudioEditorTabs
                                waveSelection={waveSelection}
                                mainComponent={
                                    <WaveQueueEditor
                                        key={'wavequeue'}
                                        shouldDisplayHeader
                                        shouldShow={shouldShowQueue}
                                        shouldAllowAudioPreviews={false}
                                        wavepaths={score.wavepaths}
                                        waveSelection={waveSelection}
                                        setWaveSelection={setWaveSelectionOrNotify}
                                        updatePathAtIndex={sessionScoreState.updatePathAtIndex}
                                        addPathAtIndex={sessionScoreState.addPathAtIndex}
                                        removePathAtIndex={sessionScoreState.removePathAtIndex}
                                        skipToWave={sessionScoreState.skipToWave}
                                        movePathToIndex={sessionScoreState.movePathToIndex}
                                        currentWaveIndex={currentWaveIndex}
                                        setSnackbarContent={setSnackbarContent}
                                        trackingHandlers={AutoGuideTracking}
                                    />
                                }
                                customComponent={
                                    <div
                                        key={'custom1'}
                                        style={{
                                            overflowY: 'scroll',
                                            position: 'relative',
                                            scrollbarGutter: 'stable',
                                            height: '88%',
                                        }}
                                    >
                                        <ListVoiceOverStages
                                            voiceOverStages={score.voiceover ?? []}
                                            onRemove={sessionScoreState.upsertVoiceOverStage}
                                            onEdit={sessionScoreState.upsertVoiceOverStage}
                                            onAdd={sessionScoreState.upsertVoiceOverStage}
                                            sessionDuration={Number(variables.totalDuration) * 60}
                                            elapsedTimeSecs={elapsedTimeSecs}
                                        ></ListVoiceOverStages>
                                    </div>
                                }
                            ></AudioEditorTabs>
                        </WaveQueueEditorContainer>
                    )}
                    {!shouldShowQueue && <FeedbackButtonsPositioned />}
                </Bottom>
            </Container>
        </VoiceOverStagesContextWrapper>
    );
}
