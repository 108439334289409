import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Features } from '@/features';

import { useAuthContext } from '../../../auth';
import IconClients from '../../../images/icons/icon-nav-clients.svg';
import IconHome from '../../../images/icons/icon-nav-home.svg';
import IconNews from '../../../images/icons/icon-nav-news.svg';
import IconPrograms from '../../../images/icons/icon-nav-programs.svg';
import IconResources from '../../../images/icons/icon-nav-resources.svg';
import IconSaved from '../../../images/icons/icon-nav-saved.svg';
import IconSessions from '../../../images/icons/icon-nav-sessions.svg';
import IconSupport from '../../../images/icons/icon-nav-support.svg';
import IconTemplates from '../../../images/icons/icon-nav-templates.svg';

const Container = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;
    padding: 8px 0;
    background: var(--color-background);
    box-shadow: 0px -4px 10px 5px rgba(0, 0, 0, 0.05);

    @media (min-width: 490px) {
        display: inline-flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: 8px;
        padding: 0;
        background: none;
        box-shadow: none;
    }
`;

const Button = styled.button<{ isActive: boolean }>`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 4px;
    background: ${({ isActive }) => (isActive ? '#ffffff' : 'transparent')};
    border: 1px solid transparent;
    border-radius: 8px;
    transition: border-color 0.1s;
    font-size: var(--font-size-small);
    text-align: left;
    cursor: pointer;
    &:hover {
        border-color: rgba(0, 0, 0, 0.1);
    }

    width: 40px;
    padding: 0;
    justify-content: center;

    @media (min-width: 1040px) {
        width: 160px;
        padding: 0 16px;
        justify-content: start;
    }

    @media (min-width: 1440px) {
        width: 230px;
    }
`;

const Icon = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: block;
        width: 16px;
        height: 16px;
    }
`;

const Label = styled.div`
    width: 100%;
    display: none;

    @media (min-width: 1040px) {
        display: initial;
    }
`;

interface LinkProps {
    to: string;
    icon: string;
    label: string;
    isActive: boolean;
    useFullPageNavigation: boolean;
}

const Link: React.FC<LinkProps> = ({ to, icon, label, isActive, useFullPageNavigation }) => {
    const history = useHistory();

    const onClick = () => {
        if (useFullPageNavigation) {
            window.location.href = to;
        } else {
            history.push(to);
        }
    };

    return (
        <Button onClick={onClick} role="link" aria-label={label} isActive={isActive}>
            <Icon>
                <img src={icon} alt={label} />
            </Icon>
            <Label>{label}</Label>
        </Button>
    );
};

export interface DashboardNavigationProps {
    currentRoute?: string;
    showSavedTemplates: boolean;
    showTemplatePrograms: boolean;
    useFullPageNavigation: boolean;
}

const DashboardNavigation: React.FC<DashboardNavigationProps> = ({
    currentRoute = '/',
    showSavedTemplates,
    showTemplatePrograms,
    useFullPageNavigation = false,
}) => {
    const { isEnabled, isPersonal } = useAuthContext();

    return (
        <Container role="navigation" aria-label="Primary Navigation">
            <Link
                to="/"
                icon={IconHome}
                label="Home"
                isActive={currentRoute === '/'}
                useFullPageNavigation={useFullPageNavigation}
            />
            {isEnabled(Features.SESSION_DASHBOARD) && isEnabled(Features.LIVE_SESSION_RENDERING) && (
                <Link
                    to="/sessions"
                    icon={IconSessions}
                    label="Sessions"
                    isActive={currentRoute === '/sessions'}
                    useFullPageNavigation={useFullPageNavigation}
                />
            )}
            <Link
                to="/templates"
                icon={IconTemplates}
                label="Templates"
                isActive={currentRoute === '/templates'}
                useFullPageNavigation={useFullPageNavigation}
            />
            {showSavedTemplates && (
                <Link
                    to="/templates/saved"
                    icon={IconSaved}
                    label="Saved"
                    isActive={currentRoute === '/templates/saved'}
                    useFullPageNavigation={useFullPageNavigation}
                />
            )}
            {showTemplatePrograms && (
                <Link
                    to="/templates/programs"
                    icon={IconPrograms}
                    label="Programs"
                    isActive={currentRoute === '/templates/programs'}
                    useFullPageNavigation={useFullPageNavigation}
                />
            )}
            {!isPersonal && (
                <Link
                    to="/clients"
                    icon={IconClients}
                    label="Clients"
                    isActive={currentRoute === '/clients'}
                    useFullPageNavigation={useFullPageNavigation}
                />
            )}
            <Link
                to="/resources"
                icon={IconResources}
                label="Resources"
                isActive={currentRoute === '/resources'}
                useFullPageNavigation={useFullPageNavigation}
            />
            <Link
                to="/news"
                icon={IconNews}
                label="News"
                isActive={currentRoute === '/news'}
                useFullPageNavigation={useFullPageNavigation}
            />
            <Link
                to="/customer-support"
                icon={IconSupport}
                label="Support"
                isActive={currentRoute === '/customer-support'}
                useFullPageNavigation={useFullPageNavigation}
            />
        </Container>
    );
};

export default React.memo(DashboardNavigation);
