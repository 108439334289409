import styled from '@emotion/styled';
import useEmblaCarousel from 'embla-carousel-react';
import { noop } from 'lodash';
import React from 'react';
import { Wavepath } from 'wavepaths-shared/core';

import { Connection } from '@/hooks';

import { Queueable } from '../actionQueue/useActionQueue';
import { UseSessionScoreReturn } from '../autoGuide/useSessionScore';
import CurrentWaveCard from './CurrentWaveCard';
import NextWaveCard from './NextWaveCard';

const EmblaContainer = styled.div`
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 446px;
    justify-content: center;
    align-items: center;
`;

const EmblaSlideContainer = styled.div`
    display: flex;
    gap: 4px;
    height: 100%;
`;

const EmblaSlide = styled.div<{ distance: number; isVisible: boolean }>`
    flex: 0 0 300px;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 18px;
    transition: transform 0.3s ease;
    transform: ${({ distance }) => {
        const scale = Math.max(0.7, 1 - Math.abs(distance) * 0.1);
        const translateX = -distance * 10 * Math.abs(distance);
        return `scale(${scale}) translateX(${translateX}px)`;
    }};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const SlideHeading = styled.div`
    text-align: center;
    font-family: inherit;
    font-weight: 600;
    color: #2b2d3f;
    font-size: 16px;
    height: 32px;
    padding: 16px 0 0 0;
    @media (min-height: 720px) {
        height: 36px;
        font-size: 20px;
        padding: 16px 0 0 0;
    }
`;

const SlideContent = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const PlaceholderSlideContent = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(43, 45, 63, 0.05);
    border-radius: 16px;
`;

const PlaceholderSlide = ({ distance, isVisible }: { distance: number; isVisible: boolean }) => (
    <EmblaSlide distance={distance} isVisible={isVisible}>
        <SlideHeading></SlideHeading>
        <SlideContent>
            <PlaceholderSlideContent />
        </SlideContent>
    </EmblaSlide>
);

export function NowAndNext({
    initialised,
    started,
    paused,
    sessionScoreState,
    onStartSession,
    onEndSession,
    pause,
    resume,
    connection,
    currentWaveIndex,
    currentWave,
    nextWave,
    currentDepth,
    onSkipWave,
    queueFunction,
}: {
    initialised: boolean;
    started: boolean;
    paused: boolean;
    sessionScoreState: UseSessionScoreReturn;
    onStartSession: () => void;
    onEndSession: () => void;
    pause: () => void;
    resume: () => void;
    connection: Connection;
    currentWaveIndex: number;
    currentWave: Wavepath | null;
    currentDepth: number | null;
    nextWave: Wavepath | null;
    onSkipWave?: () => void;
    queueFunction: (queueable: Queueable) => void;
}) {
    const [emblaRef] = useEmblaCarousel({
        loop: false,
        align: 'center',
        containScroll: false,
        watchDrag: false,
        startIndex: 3,
    });

    const nextWaveIndex = currentWaveIndex + 1;

    return (
        <EmblaContainer ref={emblaRef}>
            <EmblaSlideContainer>
                <PlaceholderSlide distance={-3} isVisible={currentWaveIndex > 1} />
                <PlaceholderSlide distance={-2} isVisible={currentWaveIndex > 0} />
                <PlaceholderSlide distance={-1} isVisible={currentWaveIndex > -1} />
                <EmblaSlide distance={0} isVisible>
                    <SlideHeading>Now</SlideHeading>
                    <SlideContent>
                        <CurrentWaveCard
                            initialised={initialised}
                            started={started}
                            paused={paused}
                            onStartSession={onStartSession}
                            onEndSession={onEndSession}
                            pause={pause}
                            resume={resume}
                            connection={connection}
                            currentWave={currentWave}
                            currentDepth={currentDepth}
                            queueFunction={queueFunction}
                        />
                    </SlideContent>
                </EmblaSlide>
                {nextWave && (
                    <EmblaSlide distance={1} isVisible>
                        <SlideHeading>Next</SlideHeading>
                        <SlideContent>
                            <NextWaveCard
                                initialised={initialised}
                                currentWave={currentWave}
                                nextWave={nextWave}
                                nextWaveIndex={nextWaveIndex}
                                onSkipWave={onSkipWave}
                                updatePathAtIndex={
                                    sessionScoreState !== 'loading' ? sessionScoreState.updatePathAtIndex : noop
                                }
                                removePathAtIndex={
                                    sessionScoreState !== 'loading' ? sessionScoreState.removePathAtIndex : noop
                                }
                            />
                        </SlideContent>
                    </EmblaSlide>
                )}
                <PlaceholderSlide distance={nextWave ? 2 : 1} isVisible />
                <PlaceholderSlide distance={nextWave ? 3 : 2} isVisible />
            </EmblaSlideContainer>
        </EmblaContainer>
    );
}
