import { useState } from 'react';

import { InstrumentationDetermined } from '@/components/WavePathSelector/useWaveInstruments';

import { useAuthContext } from '../../auth';
import configs from '../../configs';
import {
    AudioPlayer,
    AudioPlayerProps,
    AudioPlayerStream,
    AudioPlayerStreamPhase,
} from '../../pages/inSession/AudioPlayerProvider';
import { LONG_TIME_FROM_NOW } from '../../pages/inSession/usePlayerLibAudioPlayer';
import { getLayerPreview } from '../api/bonnyApi';

type InstrumentPreviewStateNotPlaying = { state: 'notPlaying' };
type InstrumentPreviewStateStarting = {
    state: 'starting';
    instrument: InstrumentationDetermined;
};
type InstrumentPreviewStatePlaying = {
    state: 'playing';
    instrument: InstrumentationDetermined;
    streams: AudioPlayerStream[];
};
type InstrumentPreviewStateStopping = {
    state: 'stopping';
    instrument: InstrumentationDetermined;
    streams: AudioPlayerStream[];
};
type InstrumentPreviewState =
    | InstrumentPreviewStateNotPlaying
    | InstrumentPreviewStateStarting
    | InstrumentPreviewStateStopping
    | InstrumentPreviewStatePlaying;

export type InstrumentPreview = {
    enabled: boolean;
    state: InstrumentPreviewState;
    start: (instrument: InstrumentationDetermined) => Promise<void>;
    stop: () => Promise<void>;
    playerProps: AudioPlayerProps;
    setPlayer: (player: AudioPlayer) => void;
};

export function useInstrumentPreview(): InstrumentPreview {
    const { firebaseUser } = useAuthContext();
    const [state, setState] = useState<InstrumentPreviewState>({ state: 'notPlaying' });
    const playerProps: AudioPlayerProps = {
        outputDevice: undefined,
        playDemoVO: false,
        streams: state.state === 'playing' || state.state === 'stopping' ? state.streams : [],
        voiceOverStages: [],
        sessionDuration: 10 * 60,
        broadcastElapsedTimeSecs: 0,
    };
    const [player, setPlayer] = useState<AudioPlayer | null>(null);

    const start = async (instrument: InstrumentationDetermined) => {
        setState({ state: 'starting', instrument });
        await player?.actions.play();
        if (!firebaseUser) {
            throw new Error('No user');
        }
        if (instrument.type === 'determinedWithNoContent') {
            return;
        }
        const audioFiles = await getLayerPreview(
            firebaseUser,
            instrument.layers.map((i) => i.id),
        );
        const now = player?.getCurrentTimeSecs() ?? 0;
        const streams: AudioPlayerStream[] = audioFiles.map((file) => ({
            id: file.path,
            phase: AudioPlayerStreamPhase.Session,
            url: `${configs.freud.SAMPLE_PREVIEW_BASE_URL}${file.path}`,
            fromTime: now * 1000 + 200,
            toTime: LONG_TIME_FROM_NOW,
            fadeOutTime: 0,
            loopContent: true,
            gainOffset: file.gainOffset,
        }));
        const isLoop = instrument.layers.some((i) => i.playerType === 'loop');
        if (isLoop) {
            await player?.actions.setTime(now + 10);
        }
        setState((state) =>
            state.state === 'starting' && state.instrument === instrument
                ? { state: 'playing', instrument, streams }
                : state,
        );
    };

    const stop = async () => {
        const stateNow = state;
        if (stateNow.state !== 'notPlaying') {
            setState({
                state: 'stopping',
                instrument: stateNow.instrument,
                streams: stateNow.state === 'starting' ? [] : stateNow.streams,
            });
            await player?.actions.pause({ fadeMs: 100, reason: 'user' });
            setTimeout(() => {
                setState((state) =>
                    state.state === 'stopping' && state.instrument === stateNow.instrument
                        ? { state: 'notPlaying' }
                        : state,
                );
            }, 100);
        }
    };

    return {
        enabled: !!player,
        state,
        start,
        stop,
        playerProps,
        setPlayer,
    };
}
