import styled from '@emotion/styled';
import Dialog from '@material-ui/core/Dialog';
import { clamp, isObject } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useMeasure, useRafLoop } from 'react-use';
import { CoreEmotionalAtmosphere, ScheduledWavepath } from 'wavepaths-shared/core';

import { getSecondsRendered, SessionVisualPreview, useSessionVisualPreview } from '@/hooks/useSessionVisualPreview';
import { useWavePreview, useWavePreviewPlayer, WavePreviewState } from '@/hooks/useWavePreview';

// import { TimelineWave } from '../../../pages/inSession/timeline/TimelineWave';
import {
    Instrumentation,
    InstrumentId,
    WaveInstrumentation,
    WaveInstrumentations,
} from '../WavePathSelector/useWaveInstruments';
import MinimapWave from './MinimapWave';
import SoundsEditor from './SoundsEditor/SoundsEditor';
import WaveShape from './WaveShape';
import { WaveVolumeWaveform } from './WaveVolumeWaveform';

export enum WavePreviewMode {
    None = 'None',
    Visual = 'Visual',
    AudioVisual = 'AudioVisual',
}

const Container = styled.div<{ emotion: string }>`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background-color: ${({ emotion }) =>
        emotion === 'Stillness'
            ? 'var(--color-stillness-primary)'
            : emotion === 'Vitality'
            ? 'var(--color-vitality-primary)'
            : emotion === 'Bittersweet'
            ? 'var(--color-bittersweet-primary)'
            : emotion === 'Tension'
            ? 'var(--color-tension-primary)'
            : 'transparent'};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px;
    position: relative;
    z-index: 1;
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden; /* Prevent horizontal scrolling */
`;

const Scrollable = styled.div`
    position: relative;
`;

// Main playhead that stays fixed in the center during phase 2, but moves during phases 1 and 3
const Playhead = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #ffffff;
    transition: opacity 0.3s ease;
    z-index: 2;
    pointer-events: none;
    will-change: transform, opacity;
`;

// Minimap playhead that moves with the wave
const MinimapPlayhead = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: #ffffff;
    transition: opacity 0.3s ease;
    z-index: 20;
    pointer-events: none;
    will-change: transform, opacity;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 16px 24px 24px 24px;
    position: relative;
    z-index: 1;
`;

// const WaveMinimap = styled.div`
//     width: 100%;
//     height: 78px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background: rgba(0, 0, 0, 0.1);
// `;

// const Svg = styled.svg`
//     width: 100%;
//     height: 100%;
//     fill: none;
// `;

// const WaveMinimapPath = styled(TimelineWave)`
//     stroke: #fff;
//     fill: none;
// `;

const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const LaunchButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    padding: 0;
    border-radius: 4px;
    font-size: 13.33px;
    font-weight: 500;
    cursor: pointer;
    background: transparent;
    border: 1px solid rgb(173, 185, 214);
    color: rgb(44, 57, 88);
    transition: background 0.2s, color 0.2s;
    &:disabled {
        border-color: #f2f4f8;
        color: #c4c7d0;
        pointer-events: none;
        cursor: not-allowed;
    }
`;

export const CloseButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background: var(--color-primary);
    border: none;
    color: #fff;
    transition: background 0.2s, color 0.2s;
    &:disabled {
        border-color: #f2f4f8;
        color: #c4c7d0;
        pointer-events: none;
        cursor: not-allowed;
    }
`;

const WavePreviewControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: relative;
`;

const LoadingText = styled.span<{ isLoading: boolean }>`
    position: absolute;
    right: calc(100% + 16px);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--font-size-xsmall);
    white-space: nowrap;
    color: var(--color-text);
    animation: ${({ isLoading }) => (isLoading ? 'pulse 1.5s ease-in-out infinite' : 'none')};

    @keyframes pulse {
        0% {
            opacity: 0.9;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 0.9;
        }
    }
`;

export const PlayPauseButton = styled.button`
    display: grid;
    place-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    border-radius: 20px;
    border-width: 0;
    background-color: var(--color-primary);
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:hover {
        background-color: #3d3f57;
    }
    svg {
        width: 24px;
        height: 24px;
        fill: #fff;
    }
`;

const WaveShapeContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    opacity: 0; // TODO: fix proportions of the wave shape and remove this
`;

// const WaveShapeFooter = styled.div<{ emotion: string }>`
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 80px;
//     background-color: ${({ emotion }) =>
//         emotion === 'Stillness'
//             ? 'var(--color-stillness-secondary)'
//             : emotion === 'Vitality'
//             ? 'var(--color-vitality-secondary)'
//             : emotion === 'Bittersweet'
//             ? 'var(--color-bittersweet-secondary)'
//             : emotion === 'Tension'
//             ? 'var(--color-tension-secondary)'
//             : 'transparent'};
// `;

const MinimapContainer = styled.div`
    position: relative;
    width: 100%;
    height: 78px;
    background: transparent;
`;

const MinimapWaveWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
`;

const MiniMapOverlaysWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

// Left overlay for the area to the left of the drag handle
const LeftOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    z-index: 1;
`;

// Right overlay for the area to the right of the drag handle
const RightOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    z-index: 1;
`;

const DragHandle = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    outline: 3px solid #2b2d3e;
    border-radius: 4px;
    cursor: grab;
    z-index: 10;
    &:active {
        cursor: grabbing;
    }
`;

// Add a styled wrapper for the Dialog component
const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        height: 600px;
        border-radius: 24px;
        overflow: hidden;
    }
`;

const PIXELS_PER_SECOND = 10;

interface WaveEditorProps {
    wavepath: ScheduledWavepath;
    waveInstrumentations: WaveInstrumentations;
    previewMode: WavePreviewMode;
    onUpdateInstrumentation: (instrumentId: InstrumentId, instrumentation: Instrumentation) => void;
}

type WaveEditorStateClosed = { state: 'closed' };
type WaveEditorStateInstruments = { state: 'instruments' };
type WaveEditorStateOpenedInstrument = { state: 'openedInstrument'; instrument: WaveInstrumentation };
type WaveEditorStateSwappingInstrument = { state: 'swappingInstrument'; instrument: WaveInstrumentation };
export type WaveEditorState =
    | WaveEditorStateClosed
    | WaveEditorStateInstruments
    | WaveEditorStateOpenedInstrument
    | WaveEditorStateSwappingInstrument;

export const WaveEditor: React.FC<WaveEditorProps> = ({
    wavepath,
    waveInstrumentations,
    previewMode,
    onUpdateInstrumentation,
}) => {
    const [state, setState] = useState<WaveEditorState>({ state: 'closed' });
    const [containerRef, { width: containerWidth, height: containerHeight }] = useMeasure<HTMLDivElement>();
    const [minimapRef, { width: minimapWidth }] = useMeasure<HTMLDivElement>();

    // Track positions with refs
    const handlePositionRef = useRef(0);
    const wavePositionRef = useRef(0);
    const playheadRef = useRef<HTMLDivElement>(null);
    const minimapPlayheadRef = useRef<HTMLDivElement>(null);
    const waveformContainerRef = useRef<HTMLDivElement>(null);
    const waveShapeWrapperRef = useRef<HTMLDivElement>(null);
    const leftOverlayRef = useRef<HTMLDivElement>(null);
    const rightOverlayRef = useRef<HTMLDivElement>(null);

    // Track wave dimensions
    const [waveWidth, setWaveWidth] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);

    // Calculate wave dimensions when wavepath changes
    useEffect(() => {
        if (wavepath && wavepath.plan) {
            const duration = (wavepath.plan.toTime - wavepath.plan.fromTime) / 1000; // in seconds
            setTotalDuration(duration);
            const calculatedWidth = duration * PIXELS_PER_SECOND;
            setWaveWidth(calculatedWidth);
            // Update the waveShapeWrapper width directly if it exists
            if (waveShapeWrapperRef.current) {
                waveShapeWrapperRef.current.style.width = `${calculatedWidth}px`;
            }
        }
    }, [wavepath]);

    const handleWidthPct = (containerWidth / waveWidth) * 100;
    const handleWidthPx = (handleWidthPct * minimapWidth) / 100;

    const handleClickOpen = () => {
        setState({ state: 'instruments' });
    };

    const handleClose = () => {
        setState({ state: 'closed' });
    };

    // Add a ref to track if we're currently dragging
    const isDraggingRef = useRef(false);
    const dragEndTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const dragHandleRef = React.useRef<HTMLDivElement>(null);

    const startDrag = (e: React.MouseEvent) => {
        e.preventDefault();

        // Store initial values
        const startX = e.clientX;

        // Get DOM elements
        const handleElement = dragHandleRef.current;
        const waveElement = waveShapeWrapperRef.current;
        const waveformElement = waveformContainerRef.current;
        const leftOverlayElement = leftOverlayRef.current;
        const rightOverlayElement = rightOverlayRef.current;

        if (!handleElement || !waveElement || !waveformElement) {
            console.error('Missing elements:', { handleElement, waveElement, waveformElement });
            return;
        }

        // Set dragging state
        isDraggingRef.current = true;

        // Clear any existing timeout
        if (dragEndTimeoutRef.current) {
            clearTimeout(dragEndTimeoutRef.current);
        }

        // Get initial positions
        const startHandleLeft = handlePositionRef.current;

        // Calculate the actual wave width
        const actualWaveWidth = waveElement.offsetWidth;

        // Calculate the actual maximum scroll range based on the actual wave width
        const actualMaxScrollRange = Math.max(0, actualWaveWidth - containerWidth);

        // If the wave is smaller than the container, let's center it
        if (actualMaxScrollRange <= 0) {
            const centerPosition = (containerWidth - actualWaveWidth) / 2;
            waveElement.style.transform = `translateX(${centerPosition}px)`;
            waveformElement.style.transform = `translateX(${centerPosition}px)`;
            isDraggingRef.current = false;
            return; // No need to drag if the wave fits in the container
        }

        function onMouseMove(moveEvent: MouseEvent) {
            if (!handleElement || !waveElement || !waveformElement) return;

            // Calculate delta in pixels
            const deltaX = moveEvent.clientX - startX;

            // Calculate new handle position (clamped)
            const newHandlePos = Math.max(0, Math.min(minimapWidth - handleWidthPx, startHandleLeft + deltaX));
            handlePositionRef.current = newHandlePos;

            // Calculate wave position from handle position
            let newWavePos = 0;

            // Calculate the scroll range
            const scrollingPx = newHandlePos / (minimapWidth - handleWidthPx);

            // Apply the percentage to the actual max scroll range
            newWavePos = -(scrollingPx * actualMaxScrollRange);

            wavePositionRef.current = newWavePos;

            // DIRECTLY update the DOM elements
            handleElement.style.transform = `translateX(${newHandlePos}px)`;
            waveElement.style.transform = `translateX(${newWavePos}px)`;
            waveformElement.style.transform = `translateX(${newWavePos}px)`;
            if (leftOverlayElement) {
                leftOverlayElement.style.transform = `translateX(${newHandlePos - minimapWidth}px)`;
            }
            if (rightOverlayElement) {
                rightOverlayElement.style.transform = `translateX(${newHandlePos + handleWidthPx}px)`;
            }
        }

        function onMouseUp() {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            // Reset dragging state after a delay
            dragEndTimeoutRef.current = setTimeout(() => {
                isDraggingRef.current = false;
            }, 5000);
        }

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const emotion = isObject(wavepath.pathScore.emotion)
        ? wavepath.pathScore.emotion.from
        : wavepath.pathScore.emotion ?? CoreEmotionalAtmosphere.STILLNESS;

    const wavePreviewState = useWavePreview({
        enabled: state.state !== 'closed' && previewMode !== WavePreviewMode.None,
        wavepath,
    });
    const waveVisualPreview = useSessionVisualPreview(wavePreviewState.state);
    const wavePreviewPlayerRef = useRef<WavePreviewPlayerHandle>(null);

    const onOpenSound = (instrument: WaveInstrumentation) => {
        setState({ state: 'openedInstrument', instrument });
    };

    const onStartSwap = () => {
        if (state.state === 'openedInstrument') {
            setState({ state: 'swappingInstrument', instrument: state.instrument });
        }
    };

    const onFinishSwap = (instrument: Instrumentation) => {
        if (state.state === 'swappingInstrument') {
            onUpdateInstrumentation(state.instrument.group.id, instrument);
            setState({ state: 'instruments' });
        }
    };

    const onCloseSound = () => {
        setState({ state: 'instruments' });
    };

    // Animation loop for playback with fallback timer
    useRafLoop(() => {
        // Get wave and waveform elements
        const waveElement = waveShapeWrapperRef.current;
        const waveformElement = waveformContainerRef.current;
        const playheadElement = playheadRef.current;
        const minimapPlayheadElement = minimapPlayheadRef.current;

        const isPlaying = wavePreviewPlayerRef.current?.isPlaying();
        if (playheadElement) playheadElement.style.opacity = isPlaying ? '1' : '0';
        if (minimapPlayheadElement) minimapPlayheadElement.style.opacity = isPlaying ? '1' : '0';

        // If the player is not created, don't update the playhead position
        if (!wavePreviewPlayerRef.current) return;

        const currentTimeSecs = wavePreviewPlayerRef.current.getCurrentTimeSecs();

        const totalDurationSecs = totalDuration || 60; // Fallback to 60 seconds if duration is 0

        // Calculate the position in pixels
        const pixelPosition = currentTimeSecs * PIXELS_PER_SECOND;

        // Calculate the center point of the container
        const centerPoint = containerWidth / 2;

        // Calculate the maximum scroll position (wave width - container width)
        const maxScrollPosition = Math.max(0, waveWidth - containerWidth);

        if (!waveElement || !waveformElement) {
            console.error('Missing wave elements in animation loop');
            return;
        }

        const newPlayheadPosition = pixelPosition;
        // Calculate the new position for the waveform
        let newWavePosition = 0;

        // Phase 1: Playhead moves to center
        if (pixelPosition < centerPoint) {
            // Keep wave at 0 and let playhead move
            newWavePosition = 0;
        }
        // Phase 2: Wave scrolls while playhead stays centered
        else if (pixelPosition >= centerPoint && pixelPosition <= waveWidth - centerPoint) {
            // Move wave to keep playhead centered
            newWavePosition = -(pixelPosition - centerPoint);
        }
        // Phase 3: Wave stops at end and playhead continues
        else {
            // Keep wave at max scroll position
            newWavePosition = -maxScrollPosition;
        }

        if (playheadElement) {
            playheadElement.style.transform = `translateX(${newPlayheadPosition}px)`;
        }

        // Update wave and waveform positions - only if not dragging
        if (!isDraggingRef.current) {
            waveElement.style.transform = `translateX(${newWavePosition}px)`;
            waveformElement.style.transform = `translateX(${newWavePosition}px)`;
            wavePositionRef.current = newWavePosition;
        }

        // Update minimap playhead position (as percentage of total width)
        // This should always reflect the current time position regardless of drag handle
        const minimapPlayheadPos = clamp(0, (currentTimeSecs / totalDurationSecs) * minimapWidth, minimapWidth);

        if (minimapPlayheadElement) {
            minimapPlayheadElement.style.transform = `translateX(${minimapPlayheadPos}px)`;
        }

        // Update drag handle position to follow the minimap playhead (only if not being dragged)
        if (!isDraggingRef.current) {
            const dragHandleElement = dragHandleRef.current;
            if (dragHandleElement) {
                // Calculate the handle position to keep the playhead centered in the handle
                // But clamp it to ensure it doesn't go out of bounds
                const handleLeft = Math.max(
                    0,
                    Math.min(minimapWidth - handleWidthPx, minimapPlayheadPos - handleWidthPx / 2),
                );

                // Update the handle position
                dragHandleElement.style.transform = `translateX(${handleLeft}px)`;
                handlePositionRef.current = handleLeft;

                // Update overlay widths
                const leftOverlayElement = leftOverlayRef.current;
                const rightOverlayElement = rightOverlayRef.current;

                if (leftOverlayElement) {
                    leftOverlayElement.style.transform = `translateX(${handleLeft - minimapWidth}px)`;
                }
                if (rightOverlayElement) {
                    rightOverlayElement.style.transform = `translateX(${handleLeft + handleWidthPx}px)`;
                }
            }
        }

        // Reset if we reach the end
        if (currentTimeSecs >= totalDurationSecs) {
            console.log('Reached end of playback');
            if (wavePreviewPlayerRef.current) {
                wavePreviewPlayerRef.current.pause();
            }
            setTimeout(() => {
                if (wavePreviewPlayerRef.current) {
                    wavePreviewPlayerRef.current.seekToTime(0);
                }
                if (playheadElement) {
                    playheadElement.style.transform = 'translateX(0px)';
                }
                if (minimapPlayheadElement) {
                    minimapPlayheadElement.style.transform = 'translateX(0px)';
                }

                // Reset wave and waveform positions
                if (waveElement && waveformElement) {
                    waveElement.style.transform = 'translateX(0px)';
                    waveformElement.style.transform = 'translateX(0px)';
                    wavePositionRef.current = 0;
                }

                // Reset drag handle position
                const dragHandleElement = dragHandleRef.current;
                if (dragHandleElement) {
                    dragHandleElement.style.transform = 'translateX(0px)';
                    handlePositionRef.current = 0;
                }
            }, 100);
        }
    }, true); // Always run the loop, but check isPlaying inside

    const onSeekClick = (e: React.MouseEvent) => {
        if (
            !wavePreviewPlayerRef.current ||
            !waveformContainerRef.current ||
            !wavePreviewPlayerRef.current?.isPlaying()
        ) {
            return;
        }
        const bounds = e.currentTarget.getBoundingClientRect();
        const clickX = e.clientX - bounds.left;
        const wavePosition = wavePositionRef.current;
        const relativePosition = (clickX - wavePosition) / waveWidth;
        const clickedTime = relativePosition * totalDuration;
        const renderedTime = getSecondsRendered(waveVisualPreview);
        if (clickedTime < renderedTime) {
            wavePreviewPlayerRef.current.seekToTime(clickedTime);
        }
    };

    return (
        <>
            <LaunchButton onClick={handleClickOpen}>Edit Music</LaunchButton>
            <StyledDialog open={state.state !== 'closed'} onClose={handleClose} maxWidth="xs" fullWidth>
                <Container emotion={emotion} ref={containerRef}>
                    <Header>
                        <h3>Edit music</h3>
                        <WavePreviewControls>
                            {previewMode === WavePreviewMode.AudioVisual &&
                                state.state === 'instruments' &&
                                wavePreviewState.state.state === 'created' && (
                                    <WavePreviewPlayer
                                        ref={wavePreviewPlayerRef}
                                        previewState={wavePreviewState.state}
                                        preview={waveVisualPreview}
                                        totalDuration={totalDuration}
                                        emotion={emotion}
                                    />
                                )}
                        </WavePreviewControls>
                    </Header>
                    <Content onClick={onSeekClick}>
                        <Scrollable>
                            <WaveformsContainer ref={waveformContainerRef}>
                                {waveInstrumentations.map((instrumentation) => (
                                    <WaveVolumeWaveform
                                        emotion={emotion}
                                        key={instrumentation.group.id}
                                        waveInstrumentation={instrumentation}
                                        preview={waveVisualPreview}
                                        pixelsPerSecond={PIXELS_PER_SECOND}
                                    />
                                ))}
                                <Playhead ref={playheadRef} />
                            </WaveformsContainer>
                        </Scrollable>
                        <SoundsEditor
                            wavepath={wavepath}
                            waveInstrumentations={waveInstrumentations}
                            previewMode={previewMode}
                            editorState={state}
                            onOpen={onOpenSound}
                            onStartSwap={onStartSwap}
                            onFinishSwap={onFinishSwap}
                            onClose={onCloseSound}
                            emotion={emotion}
                        />
                    </Content>
                    <Footer>
                        <MinimapContainer ref={minimapRef}>
                            <MinimapWaveWrapper>
                                <MinimapWave wave={wavepath} height={78} />
                            </MinimapWaveWrapper>
                            <MinimapPlayhead ref={minimapPlayheadRef} />
                            <MiniMapOverlaysWrapper>
                                <LeftOverlay
                                    ref={leftOverlayRef}
                                    style={{
                                        width: minimapWidth,
                                        transform: `translateX(${handlePositionRef.current - minimapWidth}px)`,
                                    }}
                                />
                                <RightOverlay
                                    ref={rightOverlayRef}
                                    style={{
                                        width: minimapWidth,
                                        transform: `translateX(${handlePositionRef.current + handleWidthPx}px)`,
                                    }}
                                />
                            </MiniMapOverlaysWrapper>
                            <DragHandle
                                ref={dragHandleRef}
                                style={{
                                    left: 0, // Start at 0
                                    width: `${handleWidthPct}%`,
                                }}
                                onMouseDown={startDrag}
                                tabIndex={0}
                            />
                        </MinimapContainer>
                        <Actions>
                            <CloseButton onClick={handleClose}>Close</CloseButton>
                        </Actions>
                    </Footer>

                    <WaveShapeContainer>
                        <div
                            ref={waveShapeWrapperRef}
                            style={{
                                position: 'absolute',
                                top: '-80px', // Move 72px higher up
                                left: '0px',
                                height: 'calc(100% + 80px)', // Increase height to compensate for the top offset
                                width: `${waveWidth}px`, // Match the width of WaveVolumeWaveforms
                                display: 'flex',
                                alignItems: 'center',
                                paddingBottom: '80px', // Add padding to the bottom
                                filter: 'blur(12px)', // Add blur effect
                                willChange: 'transform',
                            }}
                        >
                            <WaveShape wave={wavepath} height={containerHeight} />
                            {/* <WaveShapeFooter emotion={emotion} /> */}
                        </div>
                    </WaveShapeContainer>
                </Container>
            </StyledDialog>
        </>
    );
};

// Add Ring Progress Indicator components before WavePreviewPlayer
const RingProgressContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
`;

const RingProgress = styled.svg`
    position: absolute;
    top: -8px;
    left: -8px;
    width: 56px;
    height: 56px;
    transform: rotate(-90deg);
    z-index: -1;
    pointer-events: none;
`;

const RingTrack = styled.circle`
    fill: none;
    stroke: none;
    stroke-width: 4;
`;

const RingIndicator = styled.circle<{ progress: number; isLoading: boolean; emotion: string }>`
    fill: none;
    stroke: ${({ emotion }) =>
        emotion === 'Stillness'
            ? 'var(--color-stillness-secondary)'
            : emotion === 'Vitality'
            ? 'var(--color-vitality-secondary)'
            : emotion === 'Bittersweet'
            ? 'var(--color-bittersweet-secondary)'
            : emotion === 'Tension'
            ? 'var(--color-tension-secondary)'
            : 'var(--color-primary)'};
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dasharray: 169.65;
    stroke-dashoffset: ${({ progress }) => 169.65 - Math.max(0.05, progress) * 169.65};
    transition: stroke-dashoffset 0.3s ease;
    opacity: ${({ progress, isLoading }) => (progress >= 1 && !isLoading ? 0 : 1)};
    animation: ${({ isLoading }) => (isLoading ? 'pulse 1.5s ease-in-out infinite' : 'none')};

    @keyframes pulse {
        0% {
            stroke-opacity: 0.9;
        }
        50% {
            stroke-opacity: 0.4;
        }
        100% {
            stroke-opacity: 0.9;
        }
    }
`;

// Update the WavePreviewPlayer component with ring progress indicator
interface WavePreviewPlayerHandle {
    isPlaying: () => boolean;
    getCurrentTimeSecs: () => number;
    play: () => void;
    pause: () => void;
    seekToTime: (timeSecs: number) => void;
}

interface WavePreviewPlayerProps {
    previewState: WavePreviewState;
    preview: SessionVisualPreview;
    totalDuration?: number;
    emotion: string;
}

const WavePreviewPlayer = React.forwardRef<WavePreviewPlayerHandle, WavePreviewPlayerProps>(
    ({ previewState, preview, totalDuration = 0, emotion }, ref) => {
        const { state, play, pause, getCurrentTimeSecs, seekToTime } = useWavePreviewPlayer(previewState);

        // Calculate loading progress
        const secondsRendered = getSecondsRendered(preview);
        const progress = totalDuration > 0 ? Math.min(1, secondsRendered / totalDuration) : 0;
        const isLoading = !preview.finished;

        React.useImperativeHandle(ref, () => ({
            isPlaying: () => state.state === 'playing',
            getCurrentTimeSecs,
            play,
            pause,
            seekToTime,
        }));
        React.useImperativeHandle(ref, () => ({
            isPlaying: () => state.state === 'playing',
            getCurrentTimeSecs,
            play,
            pause,
            seekToTime,
        }));

        return (
            <RingProgressContainer>
                <RingProgress viewBox="-2 -2 60 60">
                    <RingTrack cx="28" cy="28" r="27" />
                    <RingIndicator cx="28" cy="28" r="27" progress={progress} isLoading={isLoading} emotion={emotion} />
                </RingProgress>

                {isLoading && <LoadingText isLoading={isLoading}>Generating preview...</LoadingText>}

                {state.state === 'created' && (
                    <PlayPauseButton onClick={play}>
                        <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 38V10L36 24L14 38Z" />
                        </svg>
                    </PlayPauseButton>
                )}
                {state.state === 'playing' && (
                    <PlayPauseButton onClick={pause}>
                        <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                        </svg>
                    </PlayPauseButton>
                )}
            </RingProgressContainer>
        );
    },
);

// Add WaveformsContainer definition before the RingProgressContainer
const WaveformsContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
    will-change: transform;
`;
