import styled from '@emotion/styled';
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { useEvent } from 'react-use';
import { SessionState } from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import { useRemoteElapsedTimeSecs, useRemoteSessionState } from '@/hooks';

import { AudioPlayer, AudioPlayerContext } from '../AudioPlayerProvider';
import { PlayerChoice, PlayerChoiceContext, WhereToPlayMusic } from '../deviceOptions/PlayerChoiceContext';
import { RemoteSessionControlsContext } from '../Guide';

interface IStartEndSessionButtonProps {
    onStartSession: () => void;
    onEndSession: () => void;
    broadcastIdentifier: string;
}

export const START_LABEL = 'Start Session';
export const StartButton = ({ onStartSession }: { onStartSession: () => void }) => {
    const [clicked, setClicked] = useState(false);

    const handleStartSession = () => {
        setClicked(true);
        onStartSession();
    };

    return (
        <Button
            className="tour-startSession"
            size="l"
            variant="solid-blue"
            disabled={clicked}
            onClick={handleStartSession}
            style={{ backgroundColor: '#2B2D3F', color: '#ffffff', fontWeight: 600 }}
        >
            {START_LABEL}
        </Button>
    );
};

export const END_LABEL = 'End Session';
const EndButton = ({ onEndSession }: { onEndSession: () => void }) => {
    const [clicked, setClicked] = useState(false);

    const handleEndSession = () => {
        setClicked(true);
        onEndSession();
    };

    return (
        <Button
            size="l"
            variant="solid-blue"
            disabled={clicked}
            onClick={handleEndSession}
            style={{ backgroundColor: '#2B2D3F', color: '#ffffff', fontWeight: 600 }}
        >
            {END_LABEL}
        </Button>
    );
};

const Container = styled.div`
    display: inline-grid;
    place-content: center;
    gap: 10px;
`;

const StartContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export default function StartEndSessionButton({
    onStartSession,
    onEndSession,
}: //broadcastIdentifier,
IStartEndSessionButtonProps): JSX.Element {
    const remoteSession = useContext(RemoteSessionControlsContext);
    if (!remoteSession) {
        throw new Error('no RemoteSessionControlsContext');
    }
    const isPrelude =
        remoteSession.tick && [SessionState.PRELUDE, SessionState.PLANNED].includes(remoteSession.tick.sessionState);
    const isPostlude =
        remoteSession.tick && [SessionState.POSTLUDE, SessionState.ENDED].includes(remoteSession.tick.sessionState);
    const isEnded = remoteSession.tick && remoteSession.tick.sessionState === SessionState.ENDED;
    const { paused } = useRemoteSessionState();

    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const audioPlayer = useContext(AudioPlayerContext);
    const audioLoaded = useRef(false);
    useEffect(() => {
        audioLoaded.current = false;
    }, [audioPlayer?.type]);

    const elapsedTimeSecs = useRemoteElapsedTimeSecs();

    useEffect(() => {
        (async () => {
            if (!audioLoaded.current) return;

            if (paused) {
                audioPlayer?.actions.pause({ reason: 'user' });
            } else {
                await audioPlayer?.actions.unblock();
                audioPlayer?.actions.play({ offsetSeconds: elapsedTimeSecs });
            }
        })();
    }, [paused]);

    // Note (tero): This is all very unfortunate, but we need to get a click especially on Safari
    // for players to unblock properly. Once the HLS players are vmoed (playerlib is stable),
    // consider yanking the whole player init out of React effect hell.
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
        if (!clicked) return;
        initPlayback({
            sessionInitialised: remoteSession.sessionInitialised,
            whereToPlayMusic: playerChoiceContext.whereToPlayMusic,
            playerChoice: playerChoiceContext.playerChoice,
            sessionStarted: remoteSession.sessionStarted,
            audioLoadedRef: audioLoaded,
            isPostlude: !!isPostlude,
            paused,
            audioPlayer,
        });
    }, [
        clicked,
        remoteSession.sessionInitialised,
        playerChoiceContext.whereToPlayMusic,
        remoteSession.sessionStarted,
        audioLoaded.current,
        isPostlude,
        playerChoiceContext.playerChoice,
    ]);
    useEvent(
        'click',
        () => {
            if (clicked) return;
            setClicked(true);
            initPlayback({
                sessionInitialised: remoteSession.sessionInitialised,
                whereToPlayMusic: playerChoiceContext.whereToPlayMusic,
                playerChoice: playerChoiceContext.playerChoice,
                sessionStarted: remoteSession.sessionStarted,
                audioLoadedRef: audioLoaded,
                isPostlude: !!isPostlude,
                paused,
                audioPlayer,
            });
        },
        document.body,
    );

    const showContainer =
        isPostlude ||
        isPrelude ||
        !playerChoiceContext.whereToPlayMusic ||
        (playerChoiceContext.whereToPlayMusic !== 'remoteOnly' && playerChoiceContext.playerChoice !== 'browser');
    //&& !dismissedPlayerChoice

    return (
        <>
            {showContainer && (
                <Container>
                    {isPostlude && !isEnded && <EndButton onEndSession={onEndSession} />}

                    {(isPrelude || !playerChoiceContext.whereToPlayMusic) && (
                        <StartContainer>
                            {playerChoiceContext.whereToPlayMusic !== undefined && isPrelude ? (
                                <StartButton onStartSession={onStartSession} />
                            ) : (
                                <></>
                            )}
                        </StartContainer>
                    )}
                </Container>
            )}
        </>
    );
}

async function initPlayback({
    sessionInitialised,
    whereToPlayMusic,
    playerChoice,
    sessionStarted,
    audioLoadedRef,
    isPostlude,
    paused,
    audioPlayer,
}: {
    sessionInitialised: boolean;
    whereToPlayMusic: WhereToPlayMusic | undefined;
    playerChoice: PlayerChoice;
    sessionStarted: boolean;
    audioLoadedRef: MutableRefObject<boolean>;
    isPostlude: boolean;
    paused: boolean;
    audioPlayer?: AudioPlayer;
}) {
    console.debug('Init playback', {
        sessionInitialised: sessionInitialised,
        whereToPlayMusic: whereToPlayMusic,
        playerChoice: playerChoice,
        sessionStarted: sessionStarted,
        audioLoadedRef: audioLoadedRef,
        isPostlude,
        paused,
    });
    if (whereToPlayMusic !== 'remoteOnly' && playerChoice === 'browser') {
        if (!isPostlude) {
            if (sessionInitialised && !audioLoadedRef.current) {
                (async () => {
                    if (!sessionStarted) {
                        console.debug('Playing prelude...');
                        await audioPlayer?.actions.unblock();
                        audioPlayer?.actions.playPrelude();
                    } else {
                        if (!paused) {
                            await audioPlayer?.actions.unblock();
                            audioPlayer?.actions.play();
                        }
                    }
                    audioLoadedRef.current = true;
                })();
            }
            if (audioLoadedRef.current && sessionStarted) {
                if (!paused) {
                    console.debug('Playing main phase...');
                    if (audioPlayer === undefined) throw new Error('No audioPlayer');
                    await audioPlayer?.actions.unblock();
                    audioPlayer?.actions.play();
                }
            }
        } else {
            console.debug('Playing postlude...');
            audioPlayer?.actions.pause({ fadeMs: 30 * 1000, reason: 'sessionEnd' });
            audioPlayer?.actions.playPostlude();
        }
    }
}
