import React, { useContext, useState } from 'react';
import { SessionRenderType } from 'wavepaths-shared/core';

import Dialog from '@/components/Dialog/Dialog';
import DialogFooter from '@/components/Dialog/DialogFooter';
import DialogHeader from '@/components/Dialog/DialogHeader';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import { useSessionLinkForTheNativeApp } from '@/util/broadcastLinkUtils';

import { CancelButton, Column, Columns, FooterActions, SaveButton } from './DeviceOptionsDialogComponents';
import PlaybackOptions from './playbackOptions/PlaybackOptions';
import { PlayerChoiceContext } from './PlayerChoiceContext';

interface PlaybackOptionsDialogProps {
    broadcastIdentifier: string;
    isPlayerAppSupported: boolean;
    isWebPlayerSupported: boolean;
    freePaidPathPartForPlayerAppLink?: string;
    sessionRenderType?: SessionRenderType;
}

export default function PlaybackOptionsDialog({
    broadcastIdentifier,
    isPlayerAppSupported,
    isWebPlayerSupported,
    freePaidPathPartForPlayerAppLink,
    sessionRenderType,
}: PlaybackOptionsDialogProps): React.ReactElement {
    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

    const [whereToPlayMusic, setWhereToPlayMusic] = useState(playerChoiceContext.whereToPlayMusic);
    const [playerChoice, setPlayerChoice] = useState(playerChoiceContext.playerChoice);
    const [browserPlayerOutputDevice, setBrowserPlayerOutputDevice] = useState(
        playerChoiceContext.browserPlayerOutputDevice,
    );

    const mayBeDismissed = whereToPlayMusic !== undefined;
    const haveChanges =
        whereToPlayMusic !== playerChoiceContext.whereToPlayMusic ||
        playerChoice !== playerChoiceContext.playerChoice ||
        browserPlayerOutputDevice !== playerChoiceContext.browserPlayerOutputDevice;

    const onCancel = () => {
        if (mayBeDismissed) {
            playerChoiceContext.closeDialog();
            setWhereToPlayMusic(playerChoiceContext.whereToPlayMusic);
            setPlayerChoice(playerChoiceContext.playerChoice);
            setBrowserPlayerOutputDevice(playerChoiceContext.browserPlayerOutputDevice);
        }
    };
    const onSave = () => {
        if (mayBeDismissed) {
            playerChoiceContext.closeDialogWithUpdate(
                {
                    whereToPlayMusic,
                    playerChoice,
                    browserPlayerOutputDevice,
                },
                () => {
                    if (
                        (whereToPlayMusic === 'thisDevice' || whereToPlayMusic === 'thisAndRemote') &&
                        playerChoice === 'native'
                    ) {
                        launchPlayerAppSnackbar();
                    }
                },
            );
        }
    };

    const globalSnackbarContext = useContext(GlobalSnackbarContext);
    const appLink = useSessionLinkForTheNativeApp({
        broadcastIdentifier,
    });
    const launchPlayerAppSnackbar = () => {
        setTimeout(() => {
            globalSnackbarContext.setSnackbarContent(
                'Music playing in Player app',
                undefined,
                'Open',
                () => {
                    window.open(appLink, '_blank');
                },
                true,
                10000,
            );
        }, 500);
    };

    return (
        <Dialog isOpen={playerChoiceContext.isDialogOpen} stopClickPropagation={false}>
            <DialogHeader text={'Playback'} />
            <Columns>
                <Column>
                    <PlaybackOptions
                        broadcastIdentifier={broadcastIdentifier}
                        whereToPlayMusic={whereToPlayMusic}
                        playerChoice={playerChoice}
                        browserPlayerOutputDevice={browserPlayerOutputDevice}
                        isPlayerAppSupported={isPlayerAppSupported}
                        isWebPlayerSupported={isWebPlayerSupported}
                        freePaidPathPartForPlayerAppLink={freePaidPathPartForPlayerAppLink}
                        onSetWhereToPlayMusic={setWhereToPlayMusic}
                        onSetPlayerChoice={setPlayerChoice}
                        onSetBrowserPlayerOutputDevice={setBrowserPlayerOutputDevice}
                        sessionRenderType={sessionRenderType}
                    />
                </Column>
            </Columns>
            <DialogFooter>
                <FooterActions>
                    <CancelButton
                        onClick={onCancel}
                        disabled={!mayBeDismissed || !haveChanges || playerChoiceContext.isInitialDialog}
                    >
                        Cancel
                    </CancelButton>
                    <SaveButton onClick={onSave} disabled={!mayBeDismissed}>
                        Save
                    </SaveButton>
                </FooterActions>
            </DialogFooter>
        </Dialog>
    );
}
