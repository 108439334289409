import styled from '@emotion/styled';
import { Dialog, DialogContent } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import React, { ReactNode, useContext, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { useInterval } from 'react-use';
import { Session, SessionRenderType } from 'wavepaths-shared/core';

import { Button, Menu } from '@/component-library';
import { SubscribeModal } from '@/components';

import { useAuthContext } from '../../../auth';
import { Header } from '../../../common/components/Header';
import { formatDuration } from '../../../dateUtilsV2';
import SettingsIcon from '../../../images/icons/icon-settings.svg';
import { AudioDownload } from '../AudioDownload';
import { AudioPlayerContext } from '../AudioPlayerProvider';
import { PlayerChoiceContext } from '../deviceOptions/PlayerChoiceContext';
import { SessionInfo } from '../SessionInfo';
import { SessionName } from '../SessionName';
import Timer from '../Timer';

const SessionHeaderInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
`;

const SessionDuration = styled.div`
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    color: #2b2d3f;
`;

interface PrecomposedGuideHeaderProps {
    session: Session;
    headerCenterText?: ReactNode;
    sessionPlaysHere: boolean;
    totalDurationMs: number;
    isExpired?: boolean;
}

const useStyles = makeStyles<Theme>(() => ({
    paper: { maxWidth: 398 },
}));

export const PrecomposedGuideHeader: React.FC<PrecomposedGuideHeaderProps> = React.memo(
    ({ session, headerCenterText, totalDurationMs, sessionPlaysHere, isExpired = false }) => {
        const [sessionInfoOpen, setSessionInfoOpen] = useState(false);
        const classes = useStyles();

        const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState<boolean>(false);

        const { isPersonal, firebaseUser } = useAuthContext();

        const playerChoiceContext = useContext(PlayerChoiceContext);
        if (playerChoiceContext === undefined) throw new Error('no PlayerChoiceContext');

        const menuActions = [
            ...(isPersonal || !firebaseUser || session.renderType !== SessionRenderType.PRE_RENDERED
                ? []
                : [
                      {
                          element: 'Session Info',
                          icon: <InfoIcon />,
                          onClick: () => setSessionInfoOpen(true),
                      },
                  ]),
            {
                element: 'Playback Settings',
                icon: <MobileScreenShareIcon />,
                onClick: () => playerChoiceContext.openDialog(),
            },
            {
                element: 'Go Back To Dashboard',
                icon: <ArrowBackIcon />,
                onClick: () => (window.location.href = '/'),
            },
        ];

        return (
            <>
                <SubscribeModal isOpen={!!upgradeModalIsOpen} closeModal={() => setUpgradeModalIsOpen(false)} />

                <Header
                    left={
                        <SessionHeaderInfo>
                            <SessionName session={session} />
                            <SessionDuration>
                                {totalDurationMs > 0 ? formatDuration(totalDurationMs) : '00:00'}
                            </SessionDuration>
                        </SessionHeaderInfo>
                    }
                    center={sessionPlaysHere && <AudioPlayerTimer />}
                    right={
                        <div
                            className="tour-sessionOptions"
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}
                        >
                            <>
                                {sessionPlaysHere &&
                                    session.renderType !== SessionRenderType.PREDICTIVE_COMPOSED &&
                                    !isSafari &&
                                    !isExpired && <AudioDownload />}
                                {isPersonal ||
                                !firebaseUser ||
                                session.renderType !== SessionRenderType.PRE_RENDERED ? (
                                    <></>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        style={{
                                            background: 'rgba(255, 255, 255, 0.4)',
                                            border: '1px solid rgba(255, 255, 255, 0.4)',
                                            borderRadius: '75px',
                                        }}
                                        onClick={() => playerChoiceContext.openDialog()}
                                        icon={<img src={SettingsIcon} />}
                                        aria-label="Volume"
                                    />
                                )}
                            </>
                            {/* {firebaseUser && <NotificationBell />} */}
                            <Menu
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                options={menuActions}
                            />
                            <Dialog
                                classes={{ paper: classes.paper }}
                                open={sessionInfoOpen}
                                onClose={() => setSessionInfoOpen(false)}
                            >
                                <DialogContent>
                                    <SessionInfo closeDialog={() => setSessionInfoOpen(false)} session={session} />
                                </DialogContent>
                            </Dialog>
                        </div>
                    }
                />
                {headerCenterText ? <div style={{ textAlign: 'center' }}>{headerCenterText}</div> : ''}
            </>
        );
    },
);

const AudioPlayerTimer = () => {
    const audioPlayerContext = useContext(AudioPlayerContext);
    const [timeElapsedSecs, setTimeElapsedSecs] = useState(0);
    useInterval(() => {
        const timeElapsedSecs = audioPlayerContext?.getCurrentTimeSecs();
        setTimeElapsedSecs(timeElapsedSecs ?? 0);
    }, 1000);
    return <Timer timeElapsedMs={timeElapsedSecs * 1000}></Timer>;
};
