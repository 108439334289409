import firebase from 'firebase/app';
import { findLast } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
    ClientVariablesMusicPreference,
    ScheduledWavepath,
    Session,
    SessionRenderType,
    SessionScoreDTO,
    SessionScoreSessionUse,
    SessionType,
    SessionVariables,
} from 'wavepaths-shared/core';

import { Features } from '@/features';

import { useAuthContext } from '../../auth';
import configs from '../../configs';
import { AudioPlayerStreamPhase } from '../../pages/inSession/AudioPlayerProvider';
import { RemoteSessionControlsContext } from '../../pages/inSession/Guide';
import { LONG_TIME_FROM_NOW, usePlayerLibAudioPlayer } from '../../pages/inSession/usePlayerLibAudioPlayer';
import * as sessionApi from '../api/sessionApi';

type StateNotCreated = { state: 'notCreated' };
type StateCreating = { state: 'creating' };
type StateCreated = {
    state: 'created';
    broadcastIdentifier: string;
    sessionId: string;
    timeWindow: { fromTime: number; toTime: number };
};
type StateError = { state: 'error'; reason: string };
export type WavePreviewState = StateNotCreated | StateCreating | StateCreated | StateError;

interface WavePreviewProps {
    enabled: boolean;
    wavepath: ScheduledWavepath;
}

export function useWavePreview({ enabled, wavepath }: WavePreviewProps) {
    const [state, setState] = useState<WavePreviewState>({ state: 'notCreated' });
    const { firebaseUser } = useAuthContext();
    const remoteSession = useContext(RemoteSessionControlsContext);

    const session = remoteSession?.session;
    const broadcastState = remoteSession?.broadcastState;

    useEffect(() => {
        if (!enabled) {
            setState({ state: 'notCreated' });
            return;
        }
        let superceded = false;
        let theSession: Session | undefined;
        (async () => {
            console.log('rendering wave preview for', wavepath.id);
            if (!firebaseUser) {
                setState({ state: 'error', reason: 'No user' });
                return;
            }
            if (!wavepath.plan) {
                setState({ state: 'error', reason: 'No plan in wavepath' });
                return;
            }

            const planDuration = wavepath.plan.toTime - wavepath.plan.fromTime;

            if (session) {
                const fromTime = wavepath.plan.fromTime;
                const relevantBroadcast = findLast(broadcastState?.timeline, (t) => t.dspOffset <= fromTime) ?? {
                    sessionId: session.id,
                    dspOffset: 0,
                };
                const fromTimeInBroadcast = fromTime - relevantBroadcast.dspOffset;
                const toTimeInBroadcast = fromTimeInBroadcast + planDuration;
                console.log(
                    'found broadcast',
                    relevantBroadcast,
                    'for wave time',
                    fromTime,
                    'and broadcast timeline',
                    broadcastState?.timeline,
                    'and time window',
                    { fromTime: fromTimeInBroadcast, toTime: toTimeInBroadcast },
                );
                setState({
                    state: 'created',
                    broadcastIdentifier: session.broadcastIdentifier,
                    sessionId: relevantBroadcast.sessionId,
                    timeWindow: {
                        fromTime: fromTimeInBroadcast,
                        toTime: toTimeInBroadcast,
                    },
                });
            } else {
                setState({ state: 'creating' });
                const wavepathWithPlanAtStartOfSession = { ...wavepath, plan: { fromTime: 0, toTime: planDuration } };
                try {
                    theSession = await createSession(wavepathWithPlanAtStartOfSession, firebaseUser);
                    if (!superceded) {
                        setState({
                            state: 'created',
                            broadcastIdentifier: theSession.broadcastIdentifier,
                            sessionId: theSession.id,
                            timeWindow: { fromTime: 0, toTime: planDuration },
                        });
                    }
                } catch (error) {
                    if (!superceded) {
                        setState({ state: 'error', reason: String(error) });
                    }
                }
            }
        })();
        return () => {
            superceded = true;
            if (theSession && firebaseUser) {
                sessionApi.deleteSession({
                    sessionId: theSession.id,
                    fadeTime: 100,
                    fbUser: firebaseUser,
                });
            }
        };
    }, [enabled, session, broadcastState, wavepath, firebaseUser]);

    return { state };
}

type PlayerStateCreated = { state: 'created' };
type PlayerStatePlaying = { state: 'playing' };
type PlayerState = PlayerStateCreated | PlayerStatePlaying;

export function useWavePreviewPlayer(state: WavePreviewState) {
    const [playerState, setPlayerState] = useState<PlayerState>({ state: 'created' });
    const { isEnabled } = useAuthContext();
    const playDemoVO = !isEnabled(Features.FREE_ACCESS);

    const player = usePlayerLibAudioPlayer({
        playDemoVO,
        streams:
            state.state === 'created'
                ? [
                      {
                          id: state.sessionId,
                          phase: AudioPlayerStreamPhase.Session,
                          url: `${configs.freud.STREAM_BASE}/${state.broadcastIdentifier}/${state.sessionId}/stream.m3u8`,
                          fromTime: 0,
                          toTime: LONG_TIME_FROM_NOW,
                          fadeOutTime: 0,
                          loopContent: false,
                          gainOffset: 0,
                      },
                  ]
                : [],
        broadcastElapsedTimeSecs: 0,
        sessionDuration: state.state === 'created' ? Number(state.timeWindow.toTime - state.timeWindow.fromTime) : 0,
        voiceOverStages: [],
    });

    // Seek to beginning of timeline whenever we ge a new session
    const latestSetTimeFn = useRef(player.actions.setTime);
    useEffect(() => {
        latestSetTimeFn.current(0);
    }, [state]);

    const play = () => {
        if (state.state !== 'created') {
            return;
        }
        player.actions.play();
        setPlayerState({ state: 'playing' });
    };

    const pause = () => {
        if (state.state !== 'created') {
            return;
        }
        player.actions.pause({ fadeMs: 300, reason: 'user' });
        setPlayerState({ state: 'created' });
    };

    const seekToTime = (timeSecs: number) => {
        if (state.state !== 'created') {
            return;
        }
        player.actions.setTime(timeSecs);
    };

    return { state: playerState, play, pause, getCurrentTimeSecs: player.getCurrentTimeSecs, seekToTime };
}

async function createSession(
    wavepath: ScheduledWavepath & { plan: { fromTime: number; toTime: number } },
    firebaseUser: firebase.User,
) {
    const durationMins = (wavepath.plan?.toTime - wavepath.plan?.fromTime) / 60000;

    const variableInputs: SessionVariables = {
        sessionUse: SessionScoreSessionUse.IN_PERSON,
        name: '',
        voiceover: 'none',
        Acousticness: ClientVariablesMusicPreference.MIXED,
        totalDuration: durationMins,
    };
    const attributeInputs: Partial<Session> = {
        unlisted: true,
        canClientStartEarly: false,
        scheduledStart: 1,
    };
    const sessionScore: SessionScoreDTO = {
        name: `Wave Preview ${wavepath.id}`,
        sessionTypes: [SessionType.WAVE_PREVIEW],
        variables: {
            inputs: [
                {
                    name: 'totalDuration',
                    type: 'number',
                    defaultValue: durationMins,
                },
                {
                    name: 'preludeDuration',
                    type: 'number',
                    defaultValue: 0,
                },
                {
                    name: 'postludeDuration',
                    type: 'number',
                    defaultValue: 0,
                },
            ],
        },
        showInMenu: true,
        wavepaths: [wavepath],
    };
    const createdSession = await sessionApi.startSession(
        SessionType.WAVE_PREVIEW,
        SessionRenderType.PRE_RENDERED,
        sessionScore,
        variableInputs,
        attributeInputs,
        [],
        firebaseUser,
        [],
        ['Approved'],
    );
    return createdSession;
}
