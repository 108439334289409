import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { SessionRenderType } from 'wavepaths-shared/core';

export function useSessionRenderingType() {
    const [_renderingType, setRenderingType] = useLocalStorage<SessionRenderType>(
        'renderType',
        SessionRenderType.PREDICTIVE_COMPOSED,
    );
    const renderingType = _renderingType ?? SessionRenderType.PREDICTIVE_COMPOSED;

    // Legacy removal of real-time session from people's local storage
    useEffect(() => {
        if (renderingType === SessionRenderType.REAL_TIME_DEPRECATED) {
            setRenderingType(SessionRenderType.PREDICTIVE_COMPOSED);
        }
    }, [renderingType]);

    return [renderingType, setRenderingType] as const;
}
