import React from 'react';
import styled from 'styled-components';
import {
    isPostludePathScore,
    isPostludeWavepath,
    isPreludePathScore,
    isPreludeWavepath,
    isScheduledPathScore,
    isScheduledWavepath,
    Wavepath,
} from 'wavepaths-shared/core';

import { AnimatedLoaderIcon } from '@/component-library';
import useScoreLibrary from '@/hooks/useScoreLibrary';

import { useAuthContext } from '../../../auth';
import { WavePreviewMode } from '../WaveEditor/WaveEditor';
import { PrePostludePathSelector } from './PrePostLudePathSelector';
import { ScheduledWavePathSelector } from './ScheduledWavePathSelector';
export interface SegmentedControlOption<ValueType> {
    label: string;
    value: ValueType;
    disabled: boolean;
}

export interface IWavePathSelectorProps {
    wavepath: Wavepath;
    previewMode: WavePreviewMode;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

const Container = styled.div({
    display: 'grid',
    rowGap: '16px',
    padding: '8px 0 24px',
});

const LOADING_ICON_LABEL = 'Loading waves';
export function WavePathSelector(props: IWavePathSelectorProps): JSX.Element {
    const { wavepath } = props;

    const { firebaseUser } = useAuthContext();
    const scores = useScoreLibrary(firebaseUser);

    if (scores.loading === true) return <AnimatedLoaderIcon aria-label={LOADING_ICON_LABEL} />;

    const { pathScores: allPathScores } = scores;

    const scheduledPathScores = allPathScores.filter(isScheduledPathScore);
    const preludePathScores = allPathScores.filter(isPreludePathScore);
    const postludePathScores = allPathScores.filter(isPostludePathScore);

    if (isScheduledWavepath(wavepath)) {
        return (
            <Container>
                <ScheduledWavePathSelector {...props} wavepath={wavepath} pathScores={scheduledPathScores} />
            </Container>
        );
    } else if (isPreludeWavepath(wavepath)) {
        return (
            <Container>
                <PrePostludePathSelector {...props} wavepath={wavepath} pathScores={preludePathScores} />
            </Container>
        );
    } else if (isPostludeWavepath(wavepath)) {
        return (
            <Container>
                <PrePostludePathSelector {...props} wavepath={wavepath} pathScores={postludePathScores} />
            </Container>
        );
    }

    return <></>;
}
