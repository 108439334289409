import styled from '@emotion/styled';
import React, { useCallback, useContext, useState } from 'react';
import QRCode from 'react-qr-code';

import { GlobalSnackbarContext } from '@/components/Snackbar';
import { Features } from '@/features';
import { useSessionLinkForTheClient, useSessionLinkForTheNativeApp } from '@/util/broadcastLinkUtils';

import { useAuthContext } from '../../../../auth';
import CopyIcon from '../../../../images/icons/icon-copy.svg';
import UpgradeMessage from '../UpgradeMessage';
import AppStoreContent from './AppStoreContent';
import PlayerAppMessage from './PlayerAppMessage';
import TextDivider from './TextDivider';

const Container = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Tabs = styled.div`
    width: 100%;
    display: flex;
    margin-top: -8px;
`;

const Tab = styled.button<{ isActive: boolean }>`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
    border-bottom: 2px solid ${({ isActive }: { isActive: boolean }) => (isActive ? '#103DA8' : 'transparent')};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ isActive }: { isActive: boolean }) => (isActive ? '#103DA8' : '#344054')};
`;

const TabContent = styled.div`
    display: flex;
    gap: 16px;
`;

const TabPanel = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const QRCodeContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const QRCodeText = styled.p`
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #475467;
`;

const QRCodeImage = styled(QRCode)`
    width: 150px;
    height: 150px;
`;

const CopyLinkButton = styled.button`
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: 1px solid #475467;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: #2b2d3f;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    stroke: #475467;
`;

function PlayOnRemoteDevice({
    broadcastIdentifier,
    isPlayerAppSupported,
    freePaidPathPartForPlayerAppLink,
}: {
    broadcastIdentifier: string;
    isPlayerAppSupported: boolean;
    freePaidPathPartForPlayerAppLink?: string;
}): React.ReactElement {
    const sessionLinkForTheClient = useSessionLinkForTheClient({
        broadcastIdentifier,
    });
    const appStoreLink = 'https://apps.apple.com/app/wavepaths-player/id6479019124';
    const appLink = useSessionLinkForTheNativeApp({
        broadcastIdentifier,
        freePaidPathPart: freePaidPathPartForPlayerAppLink,
    });

    const { isEnabled } = useAuthContext();
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    const copyWebClientLink = useCallback(() => {
        navigator.clipboard.writeText(sessionLinkForTheClient);
        setSnackbarContent('Web Player Link copied');
    }, [sessionLinkForTheClient]);

    const copyAppClientLink = useCallback(() => {
        navigator.clipboard.writeText(appLink);
        setSnackbarContent('Player App Link copied');
    }, [appLink]);

    const blockedToShareWithClient = isEnabled(Features.BLOCKED_REMOTE_LISTEN);

    const [selectedTab, setSelectedTab] = useState('web');
    const handleTabClick = (tab: string) => {
        setSelectedTab(tab);
    };

    return (
        <Container>
            {blockedToShareWithClient ? (
                <UpgradeMessage upgradeLink="/profile" />
            ) : (
                <>
                    {isPlayerAppSupported && (
                        <Tabs>
                            <Tab onClick={() => handleTabClick('web')} isActive={selectedTab === 'web'}>
                                Web browser
                            </Tab>
                            <Tab onClick={() => handleTabClick('app')} isActive={selectedTab === 'app'}>
                                Player app
                            </Tab>
                        </Tabs>
                    )}
                    <TabContent>
                        {selectedTab === 'web' && (
                            <TabPanel>
                                {isPlayerAppSupported && <PlayerAppMessage appStoreLink={appStoreLink} />}
                                <QRCodeContent>
                                    <QRCodeText>Open Session in web browser:</QRCodeText>
                                    <QRCodeImage value={sessionLinkForTheClient} />
                                </QRCodeContent>
                                <CopyLinkButton onClick={copyWebClientLink}>
                                    Copy link to web player
                                    <Icon src={CopyIcon} alt="Copy icon" />
                                </CopyLinkButton>
                            </TabPanel>
                        )}
                        {selectedTab === 'app' && (
                            <TabPanel>
                                <PlayerAppMessage appStoreLink={appStoreLink} />
                                <AppStoreContent appStoreLink={appStoreLink} />
                                <TextDivider text="Then" />
                                <QRCodeContent>
                                    <QRCodeText>Open Session in Player app:</QRCodeText>
                                    <QRCodeImage value={sessionLinkForTheClient} />
                                </QRCodeContent>
                                <CopyLinkButton onClick={copyAppClientLink}>
                                    Copy link for player app
                                    <Icon src={CopyIcon} alt="Copy icon" />
                                </CopyLinkButton>
                            </TabPanel>
                        )}
                    </TabContent>
                </>
            )}
        </Container>
    );
}

export default PlayOnRemoteDevice;
