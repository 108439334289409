import './SubscribeModal.scss';

import React from 'react';

import { InfoDialog, Typography } from '@/component-library';

interface ISubscribeModal {
    isOpen: boolean;
    closeModal: () => void;
    availableTiers?: string[];
}

const SubscribeModal = ({ isOpen, closeModal, availableTiers }: ISubscribeModal) => {
    let tiersText = '';
    if (availableTiers) {
        tiersText = `This is only available for ${availableTiers.join(', ')} ${
            availableTiers.length > 1 ? 'tiers' : 'tier'
        }.`;
    }
    return isOpen ? (
        <InfoDialog title={'Advanced feature'} open={isOpen} onClose={closeModal}>
            <Typography variant="body2">
                Please upgrade your subscription to access this feature.
                {tiersText}
                You can manage your subscription from your <a href="/profile">profile</a> or email support at{' '}
                <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>.
            </Typography>
        </InfoDialog>
    ) : (
        <></>
    );
};

export default SubscribeModal;
