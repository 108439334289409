import '../../../Guide.scss';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { SessionRenderType } from 'wavepaths-shared/core';

import { Features } from '@/features';

import { useAuthContext } from '../../../auth';
import * as sessionApi from '../../../common/api/sessionApi';
import LoadingOrb from '../../../common/components/LoadingOrb';
import { FreudConnection } from '../../../freudConnection/FreudConnection';
import useRemoteSession, { SessionContext } from '../autoGuide/useSession';
import { PlayerChoiceContext, usePlayerChoice } from '../deviceOptions/PlayerChoiceContext';
import { PrecomposedGuide } from './PrecomposedGuide';

export interface PrecomposedGuideParams {
    sessionId: string;
}

export const PrecomposedGuideContainer: React.FC<{
    requiredSubscription: boolean;
}> = ({ requiredSubscription }) => {
    const { sessionId } = useParams<PrecomposedGuideParams>();
    const { firebaseUser, isEnabled } = useAuthContext();
    const [connection] = useState<FreudConnection | null>(null);

    const { session } = useRemoteSession(sessionId, connection);

    useEffect(() => {
        if (sessionId && firebaseUser && session && session.userId != firebaseUser.uid) {
            sessionApi.upsertSessionSignup(sessionId, firebaseUser);
        }
    }, [sessionId, firebaseUser, session]);

    useEffect(() => {
        if (sessionId) {
            sessionApi.prolongSession(sessionId);
        }
    }, []);

    const playerChoiceHook = usePlayerChoice(session?.renderType ?? SessionRenderType.PREDICTIVE_COMPOSED);

    // hacky work around for circular dependency between useSession and useFreudConnection
    return session ? (
        <SessionContext.Provider value={session}>
            <PlayerChoiceContext.Provider value={playerChoiceHook}>
                <PrecomposedGuide
                    session={session}
                    firebaseUser={firebaseUser}
                    isEnabled={isEnabled}
                    playDemoVO={!isEnabled(Features.FREE_ACCESS) && requiredSubscription}
                />
            </PlayerChoiceContext.Provider>
        </SessionContext.Provider>
    ) : (
        // TODO: why does it have a different background color until it switches over to the guidewithconnection loader and
        <LoadingOrb />
    );
};
