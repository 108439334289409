import firebase from 'firebase';

import configs from '../configs';
import { FormResponse, FormResponseElement, FormTemplate, FormTemplateElement } from './formTypes';

const templateToResponseElement = (element: FormTemplateElement): FormResponseElement => {
    if (!['header', 'description'].includes(element.type)) {
        return {
            ...element,
            value: undefined,
        };
    } else {
        return element;
    }
};

export const formTemplateToResponseObject = (sessionId: string, formTemplate: FormTemplate): FormResponse => {
    return {
        templateReferences: [formTemplate.id],
        //TODO: Do not use Browser clock its unreliable
        dateCreated: new Date(),
        sessionId: sessionId,
        responderEmail: undefined,
        type: formTemplate.type,
        anonymous: formTemplate.anonymous,
        elements: formTemplate.elements.map((elem: FormTemplateElement) => templateToResponseElement(elem)),
    };
};

export const getFormTemplates = async (
    type: string,
    fbUser: firebase.User,
): Promise<{ formTemplates: FormTemplate[] }> => {
    try {
        const idToken = await fbUser.getIdToken();
        const response = await fetch(`${configs.cloudFunctions.URL}/formTemplates?type=${type}`, {
            method: 'GET',
            headers: { Authorization: `idToken ${idToken}`, 'Cache-Control': 'no-cache, no-store' },
        });
        return response.json();
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const postFormResponse = async (formResponse: FormResponse, fbUser: firebase.User): Promise<FormResponse> => {
    try {
        const idToken = await fbUser.getIdToken();
        const response = await fetch(`${configs.cloudFunctions.URL}/formResponses`, {
            method: 'POST',
            headers: {
                Authorization: `idToken ${idToken}`,
                'Cache-Control': 'no-cache, no-store',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formResponse),
        });
        return response.json();
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const getSessionFeedbackSurveyLink = (sessionId: string, creatorId: string): string => {
    // TODO patient id/email
    return (
        creatorId &&
        (process.env.ENDED_SESSION_FEEDBACK_SURVEY_LINK || 'https://form.typeform.com/to/xJkWzyAm#session_id=') +
            sessionId
    );
};

export const getRelativeSessionReplayLink = (sessionId: string): string => {
    return `/session/replay/${sessionId}`;
};

export const getRelativeSessionRecordingLink = (sessionId: string): string => {
    return `/session/recording/${sessionId}`;
};

export const getRelativeSessionAnonymousRecordingLink = (sessionId: string): string => {
    return `/session/anonymous/${sessionId}`;
};

export const getAbsoluteSessionRecordingLink = (sessionId: string): string => {
    const baseURL = configs.bonny.BASE_URL;
    const url = `${baseURL}${getRelativeSessionRecordingLink(sessionId)}`;

    return url;
};

export const getAbsoluteSessionAnonymousRecordingLink = (sessionId: string): string => {
    const baseURL = configs.bonny.BASE_URL;
    const url = `${baseURL}${getRelativeSessionAnonymousRecordingLink(sessionId)}`;

    return url;
};

export const getAbsoluteSessionIntegrationLink = (sessionId: string): string => {
    const url = new URL(getAbsoluteSessionRecordingLink(sessionId));
    url.searchParams.set('screen', 'end');
    return url.toString();
};
