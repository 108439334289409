import styled from '@emotion/styled';
import { format, isThisMonth, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FirebaseUser, SessionHeader } from 'wavepaths-shared/core';

import { isLastWeek, weekStartsOn } from '../../../dateUtilsV2';
import SessionCardSkeleton from '../SessionCardSkeleton';
import EndedSessionCard from './EndedSessionCard';
import { IEndedSessions } from './useEndedSessions';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--global-spacing);
`;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Heading = styled.h2`
    padding-left: 24px;
    font-family: var(--font-family-sans);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
`;

const Pagination = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: var(--global-spacing);
`;

const PaginationButton = styled.button`
    display: inline-flex;
    align-items: center;
    background: var(--color-primary);
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    height: 44px;
    padding: 0 24px;
`;

const PaginationCount = styled.div`
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
`;

interface EndedSessionsTableProps {
    endedSessionsInterface: IEndedSessions;
    signups: { [sessionId: string]: FirebaseUser[] };
    isAdmin: boolean;
}
interface IHeaderAndSession {
    header: string;
    sessions: SessionHeader[];
}

export const genArrOfHeadersAndSessions = (sessions: SessionHeader[]): IHeaderAndSession[] => {
    const sessionsToday = sessions.filter((session) => session.endedAt && isToday(session.endedAt));
    const sessionsYesterday = sessions.filter((session) => session.endedAt && isYesterday(session.endedAt));
    const sessionsThisWeek = sessions.filter(
        (session) => session.endedAt && isThisWeek(session.endedAt, { weekStartsOn }),
    );
    const sessionsLastWeek = sessions.filter((session) => session.endedAt && isLastWeek(session.endedAt));
    const sessionsThisMonth = sessions.filter((session) => session.endedAt && isThisMonth(session.endedAt));
    const sessionsThisYear = sessions.filter((session) => session.endedAt && isThisYear(session.endedAt));

    const restOfThisWeek: SessionHeader[] = _.differenceWith(
        sessionsThisWeek,
        _.flatten([sessionsToday, sessionsYesterday]),
        _.isEqual,
    );

    const restOfThisMonth: SessionHeader[] = _.differenceWith(
        sessionsThisMonth,
        _.flatten([sessionsYesterday, sessionsThisWeek, sessionsLastWeek]),
        _.isEqual,
    );

    const restOfTheYear = _.differenceWith(
        sessionsThisYear,
        _.flatten([sessionsYesterday, sessionsThisWeek, sessionsLastWeek, sessionsThisMonth]),
        _.isEqual,
    );
    const restOfTheYearGroupedByMonth = _.groupBy(
        restOfTheYear,
        (session) => session.endedAt && format(session.endedAt, 'MMMM'),
    );
    const restOfTheYearMapped = _.map(restOfTheYearGroupedByMonth, (sessions, key) => ({ header: key, sessions }));

    const restOfSessions = _.differenceWith(sessions, sessionsThisYear, _.isEqual);
    const restOfSessionsGroupedByMonthAndYear = _.groupBy(
        restOfSessions,
        (session) => session.endedAt && format(session.endedAt, "MMM ''yy"),
    );
    const restOfSessionsMapped = _.map(restOfSessionsGroupedByMonthAndYear, (sessions, key) => ({
        header: key,
        sessions,
    }));

    return [
        { header: 'Today', sessions: sessionsToday },
        { header: 'Yesterday', sessions: sessionsYesterday },
        { header: 'Earlier this Week', sessions: restOfThisWeek },
        { header: 'Last Week', sessions: sessionsLastWeek },
        { header: 'Earlier this Month', sessions: restOfThisMonth },
        ...restOfTheYearMapped,
        ...restOfSessionsMapped,
    ];
};

const EndedSessionsTable: React.FC<EndedSessionsTableProps> = ({
    endedSessionsInterface: { sessions, previousPage, nextPage, totalPageCount, currentPageIndex, loading },
    signups,
    isAdmin,
}) => {
    const [disableHide, setDisableHide] = useState<boolean>(false);
    useEffect(() => {
        if (totalPageCount && totalPageCount > 0) {
            setDisableHide(true);
        }
    }, [totalPageCount]);

    if (loading) {
        return (
            <div style={{ width: '100%' }}>
                <SessionCardSkeleton />
            </div>
        );
    }

    if (!disableHide && sessions.length === 0) {
        return <></>;
    }

    const headersAndSessions = genArrOfHeadersAndSessions(sessions).filter(({ sessions }) => sessions.length !== 0);

    const currentPage = currentPageIndex + 1;
    return (
        <Container>
            {headersAndSessions.map(({ header, sessions }, idx) => (
                <Section key={`${header}${idx}`}>
                    <Heading>{header}</Heading>
                    {sessions.map((session) => (
                        <div key={`${session.id}`}>
                            <EndedSessionCard key={session.id} session={session} signups={signups} isAdmin={isAdmin} />
                        </div>
                    ))}
                </Section>
            ))}
            {totalPageCount > 1 && (
                <Pagination>
                    <PaginationButton data-testid="lastPageButton" onClick={previousPage} disabled={currentPage <= 1}>
                        Previous
                    </PaginationButton>
                    <PaginationCount>{`Page ${currentPage} of ${totalPageCount}`}</PaginationCount>
                    <PaginationButton
                        data-testid="nextPageButton"
                        onClick={nextPage}
                        disabled={currentPage >= totalPageCount}
                    >
                        Next
                    </PaginationButton>
                </Pagination>
            )}
        </Container>
    );
};

export default EndedSessionsTable;
