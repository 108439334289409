import { sample } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    CoreEmotionalAtmosphere,
    PathScore,
    ScheduledExperimentalBridgePathScore,
    ScheduledWavepath,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { SegmentedControl } from '@/component-library';
import Container from '@/component-library/components/Control/Container';
import Heading from '@/component-library/components/Control/Heading';

import { WaveEditor, WavePreviewMode } from '../WaveEditor/WaveEditor';
import * as infoCopy from './infoCopy';
import { convertListToOptions, extractFromEmotionsFromScores, extractToEmotionsFromScores } from './pathScoreFns';
import { Instrumentation, InstrumentId, useWaveInstruments } from './useWaveInstruments';

interface BridgeWaveSelectorProps {
    pathScores: ScheduledExperimentalBridgePathScore[];
    wavepath: ScheduledWavepath;
    previewMode: WavePreviewMode;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isBridgePathScore = (p: PathScore): p is ScheduledExperimentalBridgePathScore =>
    p.direction === TherapeuticDirection.BRIDGE;

const filterScoresByFromEmotion = (
    scores: ScheduledExperimentalBridgePathScore[],
    fromEmotion?: CoreEmotionalAtmosphere,
) => scores.filter((score) => (fromEmotion && score.emotion.from ? score.emotion.from === fromEmotion : true));

const filterScoresByToEmotion = (scores: ScheduledExperimentalBridgePathScore[], toEmotion?: CoreEmotionalAtmosphere) =>
    scores.filter((score) => (toEmotion && score.emotion.to ? score.emotion.to === toEmotion : true));

function BridgeWaveSelector({ pathScores, wavepath, previewMode, onUpdateWave }: BridgeWaveSelectorProps): JSX.Element {
    const defaultFrom = isBridgePathScore(wavepath.pathScore)
        ? wavepath.pathScore.emotion.from
        : pathScores[0].emotion.from;
    const [fromEmotion, setFromEmotion] = useState(defaultFrom);

    const defaultTo = isBridgePathScore(wavepath.pathScore) ? wavepath.pathScore.emotion.to : pathScores[0].emotion.to;
    const [toEmotion, setToEmotion] = useState(defaultTo);

    const fromEmotionFilteredScores = filterScoresByFromEmotion(pathScores, fromEmotion);
    const toEmotionFilteredScores = filterScoresByToEmotion(fromEmotionFilteredScores, toEmotion);

    if (toEmotionFilteredScores.length == 0) {
        setToEmotion(fromEmotionFilteredScores[0].emotion.to);
    }

    useEffect(() => {
        const currentPathQualifies = toEmotionFilteredScores.find((x) => x.id == wavepath?.pathId);
        if (!currentPathQualifies) {
            console.debug('Current path doesnt qualify for criteria', { wavepath });
            const pathScore = sample(toEmotionFilteredScores);
            if (pathScore) {
                onUpdateWave({
                    ...wavepath,
                    duration: pathScore.duration,
                    preferredDuration: 'Auto',
                    pathScore,
                    pathId: pathScore.id,
                });
            }
        }
    }, [fromEmotion, toEmotion]);
    console.log('wavepath', wavepath);

    const { waveInstrumentations, applyInstrumentation } = useWaveInstruments(wavepath);

    const onUpdateInstrumentation = (instrumentId: InstrumentId, instrumentation: Instrumentation) => {
        const updatedWavepath = applyInstrumentation(instrumentId, instrumentation);
        onUpdateWave(updatedWavepath);
    };

    return (
        <>
            <SegmentedControl
                name="wave-fromEmotion"
                heading={'From'}
                canSave={false}
                size="small"
                colour="dark"
                options={convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.SILENCE,
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    extractFromEmotionsFromScores(pathScores),
                )}
                value={fromEmotion}
                onChange={setFromEmotion}
                info={infoCopy.emotionFrom}
            />
            <SegmentedControl
                name="wave-toEmotion"
                heading={'To'}
                canSave={false}
                size="small"
                colour="dark"
                options={convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.SILENCE,
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    extractToEmotionsFromScores(fromEmotionFilteredScores),
                )}
                value={toEmotion}
                onChange={setToEmotion}
                info={infoCopy.emotionTo}
            />
            <Container inline style={{ gridTemplateColumns: '104px 440px' }}>
                <Heading
                    id="wave-music"
                    heading={'Music'}
                    info={infoCopy.composition}
                    canSave={false}
                    colour="dark"
                    inline={false}
                >
                    Music
                </Heading>
                <WaveEditor
                    wavepath={wavepath}
                    waveInstrumentations={waveInstrumentations}
                    previewMode={previewMode}
                    onUpdateInstrumentation={onUpdateInstrumentation}
                />
            </Container>
        </>
    );
}

export default BridgeWaveSelector;
