import React from 'react';
import styled from 'styled-components';

import { formatDuration } from '../../dateUtilsV2';

const TimerWrapper = styled.div`
    display: flex;
    align-items: center;
    border-radius: 24px;
    background-color: #2b2d3f;
    font-family: inherit;
    font-weight: 600;
    color: white;
    font-size: 20px;
    height: 32px;
    border-radius: 16px;
    padding: 0px 16px;

    @media (min-height: 720px) {
        font-size: 24px;
        height: 48px;
        border-radius: 24px;
        padding: 0px 24px;
    }
`;

interface TimerProps {
    timeElapsedMs: number;
}

const Timer: React.FC<TimerProps> = React.memo(({ timeElapsedMs }) => {
    return <TimerWrapper>{timeElapsedMs > 0 ? formatDuration(timeElapsedMs) : '00:00'}</TimerWrapper>;
});

export default Timer;
