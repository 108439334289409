import {
    ClientVariablesMusicPreference,
    SessionScoreAdministration,
    SessionScoreDosage,
    SessionScoreModality,
    SessionType,
    SessionVariables,
} from 'wavepaths-shared/core';

export const GROUP_GUIDED_DEFAULTS: Partial<SessionVariables> = {
    totalDuration: 50,
};

export const GROUP_INFINITE_DEFAULTS: Partial<SessionVariables> = {
    totalDuration: 1500,
    preludeDuration: 0,
    postludeDuration: 0,
    nameOnWebsite: 'Soothing',
    preferSubmittedContent: 0,
};

export const ONE_ON_ONE_SESSION_DEFAULTS: Partial<SessionVariables> = {
    Acousticness: ClientVariablesMusicPreference.MIXED,
    preludeDuration: 240,
    postludeDuration: 240,
    preferSubmittedContent: 0,
    voiceover: 'none',
};

export const INTRO_SESSION_DEFAULTS: Partial<SessionVariables> = {};

export const SESSION_TYPE_DEFAULTS: { [key in SessionType]: Partial<SessionVariables> } = {
    [SessionType.ONE_ON_ONE]: ONE_ON_ONE_SESSION_DEFAULTS,
    [SessionType.GROUP_INFINITE]: GROUP_INFINITE_DEFAULTS,
    [SessionType.GROUP_GUIDED]: GROUP_GUIDED_DEFAULTS,
    [SessionType.INTRO]: INTRO_SESSION_DEFAULTS,
    [SessionType.B2C_SELF_GUIDED]: {},
    [SessionType.OFFSET_PREDICTIVE]: {},
    [SessionType.WAVE_PREVIEW]: {},
};

export const KETAMINE_DEFAULTS: Partial<SessionVariables> = {
    administration: SessionScoreAdministration.Intramuscular,
    dosage: SessionScoreDosage.MEDIUM,
};

export const MDMA_DEFAULTS: Partial<SessionVariables> = {
    administration: SessionScoreAdministration.Oral,
    dosage: SessionScoreDosage.HIGH,
};

export const IBOGAINE_DEFAULTS: Partial<SessionVariables> = {
    administration: SessionScoreAdministration.Oral,
    dosage: SessionScoreDosage.MEDIUM,
};

export const PSILOCYBIN_DEFAULTS: Partial<SessionVariables> = {
    administration: SessionScoreAdministration.Oral,
    dosage: SessionScoreDosage.MEDIUM,
};

export const MEDICINE_SPECIFIC_VARIABLES: { [key in SessionScoreModality]: Partial<SessionVariables> } = {
    [SessionScoreModality.KETAMINE]: KETAMINE_DEFAULTS,
    [SessionScoreModality.NONE]: {},
    [SessionScoreModality.MDMA]: MDMA_DEFAULTS,
    [SessionScoreModality.IBOGAINE]: IBOGAINE_DEFAULTS,
    [SessionScoreModality.PSILOCYBIN]: PSILOCYBIN_DEFAULTS,
    [SessionScoreModality.BREATHWORK]: {},
    [SessionScoreModality.FLOATATION]: {},
    [SessionScoreModality.PSYCHOTHERAPY]: {},
    [SessionScoreModality.WELLNESS]: {},
    [SessionScoreModality.CLIENT_PREPARATION]: {},
    [SessionScoreModality.AMBIENCE]: {},
};
