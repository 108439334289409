import { useState } from 'react';
import { useLocalStorage } from 'react-use';
import useSWR from 'swr';
import { ActiveBonnySubscription, BonnySubscription, GetBonnySubscriptionResponse } from 'wavepaths-shared/core';

import { offeringsList } from '../../pages/subscriptions/data/offerings';
import { getUserSubscription } from '../api/bonnyApi';
import { hasActiveSubscription } from './useMySubscription';

interface IUseUserSubscriptionReturn {
    subscription?: BonnySubscription;
    error: Error | undefined;
    isLoading: boolean;
    userHasActiveSubscription: boolean;
    activeOfferingTitle: string | undefined;
    activeOfferingTier: string | undefined;
}

const options = {
    dedupingInterval: 5 * 60 * 1000,
};

const useUserSubscription = (userId: string): IUseUserSubscriptionReturn => {
    const [error] = useState<Error | undefined>(undefined);
    const [membershipCacheKey] = useLocalStorage('membershipCacheKey', '');

    const { error: getMySubscriptionError, data, isLoading } = useSWR<GetBonnySubscriptionResponse, Error>(
        ['/user/subscription', userId, membershipCacheKey],
        () => getUserSubscription(userId),
        options,
    );

    const userHasActiveSubscription = hasActiveSubscription(data?.subscription);
    const activeSubscription = data?.subscription as ActiveBonnySubscription;
    const activeOffering =
        activeSubscription && activeSubscription.tier
            ? offeringsList.find((x) => x.tier == activeSubscription.tier)
            : undefined;

    return {
        subscription: data?.subscription,
        isLoading,
        error: error ?? getMySubscriptionError,
        userHasActiveSubscription: userHasActiveSubscription,
        activeOfferingTitle: activeOffering ? activeOffering.offeringTitle : undefined,
        activeOfferingTier: activeOffering ? activeOffering.tier : undefined,
    };
};

export default useUserSubscription;
