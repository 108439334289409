import { EventEmitter } from 'events';
import { isNumber } from 'lodash';

import { AudioPlayerStreamPhase } from './AudioPlayerProvider';

export type PlayerLibStream = {
    id: string; // const char*
    phase: AudioPlayerStreamPhase; // enum WpPlayerLibPhase
    url: string; // const char*
    fromTime: number; // uint64_t
    toTime: number; // uint64_t
    fadeOutTime: number; // uint64_t
    loopContent: boolean; // uint8_t
    gain: number; // float
    usesSidechain: boolean; // bool
    sidechainGain: number; // float
};

export class PlayerLibInterface extends EventEmitter {
    private static playerWasmCodeP: Promise<any> | null = null;
    private static previousDestroyedP = Promise.resolve();

    private playerWasmP: Promise<any> | null = null;
    private playerWasm: any | null = null;
    private playerRef: number | null = null;
    private onDestroy: (() => void) | null = null;

    private broadcastElapsedTimeSecs = 0;

    constructor() {
        super();
        if (PlayerLibInterface.playerWasmCodeP === null) {
            PlayerLibInterface.playerWasmCodeP = import(
                /* webpackIgnore: true */ '/playerlib/WpPlayerlibWasm.js' as any
            );
        }

        const thisDestroyedP = new Promise<void>((resolve) => {
            this.onDestroy = resolve;
        });

        this.playerWasmP = PlayerLibInterface.previousDestroyedP.then(() => {
            PlayerLibInterface.previousDestroyedP = thisDestroyedP;
            return PlayerLibInterface.playerWasmCodeP!.then((module) => module.default());
        });
    }

    async init() {
        this.playerWasm = await this.playerWasmP;
        // playerWasm.emscriptenRegisterAudioObject(audioCtx);
        if ('audioSession' in navigator) {
            (navigator as any).audioSession.type = 'playback';
        }
        this.playerRef = await this.playerWasm.ccall('wp_playerlib_wasm_create', 'number', ['number'], [48000], {
            async: true,
        });
    }

    audioCtx(): AudioContext {
        const windowWeak = window as any;
        const miniaudioCtx = windowWeak.miniaudio?.get_device_by_index(0).webaudio;
        if (!miniaudioCtx) {
            throw new Error('No miniaudio context found');
        }
        return miniaudioCtx;
    }

    async setSession(streams: PlayerLibStream[]) {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        try {
            const streamsPtr = this.playerWasm.ccall(
                'wp_playerlib_wasm_malloc_streams',
                'number',
                ['number'],
                [streams.length],
            );
            const stringPtrs: number[] = [];
            streams.forEach(
                (
                    { id, url, phase, fromTime, toTime, fadeOutTime, loopContent, gain, usesSidechain, sidechainGain },
                    i,
                ) => {
                    const idPtr = this.playerWasm._malloc(id.length + 1);
                    this.playerWasm.stringToUTF8(id, idPtr, id.length + 1);
                    const urlPtr = this.playerWasm._malloc(url.length + 1);
                    this.playerWasm.stringToUTF8(url, urlPtr, url.length + 1);
                    this.playerWasm.ccall(
                        'wp_playerlib_wasm_set_stream',
                        'void',
                        [
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                            'number',
                        ],
                        [
                            streamsPtr,
                            i,
                            idPtr,
                            phase,
                            urlPtr,
                            BigInt(Math.round(fromTime)),
                            BigInt(Math.round(toTime)),
                            BigInt(Math.round(fadeOutTime)),
                            loopContent ? 1 : 0,
                            gain,
                            usesSidechain ? 1 : 0,
                            sidechainGain,
                        ],
                    );
                    stringPtrs.push(idPtr, urlPtr);
                },
            );
            this.playerWasm.ccall(
                'wp_playerlib_wasm_set_session',
                'void',
                ['number', 'number', 'number'],
                [this.playerRef, streamsPtr, streams.length],
            );
            stringPtrs.forEach((ptr) => this.playerWasm._free(ptr));
            this.playerWasm._free(streamsPtr);
        } catch (e) {
            console.error(e);
        }
    }

    setBroadcastElapsedTimeSecs(secs: number) {
        this.broadcastElapsedTimeSecs = secs;
    }

    getPhase(): AudioPlayerStreamPhase {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        return this.playerWasm.ccall('wp_playerlib_wasm_get_phase', 'number', ['number'], [this.playerRef]);
    }

    getTimeInPhase(): number {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        const timeMs = this.playerWasm.ccall(
            'wp_playerlib_wasm_get_time_in_phase',
            'bigint',
            ['number'],
            [this.playerRef],
        );
        return Number(timeMs) / 1000;
    }

    setPhase(phase: AudioPlayerStreamPhase) {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        let timeWithinPhase = 0;
        if (phase === AudioPlayerStreamPhase.Session) {
            timeWithinPhase = this.broadcastElapsedTimeSecs * 1000;
        }
        this.playerWasm.ccall(
            'wp_playerlib_wasm_set_phase',
            'void',
            ['number', 'number', 'number'],
            [this.playerRef, phase, BigInt(Math.round(timeWithinPhase))],
        );
    }

    seekToTimeInPhase(timeWithinPhase: number) {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        this.playerWasm.ccall(
            'wp_playerlib_wasm_seek_to_time_in_phase',
            'void',
            ['number', 'number'],
            [this.playerRef, BigInt(Math.round(timeWithinPhase * 1000))],
        );
    }

    setVolume(volume: number) {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        this.playerWasm.ccall('wp_playerlib_wasm_set_volume', 'void', ['number', 'number'], [this.playerRef, volume]);
    }

    getBufferedTime(): number {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        return this.playerWasm.ccall('wp_playerlib_wasm_get_buffered_time', 'number', ['number'], [this.playerRef]);
    }

    start() {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        this.playerWasm.ccall('wp_playerlib_wasm_start', 'void', ['number'], [this.playerRef]);
    }

    stop() {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        this.playerWasm.ccall('wp_playerlib_wasm_stop', 'void', ['number'], [this.playerRef]);
    }

    isStarted() {
        if (!this.playerWasm || !isNumber(this.playerRef)) {
            throw new Error('Player not initialized');
        }
        return this.playerWasm.ccall('wp_playerlib_wasm_is_started', 'boolean', ['number'], [this.playerRef]);
    }

    async destroy() {
        if (this.playerWasm && isNumber(this.playerRef)) {
            const audioCtx = this.audioCtx();
            this.playerWasm.ccall('wp_playerlib_wasm_destroy', 'void', ['number'], [this.playerRef]);
            await audioCtx.close(); // for some reason emscripten's emscripten_destroy_audio_context only suspends, so we need to close to not leak resources
        }
        this.onDestroy?.();
    }
}
