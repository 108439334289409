import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

const Container = styled.div`
    background: #fef0c7;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Heading = styled.h3`
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: #344054;
`;

const Text = styled.p`
    padding: 0;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #475467;
    a {
        color: #475467;
        text-decoration: underline;
        font-weight: normal;
    }
`;

interface PlayerAppMessageProps {
    heading?: string;
    text?: ReactNode;
    appStoreLink?: string;
}

export default function PlayerAppMessage({ heading, text, appStoreLink }: PlayerAppMessageProps): React.ReactElement {
    const defaultHeader = 'Enhance stability with our Player app';
    const defaultText = (
        <>
            For best performance in longer Sessions,{' '}
            <a href={appStoreLink} target="_blank" rel="noreferrer">
                download our free macOS or iOS app
            </a>
            . Windows and Android coming soon!
        </>
    );

    return (
        <Container>
            <Heading>{heading || defaultHeader}</Heading>
            <Text>{text || defaultText}</Text>
        </Container>
    );
}
