import React, { useState } from 'react';
import {
    Acousticness,
    BaseScheduledGenerativePathScore,
    CoreEmotionalAtmosphere,
    isScheduledGenerativeDeepenPathScore,
    PathScore,
    PathScoreModes,
    ScheduledExperimentalPathScore,
    ScheduledGenerativeDeepenPathScore,
    ScheduledWavepath,
    SessionScoreEmotionalIntensity,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';
import Container from '@/component-library/components/Control/Container';
import Heading from '@/component-library/components/Control/Heading';

import { WaveEditor, WavePreviewMode } from '../WaveEditor/WaveEditor';
import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';
import { usePathScoreDurationSelector } from './usePathScoreDurationSelector';
import { Instrumentation, InstrumentId, useWaveInstruments } from './useWaveInstruments';

export interface SelectorState {
    mode?: PathScoreModes;
    emotion?: CoreEmotionalAtmosphere;
    tone?: Acousticness;
    intensity?: SessionScoreEmotionalIntensity;
    music?: string;
    preferredDuration: number | 'Auto';
}

const selectorStateDefault: SelectorState = {
    emotion: CoreEmotionalAtmosphere.STILLNESS,
    intensity: SessionScoreEmotionalIntensity.LOW,
    preferredDuration: pathScoreFns.AUTO_VALUE,
};

export type DeepenPathScore =
    | BaseScheduledGenerativePathScore
    | ScheduledGenerativeDeepenPathScore
    | ScheduledExperimentalPathScore;

interface TonalDeepenWaveSelectorProps {
    pathScores: DeepenPathScore[];
    wavepath: ScheduledWavepath;
    previewMode: WavePreviewMode;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isDeepenPathScore = (p: PathScore): p is DeepenPathScore => p.direction === TherapeuticDirection.DEEPEN;

function TonalDeepenWaveSelector({
    pathScores,
    wavepath,
    previewMode,
    onUpdateWave,
}: TonalDeepenWaveSelectorProps): JSX.Element {
    const filteredPathScores = pathScores.filter(isScheduledGenerativeDeepenPathScore);

    const [emotion, setEmotion] = useState(
        isScheduledGenerativeDeepenPathScore(wavepath.pathScore)
            ? wavepath.pathScore.emotion
            : selectorStateDefault.emotion,
    );
    const emotionFilteredScores = pathScoreFns.filterScoresByEmotion(filteredPathScores, emotion);

    // We use music=Any here to pick the deepen path score that has not music specified.
    // The specific music ones are legacy, and should be removed when we have committed to the new music controls for all users.
    // Then we can remove this music filtering.
    const music = 'Any';
    const preDurationFilteredScores = pathScoreFns.filterScoresByMusic(emotionFilteredScores, music);

    const { waveInstrumentations, applyInstrumentation } = useWaveInstruments(wavepath);

    const onUpdateInstrumentation = (instrumentId: InstrumentId, instrumentation: Instrumentation) => {
        const updatedWavepath = applyInstrumentation(instrumentId, instrumentation);
        onUpdateWave(updatedWavepath);
    };

    const { durationOptions, preferredDuration, setPreferredDuration } = usePathScoreDurationSelector({
        wavepath,
        onUpdateWave,
        preDurationFilteredScores,
        dependencies: [emotion, music],
    });

    return (
        <>
            <SegmentedControl
                name="wave-emotion"
                heading={'Emotionality'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    pathScoreFns.extractEmotionsFromScores(filteredPathScores),
                )}
                value={emotion}
                onChange={setEmotion}
                info={infoCopy.emotion}
            />
            <Container inline style={{ gridTemplateColumns: '104px 440px' }}>
                <Heading
                    id="wave-music"
                    heading={'Music'}
                    info={infoCopy.composition}
                    canSave={false}
                    colour="dark"
                    inline={false}
                >
                    Music
                </Heading>
                <WaveEditor
                    wavepath={wavepath}
                    waveInstrumentations={waveInstrumentations}
                    previewMode={previewMode}
                    onUpdateInstrumentation={onUpdateInstrumentation}
                />
            </Container>
            <DropdownControl
                name="wave-duration"
                heading={'Duration'}
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={preferredDuration}
                onChange={setPreferredDuration}
                info={infoCopy.duration}
            />
        </>
    );
}

export default TonalDeepenWaveSelector;
