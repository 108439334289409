import React, { useState } from 'react';
import { PathScore, ScheduledGenerativePathScore, TherapeuticDirection, Wavepath } from 'wavepaths-shared/core';

import { DropdownControl } from '@/component-library';

import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';
import { usePathScoreDurationSelector } from './usePathScoreDurationSelector';

export type SilencePathScore = ScheduledGenerativePathScore & {
    therapeuticDirection: TherapeuticDirection.SILENCE;
};

interface SilenceWaveSelectorProps {
    pathScores: SilencePathScore[];
    wavepath: Wavepath;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isSilencePathScore = (p: PathScore): p is SilencePathScore => p.direction === TherapeuticDirection.SILENCE;

function SilenceWaveSelector({ pathScores, wavepath, onUpdateWave }: SilenceWaveSelectorProps): JSX.Element {
    const nameOptions = pathScoreFns.extractNameOptionsFromScores(pathScores);

    const defaultName =
        wavepath.pathScore.direction === TherapeuticDirection.SILENCE ? wavepath.pathScore.name : pathScores[0].name;
    const [name, setName] = useState(defaultName);

    const nameFilteredScores = pathScoreFns.filterScoresByName(pathScores, name);
    const { durationOptions, preferredDuration, setPreferredDuration } = usePathScoreDurationSelector({
        wavepath,
        onUpdateWave,
        preDurationFilteredScores: nameFilteredScores,
        dependencies: [name],
    });

    return (
        <>
            <DropdownControl
                name="wave-silence-type"
                heading={'Type'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(nameOptions, new Set(nameOptions))}
                value={name}
                onChange={setName}
                info={infoCopy.composition}
            />
            <DropdownControl
                name="wave-silence"
                heading={'Duration'}
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={preferredDuration}
                onChange={setPreferredDuration}
                info={infoCopy.duration}
            />
        </>
    );
}

export default SilenceWaveSelector;
