import styled from '@emotion/styled';
import { isEqual } from 'lodash';
import React from 'react';

import { Instrumentation } from '@/components/WavePathSelector/useWaveInstruments';
import { InstrumentPreview } from '@/hooks/useInstrumentPreview';

const PlayPauseButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 8px;
    background: none;
    border: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    height: 44px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    transition: background-color 0.15s ease;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    svg {
        width: 24px;
        height: 24px;
        fill: var(--color-primary);
    }
`;

interface SoundPreviewProps {
    instrumentPreview: InstrumentPreview;
    instrumentation: Instrumentation;
}

export const SoundPreview: React.FC<SoundPreviewProps> = ({ instrumentPreview, instrumentation }) => {
    const isPreviewing =
        instrumentPreview.enabled &&
        instrumentPreview.state.state !== 'notPlaying' &&
        isEqual(instrumentPreview.state.instrument, instrumentation);
    const canPreview =
        instrumentPreview.enabled &&
        instrumentation?.type !== 'undetermined' &&
        instrumentation?.type !== 'determinedWithNoContent' &&
        (instrumentPreview.state.state === 'notPlaying' ||
            !isEqual(instrumentPreview.state.instrument, instrumentation));

    return (
        <>
            {isPreviewing && (
                <PlayPauseButton
                    onClick={(e) => {
                        e.stopPropagation();
                        instrumentPreview.stop();
                    }}
                >
                    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                        <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                    </svg>
                    Stop
                </PlayPauseButton>
            )}
            {canPreview && (
                <PlayPauseButton
                    onClick={(e) => {
                        e.stopPropagation();
                        instrumentation && instrumentPreview.start(instrumentation);
                    }}
                >
                    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 38V10L36 24L14 38Z" />
                    </svg>
                    Preview
                </PlayPauseButton>
            )}
        </>
    );
};
