// For historical sessions, for some time after feature flag enabling for new sessions,

import { useEffect, useState } from 'react';
import { Session } from 'wavepaths-shared/core';

import configs from '../../../configs';

// we may still need to use the hls player. This sniffs the type from the stream playlist.
type SniffedAudioPlayerTypeResolution = 'unknown' | 'hls' | 'playerlib';
const useSniffedAudioPlayerTypeResolution = (session: Session) => {
    const [
        sniffedAudioPlayerTypeResolution,
        setSniffedAudioPlayerTypeResolution,
    ] = useState<SniffedAudioPlayerTypeResolution>('unknown');
    const playerLibStreamUrl = `${configs.freud.STREAM_BASE}/${session.broadcastIdentifier}/${session.id}/stream.m3u8`;
    const hlsStreamUrl = `${configs.freud.STREAM_BASE}/${session.broadcastIdentifier}/stream.m3u8`;
    useEffect(() => {
        let stillMounted = true;
        const tryPlayerLibStream = () => fetch(playerLibStreamUrl, { method: 'HEAD' }).then((res) => res.status < 300);
        const tryHlsStream = () => fetch(hlsStreamUrl, { method: 'HEAD' }).then((res) => res.status < 300);
        const tryStreams = async (attempts = 50) => {
            if (!stillMounted) return;
            const [playerLibStream, hlsStream] = await Promise.all([tryPlayerLibStream(), tryHlsStream()]);
            if (!stillMounted) return;
            if (playerLibStream) {
                setSniffedAudioPlayerTypeResolution('playerlib');
            } else if (hlsStream) {
                setSniffedAudioPlayerTypeResolution('hls');
            } else if (attempts > 0) {
                setTimeout(() => tryStreams(attempts - 1), 1000);
            } else {
                setSniffedAudioPlayerTypeResolution('unknown');
            }
        };
        tryStreams();
        return () => {
            stillMounted = false;
        };
    }, [session]);
    return sniffedAudioPlayerTypeResolution;
};

export const useSniffedAudioPlayerTypeStreamUrl = (session: Session) => {
    const playerTypeResolution = useSniffedAudioPlayerTypeResolution(session);
    const streamUrl =
        playerTypeResolution === 'playerlib'
            ? `${configs.freud.STREAM_BASE}/${session.broadcastIdentifier}/${session.id}/stream.m3u8`
            : playerTypeResolution === 'hls'
            ? `${configs.freud.STREAM_BASE}/${session.broadcastIdentifier}/stream.m3u8`
            : undefined;
    return { streamUrl, playerTypeResolution };
};
