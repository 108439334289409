import styled from '@emotion/styled';
import React from 'react';

import {
    getComposerName,
    getInstrumentName,
    getInstrumentTags,
    Instrumentation,
} from '@/components/WavePathSelector/useWaveInstruments';

import IconClose from '../../../../images/icons/icon-close.svg';

const Background = styled.div`
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
    z-index: 5;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    padding: 56px 24px 24px 24px;
    border-radius: 24px 24px 0 0;
    background: #fff;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
`;

const InstrumentName = styled.h3`
    font-size: var(--font-size-large);
    line-height: 1;
    margin: 0;
`;

const ComposerName = styled.h4`
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-large);
    line-height: 1;
    opacity: 0.6;
    margin: 0;
`;

const ButtonClose = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 0.2s ease;
    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
    svg {
        width: 24px;
        height: 24px;
    }
`;

const Content = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;

const Description = styled.p`
    text-align: center;
    font-size: var(--font-size-small);
    margin: 0;
`;

const Tags = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
`;

const Tag = styled.div`
    border-radius: 16px;
    border: 1px solid rgba(43, 45, 62, 0.2);
    padding: 4px 8px;
    font-size: var(--font-size-xsmall);
    font-weight: var(--font-weight-xsmal);
    color: var(--color-primary);
`;

const Actions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface SoundDetailsProps {
    instrumentation: Instrumentation;
    onClose: () => void;
    children?: React.ReactNode;
}

export const SoundDetails: React.FC<SoundDetailsProps> = ({ instrumentation: instrumentation, onClose, children }) => {
    return (
        <Background>
            <Container>
                <Header>
                    <InstrumentName>
                        {getInstrumentName(instrumentation)}{' '}
                        <ButtonClose onClick={onClose}>
                            <img src={IconClose} alt="Close" />
                        </ButtonClose>
                    </InstrumentName>
                    <ComposerName>
                        {getComposerName(instrumentation)}
                        {instrumentation.type === 'undetermined' && <>Wavepaths</>}
                    </ComposerName>
                </Header>
                <Content>
                    <Description>
                        {instrumentation.type === 'undetermined' && (
                            <>Wavepaths will select an instrument during the session.</>
                        )}
                        {instrumentation.type === 'determinedWithNoContent' && <>This instrument will be silent.</>}
                    </Description>
                    <Tags>
                        {getInstrumentTags(instrumentation).map((t) => (
                            <Tag key={t}>{t}</Tag>
                        ))}
                    </Tags>
                </Content>
                <Actions>{children}</Actions>
            </Container>
        </Background>
    );
};
