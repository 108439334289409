import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQueryParam } from 'use-query-params';
import { isAdmin as checkIsAdmin } from 'wavepaths-shared/domain/user';

import { Button } from '@/component-library';
import Confirm from '@/component-library/components/Dialog';
import DashboardPageGuidance from '@/components/Dashboard/DashboardPageGuidance';
import DashboardPageHeading from '@/components/Dashboard/DashboardPageHeading';

import { useAuthContext } from '../../auth';
import { NewsArticleUpsertData } from '../../common/api/newsApi';
import { LayoutContainer } from '../../LayoutContainer';
import { NewsArticle } from './NewsArticle';
import { NewsArticleForm, NewsArticleFormState } from './NewsArticleForm';
import {
    NewsArticle as INewsArticle,
    useNews,
    useNewsArchiveArticle,
    useNewsCreateArticle,
    useNewsUnarchiveArticle,
    useNewsUpdateArticle,
    useNewsViewArticle,
} from './useNews';

const Container = styled.div``;

const AdminActions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    gap: 16px;
`;

const Tag = styled.div`
    display: flex;
    align-items: center;
    background-color: #ff9800;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
`;

const Content = styled.div``;

const open = () => ({ isOpen: true });
const openArticle = (id: string) => ({ isOpen: true, articleId: id });
const closed = () => ({ isOpen: false });

function NewsContainer() {
    const { userData } = useAuthContext();
    const isAdmin = !!userData && checkIsAdmin(userData);
    const [formDialogState, setFormDialogState] = useState<{ isOpen: boolean; articleId?: string }>(closed);
    const [confirmArchiveState, setConfirmArchiveState] = useState<{ isOpen: boolean; articleId?: string }>(closed);
    const [confirmUnarchiveState, setConfirmUnarchiveState] = useState<{ isOpen: boolean; articleId?: string }>(closed);
    const [isIncludeArchived, setIsIncludeArchived] = useState(isAdmin);

    const { news, isLoading, refresh } = useNews({
        includeArchived: isIncludeArchived,
    });
    const { mutate: create } = useNewsCreateArticle({
        onSuccess: refresh,
    });
    const { mutate: update } = useNewsUpdateArticle({
        onSuccess: refresh,
    });
    const { mutate: archive } = useNewsArchiveArticle({
        onSuccess: refresh,
    });
    const { mutate: unarchive } = useNewsUnarchiveArticle({
        onSuccess: refresh,
    });
    const { mutate: view } = useNewsViewArticle({
        onSuccess: refresh,
    });

    const [article, setArticle] = useQueryParam<string | undefined>('article');

    const articleSelected = (news || []).find((n) => n.id === article);

    const onSelectArticle = (article: INewsArticle | undefined) => {
        if (article && !article.isViewed) {
            onView(article.id);
        }
        setArticle(article?.id || undefined);
    };

    const onSubmitDialog = (article: NewsArticleFormState) => {
        const data: NewsArticleUpsertData = {
            tag: article.tag,
            description: article.description,
            shortDescription: article.shortDescription,
            publishedAt: article.date?.toISOString(),
        };
        const promise = formDialogState.articleId ? update(formDialogState.articleId, data) : create(data);
        promise.then(() => setFormDialogState(closed)).catch((e) => console.error(e));
    };

    const onArchive = (id: string) => {
        archive(id)
            .then(() => setConfirmArchiveState(closed))
            .catch((e) => console.error(e));
    };

    const onView = (id: string) => {
        view(id).catch((e) => console.error(e));
    };

    const onUnarchive = (id: string) => {
        unarchive(id)
            .then(() => setConfirmUnarchiveState(closed))
            .catch((e) => console.error(e));
    };

    const articleInForm = useMemo(() => {
        if (!formDialogState.isOpen) {
            return undefined;
        }
        return news?.find((n) => n.id === formDialogState.articleId) || undefined;
    }, [formDialogState.isOpen, formDialogState.articleId, news]);

    return (
        <Content>
            <DashboardPageHeading pageTitle="News" />
            <DashboardPageGuidance
                pageTitle="News"
                guidanceText="Discover the latest updates on our product development, including announcements of improvements, new features and upcoming events."
            />
            {isAdmin && (
                <AdminActions>
                    <Button variant="solid-blue" onClick={() => setFormDialogState(open)}>
                        Add new
                    </Button>

                    {isIncludeArchived ? (
                        <Button variant="solid-blue" onClick={() => setIsIncludeArchived(false)}>
                            Hide archived
                        </Button>
                    ) : (
                        <Button variant="solid-blue" onClick={() => setIsIncludeArchived(true)}>
                            Show archived
                        </Button>
                    )}

                    {formDialogState.isOpen ? (
                        <Dialog open={true} onClose={() => setFormDialogState(closed)} fullWidth={true} maxWidth={'md'}>
                            <DialogTitle>Add new Post</DialogTitle>
                            <DialogContent>
                                <NewsArticleForm initialState={articleInForm} onSubmit={onSubmitDialog} />
                            </DialogContent>
                        </Dialog>
                    ) : (
                        <></>
                    )}

                    <Confirm
                        fullWidth={true}
                        open={confirmArchiveState.isOpen}
                        message={'Are you sure you want to archive this article?'}
                        onClose={() => setConfirmArchiveState(closed)}
                        onConfirm={() => confirmArchiveState.articleId && onArchive(confirmArchiveState.articleId)}
                        confirmText={'Yes, ARCHIVE'}
                    />

                    <Confirm
                        fullWidth={true}
                        open={confirmUnarchiveState.isOpen}
                        message={'Are you sure you want to restore this article?'}
                        onClose={() => setConfirmUnarchiveState(closed)}
                        onConfirm={() =>
                            confirmUnarchiveState.articleId && onUnarchive(confirmUnarchiveState.articleId)
                        }
                        confirmText={'Yes, RESTORE'}
                    />
                </AdminActions>
            )}

            <Container>
                {articleSelected && (
                    <NewsArticle
                        key={articleSelected.id}
                        article={articleSelected}
                        onClick={() => onSelectArticle(undefined)}
                        isExpanded
                    />
                )}

                {isLoading && <>Loading...</>}

                {!articleSelected &&
                    !isLoading &&
                    news.map((n) => (
                        <NewsArticle key={n.id} article={n} onClick={() => onSelectArticle(n)}>
                            {isAdmin && (
                                <AdminActions>
                                    {isAdmin && n.isArchived && <Tag>Archived</Tag>}

                                    <Button variant="solid-blue" onClick={() => setFormDialogState(openArticle(n.id))}>
                                        Edit
                                    </Button>

                                    {n.isArchived ? (
                                        <Button
                                            variant="solid-blue"
                                            onClick={() => setConfirmUnarchiveState(openArticle(n.id))}
                                        >
                                            Restore
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="solid-blue"
                                            onClick={() => setConfirmArchiveState(openArticle(n.id))}
                                        >
                                            Archive
                                        </Button>
                                    )}
                                </AdminActions>
                            )}
                        </NewsArticle>
                    ))}

                {!news.length && !isLoading && !articleSelected && <p>No news available</p>}
            </Container>
        </Content>
    );
}

function NewsWithNav() {
    return (
        <LayoutContainer>
            <NewsContainer />
        </LayoutContainer>
    );
}

export default NewsWithNav;
