import { ClientVariablesMusicPreference, SessionRenderType, SessionScoreSessionUse } from 'wavepaths-shared/core';

export const renderTypeOptions = [SessionRenderType.PREDICTIVE_COMPOSED, SessionRenderType.PRE_RENDERED];

export function getRenderTypeLabel(renderType: SessionRenderType) {
    switch (renderType) {
        case SessionRenderType.PREDICTIVE_COMPOSED:
            return 'Live (Real-time)';
        case SessionRenderType.PRE_RENDERED:
            return 'Pre-recorded (Playback-only / Offline)';
        case SessionRenderType.REAL_TIME_DEPRECATED:
            return 'Real-time (deprecated)'; // should not actually be rendered
        default:
            return 'Unknown';
    }
}

export const sessionUseOptions = [
    {
        value: SessionScoreSessionUse.IN_PERSON,
        label: 'Therapeutic',
    },
    {
        value: SessionScoreSessionUse.TESTING,
        label: 'Testing Only',
    },
];

export const contentStatusOptions: { label: string; value: 'Approved' | 'Submitted' | 'All' }[] = [
    {
        value: 'Approved',
        label: 'Approved',
    },
    {
        value: 'Submitted',
        label: 'Submitted',
    },
    {
        value: 'All',
        label: 'All',
    },
];

export const musicalPreferenceOptions = [
    {
        value: ClientVariablesMusicPreference.ACOUSTIC,
        label: 'Acoustic',
    },
    {
        value: ClientVariablesMusicPreference.MIXED,
        label: 'Mixed',
    },
    {
        value: ClientVariablesMusicPreference.ELECTRONIC,
        label: 'Electronic',
    },
];

export type SchedulingStyle = 'now' | 'later' | 'specificTime';

export const schedulingOptions: { value: SchedulingStyle; label: string }[] = [
    {
        value: 'now',
        label: 'Now',
    },
    {
        value: 'later',
        label: 'An unspecified time in the future',
    },
    {
        value: 'specificTime',
        label: 'Later at a specific time',
    },
];

export const clientStartingTypeOptions: { value: number; label: string }[] = [
    {
        value: 0,
        label: 'Care-Provider Only',
    },
    {
        value: 1,
        label: 'Client or Care-Provider',
    },
];

//TODO clean this up
export const voiceoverOptions: { value: string; label: string }[] = [
    {
        label: 'None',
        value: 'none',
    },
    {
        label: 'Josie Taylor Long (en-GB)',
        value: 'josie-taylor',
    },
    {
        label: 'Josie Taylor Short 01 (en-GB)',
        value: 'josie-taylor-short-01',
    },
    {
        label: 'Josie Taylor Short 02 (en-GB)',
        value: 'josie-taylor-short-02',
    },
];
