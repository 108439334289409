import { sample } from 'lodash';
import { useEffect, useState } from 'react';
import { ScheduledPathScore, Wavepath } from 'wavepaths-shared/core';

import { ControlOption } from '@/component-library/components/Control/types';

import * as pathScoreFns from './pathScoreFns';
import WavePathSelectorLegacy from './WavePathSelectorLegacy';

export const usePathScoreDurationSelector = ({
    wavepath,
    preDurationFilteredScores,
    onUpdateWave,
    dependencies,
}: {
    wavepath: Wavepath;
    preDurationFilteredScores: ScheduledPathScore[];
    onUpdateWave: (updatedWavepath: Wavepath) => void;
    dependencies: any[];
}) => {
    const durationPossibleFromScores = pathScoreFns.extractDurationsFromScores(preDurationFilteredScores);
    const durationOptions: ControlOption<number | 'Auto'>[] = [
        ...[...durationPossibleFromScores].map(
            (value): ControlOption<number | 'Auto'> => ({
                label: value === pathScoreFns.AUTO_VALUE ? value : `${value} minutes`,
                value: value,
                disabled: false,
            }),
        ),
    ];
    const [duration, setDuration] = useState<number | undefined>(wavepath.duration);
    const [preferredDuration, _setPreferredDuration] = useState(wavepath.preferredDuration ?? 'Auto');

    const setPreferredDuration = (x: number | 'Auto') => {
        console.debug('setter', x, preferredDuration);

        const newDuration = x === 'Auto' ? undefined : x;
        newDuration !== duration && setDuration(newDuration);
        x !== preferredDuration && _setPreferredDuration(x);
    };

    const durationFilteredScores = pathScoreFns.filterScoresByDuration(preDurationFilteredScores, duration);

    const selectedDurationOption = durationOptions.find((option) => option.value === preferredDuration);
    if (durationOptions.length && !selectedDurationOption) {
        const durationCanBeAuto = durationOptions.find((x) => x.value == pathScoreFns.AUTO_VALUE);
        if (durationCanBeAuto) {
            console.debug('setPreferredDuration 2');
            setPreferredDuration('Auto');
        } else if (durationOptions.length) {
            console.debug('setPreferredDuration 3');
            setPreferredDuration(durationOptions[0].value);
        }
    }

    useEffect(() => {
        const currentPathQualifies = durationFilteredScores.find((x) => x.id == wavepath?.pathId);

        if (!currentPathQualifies) {
            console.debug('Current path doesnt qualify for criteria', { wavepath });
            if (!durationFilteredScores.length) {
                //MK20240217 accomodating templates that have broken the rules of pathscore durations - e.g. shorter than minimal
                if (preDurationFilteredScores.length) {
                    const pathScore = sample(preDurationFilteredScores);
                    if (pathScore === undefined) throw new Error('No pathScore');
                    const possibleDuration = durationPossibleFromScores.find((x) => x !== 'Auto');
                    if (possibleDuration === undefined) throw new Error('No possibleDuration');
                    console.debug('Resetting duration as no suitable possible durations', {
                        duration,
                        preferredDuration,
                        possibleDuration,
                        durationPossibleFromScores,
                        pathScore,
                    });
                    onUpdateWave({
                        ...wavepath,
                        duration: possibleDuration,
                        preferredDuration,
                        pathScore,
                        pathId: pathScore.id,
                    });
                } else {
                    console.debug('no durationFilteredScores to choose from', { duration, preDurationFilteredScores });
                    throw new Error('no durationFilteredScores to choose from');
                }
            } else {
                const pathScore = sample(durationFilteredScores);
                if (pathScore === undefined) throw new Error('No pathScore');
                onUpdateWave({
                    ...wavepath,
                    duration,
                    preferredDuration,
                    pathScore,
                    pathId: pathScore.id,
                });
            }
        } else if (duration !== wavepath.duration) {
            console.debug('Duration changed to ', duration, 'from', wavepath.duration);
            onUpdateWave({
                ...wavepath,
                duration,
                preferredDuration,
            });
        }
    }, [...dependencies, duration, preDurationFilteredScores.map((x) => x.id).join(',')]);

    return { durationOptions, preferredDuration, setPreferredDuration };
};

export default WavePathSelectorLegacy;
