import EventEmitter from 'events';
import firebase from 'firebase/app';
import io from 'socket.io-client';
import {
    BroadcastPersistentState,
    InboundSessionEvent,
    RequestType,
    Session,
    SessionRenderType,
    SessionRequest,
    SessionRequestProcessingResult,
    SessionRequestProperties,
    SessionState,
    SessionTick,
    TimestampedSessionEvent,
} from 'wavepaths-shared/core';

import * as api from '../common/api/sessionApi';
import { InboundEventManager } from './InboundEvents/InboundEventManager';
import {
    AllLatestEvents,
    NewEventEmittedEvent,
    SessionEventsReplayedEvent,
} from './InboundEvents/InboundEventManager.types';
import { registerCypressTestingHook } from './registerCypressTestingHook';

export class FreudConnection extends EventEmitter {
    private ioConnection: SocketIOClient.Socket | undefined;

    private requestQueue: SessionRequest[] = [];
    private closed = false;
    private inboundEventManager: InboundEventManager = new InboundEventManager();

    constructor(
        private session: Session,
        private fbUser?: firebase.User,
        private anonymousToken?: string,
        private mode: 'control' | 'listen' = 'control',
        private tickStressTestMultiply?: number,
    ) {
        super();
        this.setMaxListeners(100);
        this.listenToEvents();
        this.connectToMetadataStream();

        registerCypressTestingHook(this);
    }

    get sessionId() {
        return this.session.id;
    }

    private listenToEvents() {
        this.inboundEventManager.on(NewEventEmittedEvent, (event: TimestampedSessionEvent) => {
            this.emit(event.event, event);
        });
        this.inboundEventManager.on(SessionEventsReplayedEvent, () => {
            this.emit(SessionEventsReplayedEvent);
        });
        this.inboundEventManager.on(AllLatestEvents, (events: TimestampedSessionEvent[]) => {
            this.emit(AllLatestEvents, events);
        });
    }

    private async connectToMetadataStream() {
        if (this.session.renderType == SessionRenderType.PRE_RENDERED) {
            //TODO: support remote controls for Precomposed
            console.debug('Mocking connection as its precomposed');
            const fakeTick: SessionTick = {
                sessionState: SessionState.MAIN_PHASE,
                timeUntilStart: 0,
                absoluteTime: Date.now(),
                effectiveTime: 0,
                wallClockTime: Date.now(),
                preludeDuration: 0,
                sessionDuration: 0,
                timeSinceInit: 0,
                postludeDuration: 0,
                presetVolume: 1,
                contentStage: 0,
                connectedUserCount: 0,
                musicalContent: {
                    activeLayers: [],
                },
            };
            this.emit('tick', fakeTick);
            this.inboundEventManager.handleEventReplay([]);
            return;
        }

        this.ioConnection = io(`${api.FREUD_BASE_URL}/broadcastMetadata/${this.session.broadcastIdentifier}`, {
            transports: ['websocket'],
            reconnectionDelay: 2000,
            reconnectionDelayMax: 10000,
            randomizationFactor: 1,
            reconnectionAttempts: 1000,
        });

        // On initial connection, as well as reconnections
        this.ioConnection.on('connect', async () => {
            if (!this.ioConnection) throw new Error('no ioConnection');

            if (this.mode == 'control' && this.fbUser) {
                // Rejoin as control user with a fresh id token
                this.ioConnection.emit('joinControl', { idToken: await this.fbUser.getIdToken() });
            } else if (this.mode == 'listen') {
                if (this.fbUser) {
                    this.ioConnection.emit('setUser', {
                        type: 'firebase',
                        firebaseUserId: this.fbUser.uid,
                        name: this.fbUser.displayName,
                        email: this.fbUser.email,
                    });
                } else if (this.anonymousToken) {
                    this.ioConnection.emit('setUser', { type: 'anonymous', anonymousToken: this.anonymousToken });
                } else {
                    throw new Error('missing token');
                }

                //TODO - see if this is needed and populate parts of it - as access is denied for non-control users on the backend
                // for now just empty backfill to unblock the rendering and playing
                this.inboundEventManager.handleEventReplay([]);
            }
        });

        // When successfully joined as control user, make sure we have the full log, and drain any control events we may have pending
        this.ioConnection.on('joinedControl', () => {
            if (!this.ioConnection) throw new Error('no ioConnection');
            this.ioConnection.emit('requestSessionEventReplay', { from: 0 });
            while (this.requestQueue.length > 0) {
                this.ioConnection.emit('controlRequest', this.requestQueue.shift());
            }
        });

        // On control join failure, force refresh our access token and join again
        this.ioConnection.on('failedToJoinControl', async () => {
            if (!this.ioConnection) throw new Error('no ioConnection');
            if (!this.fbUser) {
                throw new Error('no fbUser');
            }
            this.ioConnection.emit('joinControl', { idToken: await this.fbUser.getIdToken(true) });
        });

        this.ioConnection.on('sessionEventReplay', (events: InboundSessionEvent[]) => {
            this.inboundEventManager.handleEventReplay(events);
        });

        // let tickCount = 0;
        this.ioConnection.on('sessionEvent', (event: InboundSessionEvent) => {
            this.inboundEventManager.handleSessionEvent(event);
        });

        this.ioConnection.on('broadcastStateUpdate', (data: BroadcastPersistentState) => {
            console.debug('Emitting broadcastStateUpdate');
            this.emit('broadcastStateUpdate', data);
        });

        this.ioConnection.on('controlRequestResult', (data: SessionRequestProcessingResult) => {
            this.emit('controlRequestResult', data);
        });

        const lastTicks: SessionTick[] = [];

        this.ioConnection.on('tick', (tick: SessionTick) => {
            lastTicks.map((x, index) => setTimeout(() => this.emit('tick', x), index * 100));

            this.emit('tick', tick);
            if (this.tickStressTestMultiply !== undefined) {
                if (lastTicks.length > this.tickStressTestMultiply) {
                    lastTicks.shift();
                }
                lastTicks.push(tick);
            }
        });
        const connector = setInterval(() => {
            if (!this.ioConnection) throw new Error('no ioConnection');

            if (this.closed) {
                clearInterval(connector);
            } else if (!this.ioConnection.connected) {
                this.ioConnection.open();
            } else {
                clearInterval(connector);
            }
        }, 1000);
    }

    close() {
        if (this.closed) return;

        this.ioConnection && this.ioConnection.disconnect();
        this.emit('closed');
        this.closed = true;
    }

    isClosed() {
        return this.closed;
    }

    sendRequest(reqProps: SessionRequestProperties) {
        if (this.mode == 'listen' && reqProps.type === RequestType.StartSessionTimers && this.ioConnection?.connected) {
            this.ioConnection!.emit('broadcastUserAdvanceFromPrelude');
            return;
        }

        if (
            this.mode == 'listen' &&
            ![RequestType.Resume, RequestType.Pause, RequestType.Seek].includes(reqProps.type)
        ) {
            throw new Error('not supported in listen mode');
        }
        const fbUserObjectForRequest =
            this.fbUser !== undefined
                ? {
                      type: 'firebase' as const,
                      firebaseUserId: this.fbUser.uid,
                      email: this.fbUser.email ?? '',
                      name: this.fbUser.displayName ?? '',
                  }
                : undefined;
        const req: SessionRequest = {
            ...reqProps,
            fbUser: fbUserObjectForRequest,
        };
        if (this.ioConnection?.connected) {
            this.ioConnection!.emit('controlRequest', req);
        } else {
            this.requestQueue.push(req);
        }
    }
}
