import { useMemo } from 'react';
import useSWR from 'swr';

import { useAuthContext } from '../../auth';
import { getSupportPostsTags, SupportPostTagData } from '../../common/api/supportPostsApi';
import { UseSupportPostsProps } from './useSupportPosts';

export type SupportPostTag = {
    label: string;
    value: string;
};

export const useSupportPostsTags = (props?: UseSupportPostsProps) => {
    const { includeArchived } = props || {};
    const { firebaseUser } = useAuthContext();
    const { data, isValidating, mutate } = useSWR(
        ['support_posts_tags', includeArchived],
        async ([, includeArchived]) => {
            if (!firebaseUser) {
                return [];
            }
            return getSupportPostsTags(firebaseUser, {
                includeArchived,
            });
        },
        {
            compare: (a, b) => JSON.stringify(a) === JSON.stringify(b),
            revalidateOnFocus: false,
        },
    );

    const refresh = async () => {
        await mutate();
    };

    const supportPostTags: SupportPostTag[] = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map(mapSupportPostTagToModel);
    }, [data]);

    return { supportPostTags, isLoading: isValidating, tagRefresh: refresh };
};

const mapSupportPostTagToModel = (article: SupportPostTagData): SupportPostTag => ({
    label: article.tag ?? 'No tag',
    value: article.tag,
});
