import styled from '@emotion/styled';
import React from 'react';

import DashboardHeader from './DashboardHeader';
import DashboardNavigation from './DashboardNavigation';

export interface DashboardLayoutProps {
    currentRoute: string;
    children: React.ReactChild[] | React.ReactChild;
    showSavedTemplates: boolean;
    showTemplatePrograms: boolean;
    userName?: string;
    onSignOutClick: () => void;
    useFullPageNavigation?: boolean;
}

const Container = styled.div`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: var(--global-spacing);

    padding: var(--global-spacing) 16px;
    margin-bottom: 56px; // Mobile nav height

    @media (min-width: 490px) {
        margin-bottom: 0;
        padding: var(--global-spacing) 16px;
    }
    @media (min-width: 768px) {
        padding: var(--global-spacing) 24px;
    }
    @media (min-width: 1040px) {
        padding: var(--global-spacing) 32px;
    }
`;

const Header = styled.div`
    // display: none;

    @media (min-width: 490px) {
        display: block;
        width: 100%;
    }
`;

const Main = styled.div`
    width: 100%;
    display: flex;
    gap: var(--global-spacing);
`;

const Sidebar = styled.aside`
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    width: 100%;

    @media (min-width: 490px) {
        position: relative;
        height: 100%;
        width: auto;
        position: sticky;
        top: var(--global-spacing);
    }
`;

const ContentArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Content = styled.main`
    position: relative;
    width: 100%;
    max-width: 1024px;
`;

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
    showSavedTemplates,
    currentRoute,
    children,
    showTemplatePrograms,
    userName,
    useFullPageNavigation,
    onSignOutClick,
}) => {
    return (
        <Container>
            <Header>
                <DashboardHeader userName={userName} onSignOutClick={onSignOutClick} />
            </Header>
            <Main>
                <Sidebar>
                    <DashboardNavigation
                        showSavedTemplates={showSavedTemplates}
                        showTemplatePrograms={showTemplatePrograms}
                        currentRoute={currentRoute}
                        useFullPageNavigation={useFullPageNavigation ?? false}
                    />
                </Sidebar>
                <ContentArea>
                    <Content role="region" aria-label="Main Content">
                        {children}
                    </Content>
                </ContentArea>
            </Main>
        </Container>
    );
};

export default React.memo(DashboardLayout);
