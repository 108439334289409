import React, { useState } from 'react';
import {
    BaseScheduledGenerativePathScore,
    isScheduledGenerativeDeepenPathScore,
    PathScore,
    ScheduledExperimentalPathScore,
    ScheduledGenerativeDeepenPathScore,
    ScheduledWavepath,
    SessionScoreEmotionalIntensity,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';
import Container from '@/component-library/components/Control/Container';
import Heading from '@/component-library/components/Control/Heading';

import { WaveEditor, WavePreviewMode } from '../WaveEditor/WaveEditor';
import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';
import { usePathScoreDurationSelector } from './usePathScoreDurationSelector';
import { Instrumentation, InstrumentId, useWaveInstruments } from './useWaveInstruments';

export interface SelectorState {
    intensity?: SessionScoreEmotionalIntensity;
    duration: number | string;
}

const selectorStateDefault: SelectorState = {
    intensity: SessionScoreEmotionalIntensity.LOW,
    duration: pathScoreFns.AUTO_VALUE,
};

export type DeepenPathScore =
    | BaseScheduledGenerativePathScore
    | ScheduledGenerativeDeepenPathScore
    | ScheduledExperimentalPathScore;

interface PercDeepenWaveSelectorProps {
    pathScores: DeepenPathScore[];
    wavepath: ScheduledWavepath;
    previewMode: WavePreviewMode;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isDeepenPathScore = (p: PathScore): p is DeepenPathScore => p.direction === TherapeuticDirection.DEEPEN;
export const isPercussivePathScore = (pathScore: DeepenPathScore) => pathScore.mode === 'Percussive';

function PercDeepenWaveSelector({
    pathScores,
    wavepath,
    previewMode,
    onUpdateWave,
}: PercDeepenWaveSelectorProps): JSX.Element {
    const filteredPathScores = pathScores.filter(isScheduledGenerativeDeepenPathScore);

    const emotion = 'None';
    const emotionFilteredScores = pathScoreFns.filterScoresByEmotion(filteredPathScores, emotion);

    const defaultIntensity = wavepath.pathScore.selectionCriteria?.emotionalIntensity ?? selectorStateDefault.intensity;
    const [intensity, setIntensity] = useState(defaultIntensity);
    const intensityFilteredScores = pathScoreFns.filterScoresByIntensity(emotionFilteredScores, intensity);

    if (intensityFilteredScores.length == 0 && emotionFilteredScores[0]) {
        setIntensity(emotionFilteredScores[0].selectionCriteria?.emotionalIntensity);
    }

    const { waveInstrumentations, applyInstrumentation } = useWaveInstruments(wavepath);

    const onUpdateInstrumentation = (instrumentId: InstrumentId, instrumentation: Instrumentation) => {
        const updatedWavepath = applyInstrumentation(instrumentId, instrumentation);
        onUpdateWave(updatedWavepath);
    };

    const { durationOptions, preferredDuration, setPreferredDuration } = usePathScoreDurationSelector({
        wavepath,
        onUpdateWave,
        preDurationFilteredScores: intensityFilteredScores,
        dependencies: [intensity, emotion],
    });

    return (
        <>
            <SegmentedControl
                name="wave-intensity"
                heading={'Intensity'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        SessionScoreEmotionalIntensity.LOW,
                        SessionScoreEmotionalIntensity.MEDIUM,
                        SessionScoreEmotionalIntensity.HIGH,
                        SessionScoreEmotionalIntensity.ALL,
                    ],
                    pathScoreFns.extractIntensitiesFromScores(filteredPathScores),
                )}
                value={intensity}
                onChange={setIntensity}
                info={infoCopy.intensity}
            />
            <Container inline style={{ gridTemplateColumns: '104px 440px' }}>
                <Heading
                    id="wave-music"
                    heading={'Music'}
                    info={infoCopy.composition}
                    canSave={false}
                    colour="dark"
                    inline={false}
                >
                    Music
                </Heading>
                <WaveEditor
                    wavepath={wavepath}
                    waveInstrumentations={waveInstrumentations}
                    previewMode={previewMode}
                    onUpdateInstrumentation={onUpdateInstrumentation}
                />
            </Container>
            <DropdownControl
                name="wave-duration"
                heading={'Duration'}
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={preferredDuration}
                onChange={setPreferredDuration}
                info={infoCopy.duration}
            />
        </>
    );
}

export default PercDeepenWaveSelector;
