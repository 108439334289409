import { difference, isEqual, isObject, sortBy, uniq, uniqBy } from 'lodash';
import {
    ContentSwitch,
    isScheduledPathScore,
    LayerNumber,
    ScheduledPathScore,
    ScheduledWavepath,
    TherapeuticDirection,
} from 'wavepaths-shared/core';
import { selectStagePresetMix } from 'wavepaths-shared/scoreLibrary';
import { LayerInformation } from 'wavepaths-shared/types/content/core';

import useScoreLibrary, { ScoreLibrary } from '@/hooks/useScoreLibrary';

import { useAuthContext } from '../../../auth';
import * as pathScoreFns from './pathScoreFns';
import { useAvailableLayers } from './useAvailableLayers';

type InstrumentLayerGroup = {
    id: InstrumentId;
    layerNumbers: LayerNumber[];
};

export type InstrumentationUndetermined = {
    type: 'undetermined';
};

export type InstrumentationDeterminedWithLayers = {
    type: 'determinedWithLayers';
    layers: LayerInformation[];
};

export type InstrumentationDeterminedWithPathScore = {
    type: 'determinedWithPathScore';
    layers: LayerInformation[];
    pathScore: ScheduledPathScore;
};

export type InstrumentationDeterminedWithNoContent = {
    type: 'determinedWithNoContent';
};

export type InstrumentationDetermined =
    | InstrumentationDeterminedWithLayers
    | InstrumentationDeterminedWithPathScore
    | InstrumentationDeterminedWithNoContent;

export type Instrumentation = InstrumentationUndetermined | InstrumentationDetermined;

export type WaveInstrumentation = {
    group: InstrumentLayerGroup;
    instrumentation: Instrumentation;
};

export type WaveInstrumentations = WaveInstrumentation[];

export type InstrumentId =
    | 'bass'
    | 'lowChords'
    | 'lowMelody'
    | 'highChords'
    | 'highMelody'
    | 'emotiveChords'
    | 'emotiveMelody'
    | 'deepen'
    | 'track';
const LAYER_INSTRUMENT_GROUPS: InstrumentLayerGroup[] = [
    { id: 'track', layerNumbers: [13] },
    { id: 'deepen', layerNumbers: [8, 9, 10, 16] },
    { id: 'emotiveMelody', layerNumbers: [11] },
    { id: 'emotiveChords', layerNumbers: [12] },
    { id: 'highMelody', layerNumbers: [3] },
    { id: 'highChords', layerNumbers: [5] },
    { id: 'lowMelody', layerNumbers: [2] },
    { id: 'lowChords', layerNumbers: [4] },
    { id: 'bass', layerNumbers: [1] },
] as const;

const UNDETERMINED_INSTRUMENTATION: InstrumentationUndetermined = {
    type: 'undetermined',
};

const NO_CONTENT_INSTRUMENTATION: InstrumentationDeterminedWithNoContent = {
    type: 'determinedWithNoContent',
};

export function useWaveInstruments(wavepath: ScheduledWavepath) {
    const { firebaseUser } = useAuthContext();
    const scoreLibrary = useScoreLibrary(firebaseUser);
    const emotion = wavepath.pathScore.emotion;
    const ceas = emotion ? (isObject(emotion) ? [emotion.from, emotion.to] : [emotion]) : [];
    const availableLayers = useAvailableLayers({ ceas, fbUser: firebaseUser });
    const activeInstrumentGroups = getActiveInstrumentGroups(wavepath, scoreLibrary);
    const pinnedLayers = getPinnedLayers(wavepath.pathScore, availableLayers.layers);
    const noContentLayerNumbers = getNoContentLayerNumbers(wavepath.pathScore);
    const waveInstrumentations = buildWaveInstrumentations(activeInstrumentGroups, pinnedLayers, noContentLayerNumbers);

    const applyInstrumentation = (instrumentId: InstrumentId, instrumentation: Instrumentation): ScheduledWavepath => {
        const previousInstrumentation = waveInstrumentations.find((i) => i.group.id === instrumentId);
        if (instrumentation.type === 'determinedWithPathScore') {
            return {
                ...wavepath,
                pathScore: instrumentation.pathScore,
                pathId: instrumentation.pathScore.id,
            };
        } else if (instrumentation.type === 'determinedWithNoContent') {
            let isFirstContentSwitch = true;
            const layerNumbersToMute =
                LAYER_INSTRUMENT_GROUPS.find((group) => group.id === instrumentId)?.layerNumbers ?? [];
            const layersToUnpin =
                previousInstrumentation?.instrumentation.type === 'determinedWithLayers'
                    ? previousInstrumentation.instrumentation.layers
                    : [];
            const layerIdsToUnpin = layersToUnpin.map((l) => l.id);
            const collectionNamesToUnpin = layersToUnpin.map((l) => l.collection.name);

            const modifyContentSwitch = (contentSwitch: ContentSwitch | undefined) => {
                if (contentSwitch) {
                    contentSwitch = {
                        ...contentSwitch,
                        layerIds: difference(contentSwitch.layerIds, layerIdsToUnpin),
                        collectionNames: difference(contentSwitch.collectionNames, collectionNamesToUnpin),
                    };
                    if (isFirstContentSwitch) {
                        contentSwitch = {
                            ...contentSwitch,
                            noContentLayerNumbers: [
                                ...(contentSwitch.noContentLayerNumbers ?? []),
                                ...layerNumbersToMute,
                            ],
                        };
                        isFirstContentSwitch = false;
                    }
                }
                return contentSwitch;
            };

            return {
                ...wavepath,
                pathScore: {
                    ...wavepath.pathScore,
                    stages: wavepath.pathScore.stages.map((stage) => ({
                        ...stage,
                        contentSwitch: modifyContentSwitch(stage.contentSwitch),
                    })),
                },
            };
        } else {
            const layersToPin = instrumentation.type === 'determinedWithLayers' ? instrumentation.layers : [];
            const layerIdsToPin = layersToPin.map((l) => l.id);
            const layersToUnpin =
                previousInstrumentation?.instrumentation.type === 'determinedWithLayers'
                    ? previousInstrumentation.instrumentation.layers
                    : [];
            const layerIdsToUnpin = layersToUnpin.map((l) => l.id);
            const collectionNamesToUnpin = layersToUnpin.map((l) => l.collection.name);
            const layerNumbersToUnmute = layersToPin.map((l) => l.layerNumber);

            let isFirstContentSwitch = true;
            const modifyContentSwitch = (contentSwitch: ContentSwitch | undefined) => {
                if (contentSwitch) {
                    contentSwitch = {
                        ...contentSwitch,
                        layerIds: difference(contentSwitch.layerIds, layerIdsToUnpin),
                        collectionNames: difference(contentSwitch.collectionNames, collectionNamesToUnpin),
                        noContentLayerNumbers: difference(contentSwitch.noContentLayerNumbers, layerNumbersToUnmute),
                    };
                    if (isFirstContentSwitch) {
                        contentSwitch = {
                            ...contentSwitch,
                            layerIds: [...(contentSwitch?.layerIds ?? []), ...layerIdsToPin],
                        };
                        isFirstContentSwitch = false;
                    }
                }
                return contentSwitch;
            };

            return {
                ...wavepath,
                pathScore: {
                    ...wavepath.pathScore,
                    stages: wavepath.pathScore.stages.map((stage) => ({
                        ...stage,
                        contentSwitch: modifyContentSwitch(stage.contentSwitch),
                    })),
                },
            };
        }
    };

    return { waveInstrumentations, applyInstrumentation };
}

export function useAvailableInstruments(wavepath: ScheduledWavepath, instrumentId: InstrumentId): Instrumentation[] {
    const { firebaseUser } = useAuthContext();
    const scoreLibrary = useScoreLibrary(firebaseUser);
    const ceas = wavepath.pathScore.emotion
        ? isObject(wavepath.pathScore.emotion)
            ? [wavepath.pathScore.emotion.from, wavepath.pathScore.emotion.to]
            : [wavepath.pathScore.emotion]
        : [];
    const availableLayers = useAvailableLayers({ ceas, fbUser: firebaseUser });

    const instrumentLayerGroup = LAYER_INSTRUMENT_GROUPS.find((group) => group.id === instrumentId);
    if (!instrumentLayerGroup) {
        throw new Error(`Instrument ${instrumentId} not found`);
    }

    if (wavepath.pathScore.direction === TherapeuticDirection.SOOTHE && wavepath.pathScore.mode === 'Structured') {
        if (scoreLibrary.loading) return [];
        const compatibleStructuredSootheScores = scoreLibrary.pathScores
            .filter(isScheduledPathScore)
            .filter((s) => s.direction === TherapeuticDirection.SOOTHE && s.mode === 'Structured')
            .filter((s) => s.emotion === wavepath.pathScore.emotion);
        const durationCompatibleScores = pathScoreFns.filterScoresByDuration(
            compatibleStructuredSootheScores,
            wavepath.duration,
        );
        return [
            UNDETERMINED_INSTRUMENTATION,
            ...sortInstrumentations(
                durationCompatibleScores.map((pathScore) => ({
                    type: 'determinedWithPathScore',
                    layers: getPinnedLayers(pathScore, availableLayers.layers),
                    pathScore,
                })),
            ),
        ];
    } else if (wavepath.pathScore.direction === TherapeuticDirection.BRIDGE) {
        if (scoreLibrary.loading) return [];
        const compatibleBridgeScores = scoreLibrary.pathScores
            .filter(isScheduledPathScore)
            .filter((s) => s.direction === TherapeuticDirection.BRIDGE)
            .filter((s) => isEqual(s.emotion, wavepath.pathScore.emotion));
        return [
            UNDETERMINED_INSTRUMENTATION,
            ...sortInstrumentations(
                compatibleBridgeScores.map((pathScore) => ({
                    type: 'determinedWithPathScore',
                    layers: getPinnedLayers(pathScore, availableLayers.layers),
                    pathScore,
                })),
            ),
        ];
    } else if (
        wavepath.pathScore.direction === TherapeuticDirection.DEEPEN &&
        wavepath.pathScore.mode === 'Percussive' &&
        instrumentLayerGroup.id === 'deepen'
    ) {
        if (scoreLibrary.loading) return [];
        const compatiblePercussiveScores = scoreLibrary.pathScores
            .filter(isScheduledPathScore)
            .filter((s) => s.direction === TherapeuticDirection.DEEPEN && s.mode === 'Percussive')
            .filter((s) => !s.emotion);
        const allCompat = compatiblePercussiveScores.map((pathScore) => ({
            type: 'determinedWithLayers' as const,
            layers: getPinnedLayers(pathScore, availableLayers.layers),
        }));
        return [UNDETERMINED_INSTRUMENTATION, ...sortInstrumentations(uniqBy(allCompat, JSON.stringify))];
    } else {
        const layerNumberFilteredLayers = availableLayers.layers.filter((layer) =>
            instrumentLayerGroup.layerNumbers.includes(layer.layerNumber as LayerNumber),
        );
        const groupedFilteredLayers = groupLayersByInclusionCriterion(layerNumberFilteredLayers);
        return [
            UNDETERMINED_INSTRUMENTATION,
            NO_CONTENT_INSTRUMENTATION,
            ...sortInstrumentations(layersToInstrumentations(groupedFilteredLayers, instrumentLayerGroup)),
        ];
    }
}

function sortInstrumentations<I extends Instrumentation>(instrumentations: I[]) {
    return sortBy(instrumentations, getInstrumentCategory, getInstrumentName, getComposerName);
}

function groupLayersByInclusionCriterion(layers: LayerInformation[]) {
    const INCLUSIVE_CRITERION_TAG_NAME = 'Marker';
    const INCLUSIVE_CRITERION_TAG_VALUE_PREFIX = 'inclusive-';
    const inclusionGroups = new Map<string, LayerInformation[]>();
    for (const layer of layers) {
        const markerTag = layer.tags.find((t) => t.tag === INCLUSIVE_CRITERION_TAG_NAME);
        if (markerTag?.type === 'string' && markerTag.value.startsWith(INCLUSIVE_CRITERION_TAG_VALUE_PREFIX)) {
            if (!inclusionGroups.has(markerTag.value)) {
                inclusionGroups.set(markerTag.value, []);
            }
            inclusionGroups.get(markerTag.value)?.push(layer);
        } else {
            inclusionGroups.set(`single-${layer.id}`, [layer]);
        }
    }
    return Array.from(inclusionGroups.values());
}

function layersToInstrumentations(groups: LayerInformation[][], instrumentLayerGroup: InstrumentLayerGroup) {
    const result: InstrumentationDetermined[] = [];
    for (const layerGroup of groups) {
        const layerNumbersInGroup = new Set(layerGroup.map((layer) => layer.layerNumber));
        const hasAllRequiredLayers = instrumentLayerGroup.layerNumbers.every((layerNumber) =>
            layerNumbersInGroup.has(layerNumber as LayerNumber),
        );
        if (hasAllRequiredLayers) {
            result.push({
                type: 'determinedWithLayers' as const,
                layers: layerGroup,
            });
        } else {
            const missingLayerNumbers = instrumentLayerGroup.layerNumbers.filter(
                (layerNumber) => !layerNumbersInGroup.has(layerNumber as LayerNumber),
            );
            console.warn(
                `Layer group missing required layer numbers: ${missingLayerNumbers.join(', ')} for group`,
                layerGroup,
                `on instrument ${instrumentLayerGroup.id}`,
            );
        }
    }
    return result;
}

function getActiveInstrumentGroups(wavepath: ScheduledWavepath, scoreLibrary: ScoreLibrary) {
    if (scoreLibrary.loading) {
        return [];
    }
    const activeLayers = new Set<LayerNumber>();
    for (const stage of wavepath.pathScore.stages) {
        const preset = selectStagePresetMix(stage.preset, stage.stage, scoreLibrary.presetScores, []);
        for (const layerSettings of preset.stage.settings.layers) {
            if (layerSettings.vol > 0) {
                activeLayers.add(layerSettings.layer);
            }
        }
    }
    const activeInstrumentGroups = LAYER_INSTRUMENT_GROUPS.filter((group) =>
        group.layerNumbers.some((layer) => activeLayers.has(layer)),
    );
    return activeInstrumentGroups;
}

function getPinnedLayers(pathScore: ScheduledPathScore, availableLayers: LayerInformation[]) {
    const layers = new Set<LayerInformation>();
    for (const stage of pathScore.stages) {
        if (stage.contentSwitch) {
            for (const layerId of stage.contentSwitch.layerIds ?? []) {
                const layer = availableLayers.find((l) => l.id === layerId);
                if (layer) {
                    layers.add(layer);
                } else {
                    console.warn(`Pinned layer ${layerId} not found in available layers`);
                }
            }
            for (const collectionName of stage.contentSwitch.collectionNames ?? []) {
                const collectionLayers = availableLayers.filter((l) => l.collection.name === collectionName);
                if (collectionLayers.length > 0) {
                    for (const layer of collectionLayers) {
                        layers.add(layer);
                    }
                } else {
                    console.warn(`Layers for pinned collection ${collectionName} not found in available layers`);
                }
            }
        }
    }
    return Array.from(layers);
}

function getNoContentLayerNumbers(pathScore: ScheduledPathScore) {
    const noContentLayerNumbers = new Set<LayerNumber>();
    for (const stage of pathScore.stages) {
        if (stage.contentSwitch) {
            const nums = stage.contentSwitch.noContentLayerNumbers ?? [];
            for (const num of nums) {
                noContentLayerNumbers.add(num);
            }
        }
    }
    return Array.from(noContentLayerNumbers);
}

function buildWaveInstrumentations(
    activeInstrumentGroups: InstrumentLayerGroup[],
    pinnedLayers: LayerInformation[],
    noContentLayerNumbers: LayerNumber[],
) {
    const waveInstrumentations: WaveInstrumentations = [];
    for (const instrumentGroup of activeInstrumentGroups) {
        const groupPinnedLayers = pinnedLayers.filter((l) => instrumentGroup.layerNumbers.includes(l.layerNumber));
        const groupNoContentLayerNumbers = noContentLayerNumbers.filter((l) =>
            instrumentGroup.layerNumbers.includes(l),
        );
        if (groupPinnedLayers.length > 0) {
            waveInstrumentations.push({
                group: instrumentGroup,
                instrumentation: {
                    type: 'determinedWithLayers' as const,
                    layers: groupPinnedLayers,
                },
            });
        } else if (groupNoContentLayerNumbers.length > 0) {
            waveInstrumentations.push({
                group: instrumentGroup,
                instrumentation: NO_CONTENT_INSTRUMENTATION,
            });
        } else {
            waveInstrumentations.push({
                group: instrumentGroup,
                instrumentation: { type: 'undetermined' },
            });
        }
    }
    return waveInstrumentations;
}

export const getInstrumentCategory = (instrument: Instrumentation): string => {
    if (instrument.type === 'undetermined') {
        return 'Wavepaths Choice';
    } else if (instrument.type === 'determinedWithNoContent') {
        return 'Silence';
    } else {
        const cats = instrument.layers
            .flatMap((i) => i.tags.filter((t) => t.tag === 'Instrument Category').map((t) => t.value))
            .filter((c) => !!c)
            .map(String);

        if (cats.length > 0) {
            return cats[0];
        } else {
            return 'Unknown';
        }
    }
};

export const getInstrumentInstrument = (instrument: Instrumentation): string => {
    if (instrument.type === 'undetermined') {
        return '';
    } else if (instrument.type === 'determinedWithNoContent') {
        return '';
    } else {
        const instrs = uniq(
            instrument.layers
                .flatMap((i) => i.tags.filter((t) => t.tag === 'Instruments').map((t) => t.value))
                .filter((i) => !!i)
                .map(String),
        );

        if (instrs.length > 0) {
            return instrs.join(', ');
        } else {
            return '';
        }
    }
};

export const getInstrumentName = (instrument: Instrumentation): string => {
    const category = getInstrumentCategory(instrument);
    const instruments = getInstrumentInstrument(instrument);

    if (category !== 'Unknown' && instruments) {
        return `${category} (${instruments})`;
    } else if (category !== 'Unknown') {
        return category;
    } else if (instruments) {
        return instruments;
    } else {
        return 'Unknown';
    }
};

export const getInstrumentTags = (instrument: Instrumentation): string[] => {
    if (instrument.type === 'undetermined') {
        return [];
    } else if (instrument.type === 'determinedWithNoContent') {
        return [];
    } else {
        const tags = uniq(
            instrument.layers
                .flatMap((i) =>
                    i.tags
                        .filter(
                            (t) =>
                                t.tag === 'Instrument Type' ||
                                t.tag === 'Instrument Character' ||
                                t.tag === 'Instrument Culture',
                        )
                        .map((t) => t.value),
                )
                .filter((t) => !!t)
                .map(String),
        );

        return tags;
    }
};

export const getComposerName = (instrument: Instrumentation): string => {
    if (instrument.type === 'undetermined') {
        return '';
    } else if (instrument.type === 'determinedWithNoContent') {
        return '';
    } else {
        const composers = uniqBy(
            instrument.layers.map((i) => i.composer),
            'id',
        ).map((c) => c.name);
        return composers.join(', ');
    }
};
