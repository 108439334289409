import React, { useState } from 'react';
import {
    Acousticness,
    CoreEmotionalAtmosphere,
    PathScore,
    ScheduledExperimentalPathScore,
    ScheduledGenerativePathScore,
    ScheduledWavepath,
    SootheModes,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';
import Container from '@/component-library/components/Control/Container';
import Heading from '@/component-library/components/Control/Heading';

import { WaveEditor, WavePreviewMode } from '../WaveEditor/WaveEditor';
import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';
import { usePathScoreDurationSelector } from './usePathScoreDurationSelector';
import { Instrumentation, InstrumentId, useWaveInstruments } from './useWaveInstruments';

interface SelectorState {
    emotion?: CoreEmotionalAtmosphere;
    intensity?: string;
    tone?: Acousticness;
    ambience?: string;
    music?: string;
    duration: number | string;
}

const selectorStateDefault: SelectorState = {
    emotion: CoreEmotionalAtmosphere.STILLNESS,
    intensity: 'Low',
    tone: 'Mixed',
    ambience: 'None',
    music: 'Any',
    duration: pathScoreFns.AUTO_VALUE,
};

export type SoothePathScore = (ScheduledGenerativePathScore | ScheduledExperimentalPathScore) & {
    therapeuticDirection: TherapeuticDirection.SOOTHE;
    mode: SootheModes;
};

interface SootheUnstructuredWaveSelectorProps {
    pathScores: SoothePathScore[];
    wavepath: ScheduledWavepath;
    previewMode: WavePreviewMode;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
}

export const isSoothePathScore = (p: PathScore): p is SoothePathScore => p.direction === TherapeuticDirection.SOOTHE;

function SootheUnstructuredWaveSelector({
    pathScores,
    wavepath,
    previewMode,
    onUpdateWave,
}: SootheUnstructuredWaveSelectorProps): JSX.Element {
    const [emotion, setEmotion] = useState(
        typeof wavepath.pathScore.emotion == 'string' ? wavepath.pathScore.emotion : selectorStateDefault.emotion,
    );
    const emotionFilteredScores = pathScoreFns.filterScoresByEmotion(pathScores, emotion);

    const modeFilteredScores = pathScoreFns.filterScoresByMode(emotionFilteredScores, 'Unstructured');

    const defaultIntensity = wavepath.pathScore.selectionCriteria?.emotionalIntensity ?? selectorStateDefault.intensity;
    const [intensity, setIntensity] = useState(defaultIntensity);
    const intensityFilteredScores = pathScoreFns.filterScoresByIntensity(modeFilteredScores, intensity);

    const defaultAmbience = wavepath.pathScore.selectionCriteria?.emotionalIntensity ?? selectorStateDefault.intensity;
    const [ambience, setAmbience] = useState(defaultAmbience);
    const ambienceFilteredScores = pathScoreFns.filterScoresByAmbience(intensityFilteredScores, ambience);

    const preDurationFilteredScores = ambienceFilteredScores;

    if (!emotionFilteredScores.length) {
        setEmotion(undefined);
    }

    if (!intensityFilteredScores.length && modeFilteredScores[0]) {
        setIntensity(modeFilteredScores[0].selectionCriteria?.emotionalIntensity);
    }

    if (!ambienceFilteredScores.length && intensityFilteredScores[0]) {
        //@ts-ignore TODO
        setAmbience(intensityFilteredScores[0].ambience || selectorStateDefault.ambience);
    }

    const { waveInstrumentations, applyInstrumentation } = useWaveInstruments(wavepath);

    const onUpdateInstrumentation = (instrumentId: InstrumentId, instrumentation: Instrumentation) => {
        const updatedWavepath = applyInstrumentation(instrumentId, instrumentation);
        onUpdateWave(updatedWavepath);
    };

    const { durationOptions, preferredDuration, setPreferredDuration } = usePathScoreDurationSelector({
        wavepath,
        onUpdateWave,
        preDurationFilteredScores,
        dependencies: [emotion, ambience, intensity],
    });

    return (
        <>
            <SegmentedControl
                name="wave-emotion"
                heading={'Emotionality'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    pathScoreFns.extractEmotionsFromScores(pathScores),
                )}
                value={emotion}
                onChange={setEmotion}
                info={infoCopy.emotion}
            />
            <SegmentedControl
                name="wave-intensity"
                heading={'Intensity'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Low', 'Medium'],
                    pathScoreFns.extractIntensitiesFromScores(modeFilteredScores),
                )}
                value={intensity}
                onChange={setIntensity}
                info={infoCopy.intensity}
            />

            <SegmentedControl
                name="wave-nature"
                heading={'Ambience'}
                canSave={false}
                size="small"
                colour="dark"
                options={[
                    {
                        label: 'None',
                        value: 'None',
                        disabled: false,
                    },
                    ...[...pathScoreFns.extractNatureSoundsFromScore(intensityFilteredScores)].map((item: string) => ({
                        label: item,
                        value: item,
                        disabled: false,
                    })),
                ]}
                value={ambience}
                onChange={setAmbience}
                info={infoCopy.ambience}
            />
            <Container inline style={{ gridTemplateColumns: '104px 440px' }}>
                <Heading
                    id="wave-music"
                    heading={'Music'}
                    info={infoCopy.composition}
                    canSave={false}
                    colour="dark"
                    inline={false}
                >
                    Music
                </Heading>
                <WaveEditor
                    wavepath={wavepath}
                    waveInstrumentations={waveInstrumentations}
                    previewMode={previewMode}
                    onUpdateInstrumentation={onUpdateInstrumentation}
                />
            </Container>

            <DropdownControl
                heading={'Duration'}
                name="wave-duration"
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={preferredDuration}
                onChange={setPreferredDuration}
                info={infoCopy.duration}
            />
        </>
    );
}

export default SootheUnstructuredWaveSelector;
