import styled from '@emotion/styled';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Wavepath } from 'wavepaths-shared/core';

import { Button, EvaIcon, Typography } from '@/component-library';
import { Features } from '@/features';

import { useAuthContext } from '../../../auth';
import { RemoteSessionControlsContext } from '../../../pages/inSession/Guide';
import { WavePreviewMode } from '../WaveEditor/WaveEditor';
import { WavePathSelector } from '../WavePathSelector/WavePathSelector';
import WavePathSelectorLegacy from '../WavePathSelectorLegacy';
import { SKIP_WAVE_LABEL } from './WaveCard';
import { WaveCardHeaderContentContainer } from './WaveCardHeaderContentContainer';

const ExpandedContentContainer = styled.div({
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

export const WaveCardDialog = ({
    isOpen,
    wave,
    waveIndex,
    previousWave,
    shouldAllowAudioPreviews,
    onUpdateWave: onUpdateWaveProp,
    onRemoveWave,
    onSkipToWave,
    onClose,
}: {
    isOpen: boolean;
    wave: Wavepath;
    waveIndex: number;
    previousWave?: Wavepath;
    shouldAllowAudioPreviews: boolean;
    onUpdateWave: (wave: Wavepath) => void;
    onRemoveWave: (e: any) => void;
    onSkipToWave?: (e: any) => void;
    onClose: () => void;
}) => {
    const remoteContext = useContext(RemoteSessionControlsContext);

    // Have the internal state of this dialog show the state of an updated wave
    // while there is an update queued
    const [updatingWave, setUpdatingWave] = useState<Wavepath | null>(null);
    const onUpdateWave = (wave: Wavepath) => {
        if (remoteContext) setUpdatingWave(wave);
        onUpdateWaveProp(wave);
    };
    const updateQueued =
        remoteContext && remoteContext.queuedFunction && remoteContext.queuedFunction.waveIndex === waveIndex;
    const effectiveWave = updateQueued && updatingWave ? updatingWave : wave;
    const wavePreviewMode = updateQueued
        ? WavePreviewMode.None
        : shouldAllowAudioPreviews
        ? WavePreviewMode.AudioVisual
        : WavePreviewMode.Visual;

    const { pathScore } = effectiveWave;
    const { name } = pathScore;

    const { isEnabled } = useAuthContext();
    const isInstrumentControlEnabled = isEnabled(Features.INSTRUMENT_CONTROL);

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Edit wave</DialogTitle>
            <DialogContent>
                <>
                    <WaveCardHeaderContentContainer
                        name={name}
                        wave={effectiveWave}
                        previousWave={previousWave}
                        isOpen={isOpen}
                        pathScore={pathScore}
                        onRemoveWave={onRemoveWave}
                    />
                    <ExpandedContentContainer>
                        {onSkipToWave ? (
                            <Button style={{ marginBottom: 16 }} size="s" onClick={onSkipToWave} variant="outlined">
                                <EvaIcon name="skip-forward" size={16} />
                                <Typography variant="body2">{SKIP_WAVE_LABEL}</Typography>
                            </Button>
                        ) : null}
                        {isInstrumentControlEnabled ? (
                            <WavePathSelector
                                wavepath={effectiveWave}
                                previewMode={wavePreviewMode}
                                onUpdateWave={onUpdateWave}
                            />
                        ) : (
                            <WavePathSelectorLegacy wavepath={wave} onUpdateWave={onUpdateWave} />
                        )}
                    </ExpandedContentContainer>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {remoteContext &&
                            remoteContext.queuedFunction &&
                            remoteContext.queuedFunction.waveIndex === waveIndex &&
                            remoteContext.queuedFunction.showQueuingUIInline && (
                                <>
                                    <Button
                                        variant="solid-blue"
                                        onClick={() => {
                                            remoteContext.onSkipQueue();
                                            onClose();
                                        }}
                                    >
                                        Apply now or autosave in ...{' '}
                                        {remoteContext.queuedFunction.timeUntilExecutionInSeconds}s
                                    </Button>
                                    <Button
                                        variant="clear-underlined"
                                        onClick={() => {
                                            remoteContext.onCancelQueuedFunction();
                                        }}
                                    >
                                        Cancel edit
                                    </Button>
                                </>
                            )}
                        <Button variant="clear-underlined" onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    );
};
