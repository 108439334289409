import firebase from 'firebase/app';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import { useAuthContext } from '../../auth';
import {
    archiveNewsArticle,
    createNewsArticle,
    getNews,
    getNewsLastArticle,
    NewsArticleData,
    unarchiveNewsArticle,
    updateNewsArticle,
    viewAllNewsArticle,
    viewNewsArticle,
} from '../../common/api/newsApi';

export type NewsArticle = {
    id: string;
    tag?: string;
    shortDescription?: string;
    description?: string;
    date?: Date;
    isArchived?: boolean;
    isViewed?: boolean;
};

// TODO: refactor it, move to separate file
export type UseAsyncMutation<P extends any[]> = (
    props?: UseAsyncMutationProps,
) => {
    isLoading: boolean;
    error: unknown | null;
    mutate: (...params: P) => Promise<void>;
};

export type UseAsyncMutationProps = {
    onSuccess?: () => void;
    onFailure?: (error: unknown) => void;
    onFinally?: () => void;
    onLoading?: () => void;
};

export const createUseAsyncMutation = <P extends any[], R>(
    fn: (firebaseUser: firebase.User, ...params: P) => Promise<R>,
): UseAsyncMutation<P> => (props) => {
    const { onLoading, onSuccess, onFailure, onFinally } = props || {};
    const { firebaseUser } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown | null>(null);

    const mutate = async (...params: P) => {
        if (!firebaseUser) {
            throw new Error('User not logged in');
        }
        onLoading?.();
        setIsLoading(true);
        setError(null);
        try {
            await fn(firebaseUser, ...params);
            onSuccess?.();
        } catch (e: any) {
            setError(e);
            onFailure?.(e);
        } finally {
            setIsLoading(false);
            onFinally?.();
        }
    };

    return { isLoading, error, mutate };
};

export type UseNewsProps = {
    includeArchived?: boolean;
};

export const useNews = (props?: UseNewsProps) => {
    const { includeArchived } = props || {};
    const { firebaseUser } = useAuthContext();
    const { data, isValidating, mutate } = useSWR(
        ['news', includeArchived],
        async ([, includeArchived]) => {
            if (!firebaseUser) {
                return [];
            }
            return getNews(firebaseUser, {
                includeArchived,
            });
        },
        {
            compare: (a, b) => JSON.stringify(a) === JSON.stringify(b),
            revalidateOnFocus: false,
        },
    );

    const refresh = async () => {
        await mutate();
    };

    const news: NewsArticle[] = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map(mapNewsArticleToModel);
    }, [data]);

    const updateNewsItem = (updatedArticle: NewsArticle) => {
        mutate((prevData: any) => {
            return prevData.map((article: any) =>
                article.id === updatedArticle.id ? { ...article, ...updatedArticle } : article,
            );
        }, false);
    };

    return { news, isLoading: isValidating, refresh, updateNewsItem };
};

export const useNewsCreateArticle = createUseAsyncMutation(createNewsArticle);
export const useNewsUpdateArticle = createUseAsyncMutation(updateNewsArticle);
export const useNewsArchiveArticle = createUseAsyncMutation(archiveNewsArticle);
export const useNewsUnarchiveArticle = createUseAsyncMutation(unarchiveNewsArticle);
export const useNewsViewArticle = createUseAsyncMutation(viewNewsArticle);
export const useNewsViewAllArticle = createUseAsyncMutation(viewAllNewsArticle);

export const useNewsArticleLatest = () => {
    const { firebaseUser } = useAuthContext();

    const { data, isValidating } = useSWR(
        'news-last',
        async () => {
            if (!firebaseUser) {
                return undefined;
            }
            return getNewsLastArticle(firebaseUser);
        },
        {
            compare: (a, b) => JSON.stringify(a) === JSON.stringify(b),
            revalidateOnFocus: false,
        },
    );

    const article = useMemo(() => {
        if (!data) {
            return null;
        }
        return mapNewsArticleToModel(data);
    }, [data]);

    return { article, isLoading: isValidating };
};

const mapNewsArticleToModel = (article: NewsArticleData): NewsArticle => ({
    id: article.id,
    tag: article.tag,
    shortDescription: article.shortDescription,
    description: article.description,
    date: article.publishedAt ? new Date(article.publishedAt) : undefined,
    isArchived: !!article.archivedAt,
    isViewed: article.isViewed,
});
