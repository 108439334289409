import styled from '@emotion/styled';
import { isObject } from 'lodash';
import React, { memo } from 'react';
import { CoreEmotionalAtmosphere, ScheduledWavepath } from 'wavepaths-shared/core';

import { pointsToSvgPath } from '../../../pages/inSession/timeline/svgUtils';
import { getCurvePointsFromWave, TimelineWaveProperties } from '../../../pages/inSession/timeline/timelineWaveUtils';

const Svg = styled.svg`
    width: 100%;
    height: 100%;
    display: block;
    overflow: visible;
`;

// Background path with fill but no stroke
const BackgroundPath = styled.path`
    stroke: none;
    fill-opacity: 0.7;
    pointer-events: none;
`;

// Top edge path with stroke but no fill
const TopEdgePath = styled.path`
    stroke: white;
    stroke-width: 8px;
    stroke-linejoin: round;
    stroke-linecap: round;
    fill: none;
    pointer-events: none;
    vector-effect: non-scaling-stroke;
`;

const PIXELS_PER_MINUTE = 8;
const PIXELS_PER_MILLIS = PIXELS_PER_MINUTE / (60 * 1000);
const MAX_HEIGHT = 24;

interface WaveShapeProps {
    wave: ScheduledWavepath;
    height: number;
    // width is no longer needed since we're using 100% width
}

const WaveShape: React.FC<WaveShapeProps> = memo(({ wave, height }) => {
    const baseWidth = wave.plan ? (wave.plan.toTime - wave.plan.fromTime) * PIXELS_PER_MILLIS : 0;
    const svgHeight = height || MAX_HEIGHT;

    // We're using 100% width in the SVG, so we don't need these variables anymore
    // const heightScaleFactor = svgHeight / MAX_HEIGHT;
    // const svgWidth = width || baseWidth * heightScaleFactor;

    const timelineWave: TimelineWaveProperties = {
        wave,
        x: 0,
        width: baseWidth,
        height: MAX_HEIGHT,
    };
    const points = getCurvePointsFromWave(timelineWave, 0);

    // Get the path data for just the top curve and the closed path
    const { d: topPath } = pointsToSvgPath(points);

    // Create a custom path that only includes the top edge
    // Start with the top curve, then draw a line to the bottom-right, then to bottom-left, then close
    const backgroundPath = `${topPath} L ${baseWidth} ${MAX_HEIGHT} L 0 ${MAX_HEIGHT} Z`;

    // Generate unique IDs for the paths
    const uniqueId = React.useMemo(() => Math.random().toString(36).substring(2, 9), []);
    const backgroundPathId = `wave-shape-bg-${uniqueId}`;
    const topEdgePathId = `wave-shape-edge-${uniqueId}`;

    // Determine the emotion for color selection
    const emotion = isObject(wave.pathScore.emotion)
        ? wave.pathScore.emotion.from
        : wave.pathScore.emotion ?? CoreEmotionalAtmosphere.STILLNESS;

    // Get the appropriate secondary color based on emotion
    const getFillColor = () => {
        switch (emotion) {
            case 'Stillness':
                return 'var(--color-stillness-secondary)';
            case 'Vitality':
                return 'var(--color-vitality-secondary)';
            case 'Bittersweet':
                return 'var(--color-bittersweet-secondary)';
            case 'Tension':
                return 'var(--color-tension-secondary)';
            default:
                return 'rgba(255, 255, 255, 0.7)';
        }
    };

    return (
        <Svg
            height={svgHeight}
            width="100%"
            viewBox={`0 0 ${baseWidth} ${MAX_HEIGHT}`}
            preserveAspectRatio="xMinYMid slice"
            style={{ marginTop: '36px' }}
        >
            {/* Background path with fill but no stroke */}
            <BackgroundPath id={backgroundPathId} d={backgroundPath} fill={getFillColor()} />

            {/* Top edge path with stroke but no fill */}
            <TopEdgePath id={topEdgePathId} d={topPath} />
        </Svg>
    );
});

export default WaveShape;
