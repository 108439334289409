import * as Sentry from '@sentry/browser';
import { compact, noop, range } from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { createEnumArrayParam, createEnumParam, NumberParam, useQueryParam } from 'use-query-params';
import {
    AdministrationRoute,
    DosageLevel,
    DurationPreset,
    DurationPresets,
    Guidance,
    Intention,
    SESSION_SCORE_EMOTIONAL_INTENSITIES,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
} from 'wavepaths-shared/core';

import FilterInputDialog from '@/component-library/components/FilterInputs';
import PillValue from '@/component-library/components/PillValue';
import DashboardPageGuidance from '@/components/Dashboard/DashboardPageGuidance';
import DashboardPageHeading from '@/components/Dashboard/DashboardPageHeading';
import TemplateCard from '@/components/Templates/TemplateCard';
import TemplateCardGrid from '@/components/Templates/TemplateCardGrid';
import { TemplateCardLoader } from '@/components/Templates/TemplateCardLoader';
import { MODALITIES_V2 } from '@/domain/data/modalities';
import {
    getAdministrationsForModality,
    getDefaultSessionDurationForModality,
    getDosagesForMedicineAndAdministration,
    MODALITY_PERSONAL_LABELS,
    MODALITY_THERAPIST_LABELS,
    POSSIBLE_DURATIONS_FOR_MODALITY,
} from '@/domain/modalities';
import useScoreTemplates from '@/hooks/useScoreTemplates';

import { useAuthContext } from '../../../auth';
import { ListScoreTemplatesItem } from '../../../common/api/contentApi';
import { LayoutContainer } from '../../../LayoutContainer';

const NoResultsContainer = styled.div`
    width: 100%;
    grid-column: 1/-1;
    text-align: center;
`;

const Templates = React.memo(
    ({
        templates,
        isLoading,
        duration,
    }: {
        templates?: ListScoreTemplatesItem[];
        duration?: number | null;
        isLoading: boolean;
    }) => {
        if (!templates?.length && !isLoading) {
            // no results is kind of an error for the user and a bad experience
            Sentry.captureMessage('Templates no results');
        }

        return (
            <TemplateCardGrid>
                {templates &&
                    (templates.length ? (
                        templates.map((t) => (
                            <TemplateCard
                                key={'template-' + t.id}
                                onCardClick={() => {
                                    window.location.href =
                                        '/templates/' + t.id + (duration ? `?duration=${duration}` : '');
                                }}
                                title={t.name}
                                subtitle={t.subtitle}
                                intensity={t.intensity}
                                modality={t.modality}
                                minDurationMins={Math.ceil(t.durationMins.min)}
                                maxDurationMins={Math.floor(t.durationMins.max)}
                                emotionalities={t.emotionalities}
                                id={t.id}
                            />
                        ))
                    ) : (
                        <NoResultsContainer>
                            <p>Sorry, we couldn't find any Templates for these filters.</p>
                        </NoResultsContainer>
                    ))}
                {isLoading &&
                    range(16).map((i) => (
                        <TemplateCardLoader key={i} state={{ state: 'loading' }} onCardClick={noop} />
                    ))}
            </TemplateCardGrid>
        );
    },
);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const FilterInputsListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    flex-direction: row;
`;

const LoadMoreButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 48px 0;
`;

const LoadMoreButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 100%;
    max-width: 320px;
    background: var(--color-primary);
    color: var(--color-background);
    border: none;
    border-radius: 8px;
    padding: 0 16px;
    cursor: pointer;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
`;

export function TemplatesContainer({ program = '' }) {
    const { userData, firebaseUser, isPersonal } = useAuthContext();
    const groups = userData?.groups || [];

    const [durationMins, setDuration] = useQueryParam<number | null | undefined>('duration', NumberParam);

    const [_intensityValues, setIntensityValues] = useQueryParam(
        'intensity',
        createEnumArrayParam(Object.values(SessionScoreEmotionalIntensity)),
    );
    const intensityValues = _intensityValues ?? [];
    const intensityOptions = SESSION_SCORE_EMOTIONAL_INTENSITIES.map((intensity) => ({
        label: intensity,
        value: intensity,
    }));
    const allowedModalities = Object.values(SessionScoreModality).filter((x) => {
        if (!isPersonal || !groups.includes('LimitedModalities')) return true;
        const groupNameForModality = x + '_Modality';
        if (groups.includes(groupNameForModality)) return true;

        return false;
    });

    const allowedModalityOptionsEnum = createEnumParam(
        allowedModalities?.length ? allowedModalities : [SessionScoreModality.NONE],
    );

    const [modality, setModality] = useQueryParam('modality', allowedModalityOptionsEnum);

    const modalityLabels = isPersonal ? MODALITY_PERSONAL_LABELS : MODALITY_THERAPIST_LABELS;

    const modalityOptions = MODALITIES_V2.filter((x) => allowedModalities.includes(x.name)).map(({ name }) => ({
        label: modalityLabels[name],
        value: name,
    }));

    const durationPresets: DurationPreset[] = modality
        ? (Object.keys(DurationPresets).filter((x) =>
              POSSIBLE_DURATIONS_FOR_MODALITY[modality!].includes(x as DurationPreset),
          ) as DurationPreset[])
        : [];
    const durationOptions = durationPresets.map((x) => ({
        label: DurationPresets[x].label,
        value: DurationPresets[x].duration,
    }));

    const [administration, _setAdministration] = useQueryParam(
        'administration',
        createEnumParam(Object.values(AdministrationRoute)),
    );
    const administrationOptions = modality
        ? getAdministrationsForModality(modality).map(({ name, id }) => ({
              label: name,
              value: id,
          }))
        : [];

    const allowedDosages = Object.values(DosageLevel).filter((x) => {
        if (!isPersonal || !groups.includes('Limited_dosages')) return true;
        const groupNameForDosage = x + '_dosage';
        if (groups.includes(groupNameForDosage)) return true;

        return false;
    });

    const [dosage, _setDosage] = useQueryParam('dosage', createEnumParam(allowedDosages));
    const dosageOptions =
        modality && administration
            ? getDosagesForMedicineAndAdministration(modality, administration)
                  .filter((x) => allowedDosages.includes(x.id))
                  .map(({ name, id }) => ({
                      label: name,
                      value: id,
                  }))
            : [];

    const setDosage = (dosage?: DosageLevel | null) => {
        _setDosage(dosage);
        setDuration(undefined);
    };

    const setAdministration = (administration?: AdministrationRoute | null) => {
        _setAdministration(administration);
        setDosage(undefined);
        setDuration(undefined);
    };

    const [_intentionValues, setIntentionValues] = useQueryParam(
        'intentions',
        createEnumArrayParam(Object.values(Intention)),
    );
    const intentionValues = _intentionValues ?? [];

    const [_guidanceValues, setGuidanceValues] = useQueryParam(
        'guidances',
        createEnumArrayParam(Object.values(Guidance)),
    );
    const guidanceValues = _guidanceValues ?? [];

    //intention option only for Ketamine for now - need to tag other templates
    const intentionOptions =
        modality == SessionScoreModality.KETAMINE
            ? Object.values(Intention).map((intention) => ({
                  label: intention,
                  value: intention,
              }))
            : [];

    const guidanceOptions = Object.values(Guidance)
        //TODO allow guidence options for Therapists
        .filter((x) => x && isPersonal)
        .map((x) => ({
            label: x,
            value: x,
        }));

    const filterCriteria = {
        modalities: modality && [modality],
        administrations: administration && [administration],
        dosages: dosage && [dosage],
        durationMins,
        intensities: intensityValues,
        intentions: intentionValues,
        guidances: guidanceValues,
        program,
    };

    const handleSetModality = (modality?: SessionScoreModality | null) => {
        setModality(modality);
        const availableAdministrations = modality ? getAdministrationsForModality(modality) : [];
        setAdministration(availableAdministrations.length === 1 ? availableAdministrations[0].id : undefined);
        setDosage(undefined);
        //TODO: set intention for all modalities
        setIntentionValues([]);
    };

    useEffect(() => {
        if (dosage && modality && !durationMins) {
            setDuration(getDefaultSessionDurationForModality(modality, { administration, dosage }));
        }
    }, [dosage, modality]);

    const { templates, loadNext, isLoading, remainingPages } = useScoreTemplates({
        fbUser: firebaseUser,
        itemsPerPage: 32,
        criteria: filterCriteria,
    });

    return (
        <Container>
            <FilterInputsListContainer>
                {!!modality && (
                    <PillValue
                        options={modalityOptions!}
                        name=""
                        values={compact([modality])}
                        onItemDelete={() => handleSetModality(undefined)}
                    />
                )}
                {!!administrationOptions.length && !!administration ? (
                    <PillValue
                        options={administrationOptions}
                        name=""
                        values={compact([administration])}
                        onItemDelete={() => setAdministration(undefined)}
                    />
                ) : null}
                {!!dosageOptions.length && !!dosage ? (
                    <PillValue
                        options={dosageOptions.map((x) => {
                            return {
                                ...x,
                                label: x.label + ' Dose',
                            };
                        })}
                        name=""
                        values={compact([dosage])}
                        onItemDelete={() => setDosage(undefined)}
                    />
                ) : null}
                {!!guidanceValues.length && (
                    <PillValue
                        options={guidanceOptions}
                        name=""
                        values={guidanceValues}
                        onItemClick={(itemValue) =>
                            setGuidanceValues(guidanceValues.filter((val) => itemValue !== val))
                        }
                        onItemDelete={(itemValue) =>
                            setGuidanceValues(guidanceValues.filter((val) => itemValue !== val))
                        }
                    />
                )}

                {!!intensityValues.length && (
                    <PillValue
                        options={intensityOptions.map((x) => {
                            return {
                                ...x,
                                label: x.label + ' Intensity',
                            };
                        })}
                        name=""
                        values={intensityValues}
                        onItemClick={(itemValue) =>
                            setIntensityValues(intensityValues.filter((val) => itemValue !== val))
                        }
                        onItemDelete={(itemValue) =>
                            setIntensityValues(intensityValues.filter((val) => itemValue !== val))
                        }
                    />
                )}
                {!!intentionValues.length && (
                    <PillValue
                        options={intentionOptions.map((x) => {
                            return {
                                ...x,
                                label: 'To ' + x.label,
                            };
                        })}
                        name=""
                        values={intentionValues}
                        onItemClick={(itemValue) =>
                            setIntentionValues(intentionValues.filter((val) => itemValue !== val))
                        }
                        onItemDelete={(itemValue) =>
                            setIntentionValues(intentionValues.filter((val) => itemValue !== val))
                        }
                    />
                )}
            </FilterInputsListContainer>
            <FilterInputDialog
                modalityOptions={modalityOptions}
                modalityValue={modality}
                onModalityValueChange={handleSetModality}
                intensityOptions={intensityOptions}
                intensityValues={intensityValues}
                onIntensityValuesChange={setIntensityValues}
                guidanceOptions={guidanceOptions}
                guidanceValues={guidanceValues}
                onGuidanceValuesChange={setGuidanceValues}
                intentionOptions={intentionOptions}
                intentionValues={intentionValues}
                onIntentionValuesChange={setIntentionValues}
                administrationOptions={administrationOptions}
                administration={administration}
                onAdministrationChange={setAdministration}
                dosageOptions={dosageOptions}
                dosage={dosage}
                onDosageChange={setDosage}
                onDurationChange={setDuration}
                durationValue={durationMins}
                durationOptions={durationOptions}
                program={program}
            />
            <Templates isLoading={isLoading} templates={templates} duration={durationMins} />
            {!isLoading && remainingPages > 0 && (
                <LoadMoreButtonContainer>
                    <LoadMoreButton onClick={loadNext}>Load more</LoadMoreButton>
                </LoadMoreButtonContainer>
            )}
        </Container>
    );
}

function TemplatesWithNav() {
    return (
        <LayoutContainer>
            <DashboardPageHeading pageTitle="Templates" />
            <DashboardPageGuidance
                pageTitle="Templates"
                guidanceText="Templates are collections of Waves curated by us, with particular functions or emotional journeys in mind. They can be used as they are, or as a starting point for your own."
            />
            <TemplatesContainer />
        </LayoutContainer>
    );
}

export default TemplatesWithNav;
