import styled from '@emotion/styled';
import React, { PropsWithChildren, ReactNode } from 'react';

const Container = styled.div`
    display: flex;
    flex-flow: column wrap;
    gap: 8px;
    padding: 24px;
    border-radius: 8px;
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
`;

const Title = styled.h3`
    margin: 0;
`;

const Meta = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.span`
    font-weight: var(--font-weight-small);
`;

const Client = styled.p`
    font-size: var(--font-size-small);
    color: var(--color-muted);
    margin: 0;
`;

const User = styled.p`
    font-size: var(--font-size-small);
    color: var(--color-muted);
    margin: 0;
`;

const Content = styled.div``;

const Info = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const InfoItems = styled.div`
    display: flex;
    flex-direction: column;
`;

const InfoItem = styled.p`
    font-size: var(--font-size-small);
    color: var(--color-muted);
    margin: 0;
`;

const Feedback = styled.div({
    display: 'flex',
    justifyContent: 'end',
});

const Actions = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    margin-top: 8px;
`;

const PrimaryAction = styled.div`
    display: inline-flex;
`;

const SecondaryActions = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
`;

const ActionButtonPrimary = styled.button`
    justify-self: flex-start;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    min-width: 180px;
    background: var(--color-primary);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0 16px;
    cursor: pointer;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
`;
const ActionButtonSecondary = styled.button`
    display: inline-flex;
    align-items: center;
    height: 36px;
    background: transparent;
    color: var(--color-primary);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0 16px;
    cursor: pointer;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
`;

export interface ISessionAction {
    description: string;
    action: () => void;
}
export interface ISessionCardProps {
    title: string;
    subtitle: string;
    info: string[];
    primaryActionLabel?: string;
    primaryAction?: () => void;
    actions?: ISessionAction[];
    actionsComponent?: ReactNode;
    subActions?: ISessionAction[];
    userInfoText?: string;
}

const SessionCard = ({
    title,
    subtitle,
    info,
    primaryActionLabel = 'Open',
    primaryAction,
    actions = [],
    actionsComponent,
    subActions,
    userInfoText,
    children,
}: PropsWithChildren<ISessionCardProps>): JSX.Element => {
    return (
        <Container>
            <Title>{title}</Title>
            <Meta>
                {userInfoText && (
                    <User>
                        <Label>Created by:</Label> {userInfoText}
                    </User>
                )}
                {subtitle && (
                    <Client>
                        <Label>Client:</Label> {subtitle}
                    </Client>
                )}
                <Content>{children}</Content>
                <Info>
                    {info.length === 1 ? (
                        <InfoItem>
                            <Label>Status:</Label> {info}
                        </InfoItem>
                    ) : (
                        <InfoItems>
                            {info.map((infoItem, idx) => (
                                <InfoItem key={idx}>{infoItem}</InfoItem>
                            ))}
                        </InfoItems>
                    )}
                    {subActions && (
                        <Feedback>
                            {subActions.map((action, idx) => (
                                <ActionButtonSecondary key={idx} onClick={action.action}>
                                    {action.description}
                                </ActionButtonSecondary>
                            ))}
                        </Feedback>
                    )}
                </Info>
            </Meta>
            <Actions>
                <PrimaryAction>
                    {primaryAction && (
                        <ActionButtonPrimary onClick={primaryAction}>{primaryActionLabel}</ActionButtonPrimary>
                    )}
                </PrimaryAction>
                <SecondaryActions>
                    {actions?.length
                        ? actions.map(({ description, action }, idx) => (
                              <ActionButtonSecondary key={idx} onClick={action}>
                                  {description}
                              </ActionButtonSecondary>
                          ))
                        : null}
                    {actionsComponent}
                </SecondaryActions>
            </Actions>
        </Container>
    );
};

export default SessionCard;
