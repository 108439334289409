import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import { useSessionStorage } from 'react-use';
import { SessionRenderType } from 'wavepaths-shared/core';

import { GlobalSnackbarContext } from '@/components/Snackbar';
import { Features } from '@/features';
import { useRemoteElapsedTimeSecs } from '@/hooks';

import { useAuthContext } from '../../../auth';
import { AudioPlayer, AudioPlayerContext, AudioPlayerMaker, NULL_AUDIO_PLAYER } from '../AudioPlayerProvider';
import { RemoteSessionControlsContext } from '../Guide';

const AudioPlayerHookWrapper = ({
    outputDevice,
    children,
}: {
    outputDevice: string | undefined;
    children: ReactNode;
}) => {
    const broadcastElapsedTimeSecs = useRemoteElapsedTimeSecs();
    const { isEnabled } = useAuthContext();

    const remoteSession = useContext(RemoteSessionControlsContext);
    if (remoteSession === undefined) throw new Error('No RemoteSessionControlsContext');
    if (remoteSession.session === undefined) throw new Error('No remoteSession.session');

    const session = remoteSession.session;

    const playDemoVO = !isEnabled(Features.FREE_ACCESS);

    const [audioPlayer, setAudioPlayer] = useState<AudioPlayer>(NULL_AUDIO_PLAYER);
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    useEffect(() => {
        setSnackbarContent(audioPlayer.warning);
    }, [audioPlayer.warning, setSnackbarContent]);

    if (!remoteSession.broadcastState) {
        return null;
    }

    return (
        <>
            <AudioPlayerMaker
                playerProps={{
                    outputDevice,
                    broadcastIdentifier: session.broadcastIdentifier,
                    renderType: session.renderType,
                    errorContext: 'Adaptive Session',
                    mode: session.renderType == SessionRenderType.PREDICTIVE_COMPOSED ? 'recording' : 'live',
                    broadcastState: remoteSession.broadcastState,
                    broadcastElapsedTimeSecs,
                    sessionDuration: remoteSession.tick?.sessionDuration ?? 0,
                    voiceOverStages: session.score.voiceover ?? [],
                    playDemoVO,
                }}
                setAudioPlayer={setAudioPlayer}
            />
            <AudioPlayerContext.Provider value={audioPlayer}>{children}</AudioPlayerContext.Provider>
        </>
    );
};

const AudioPlayerContextWrapper = ({ children }: { children: ReactNode }) => {
    const playerChoiceContext = useContext(PlayerChoiceContext);
    if (playerChoiceContext === undefined) throw new Error('No PlayerChoiceContext');
    const playHere =
        playerChoiceContext.playerChoice === 'browser' &&
        (playerChoiceContext.whereToPlayMusic === 'thisDevice' ||
            playerChoiceContext.whereToPlayMusic === 'thisAndRemote');
    return (
        <>
            {playHere ? (
                <AudioPlayerHookWrapper outputDevice={playerChoiceContext.browserPlayerOutputDevice}>
                    {children}
                </AudioPlayerHookWrapper>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

export type DialogState = 'openInitial' | 'reopen' | 'closed';
export type WhereToControl = 'controlOnThisDevice' | 'controlOnRemoteDevice';
export type WhereToPlayMusic = 'thisDevice' | 'remoteOnly' | 'thisAndRemote';
export type PlayerChoice = 'browser' | 'native';

type PlayerChoiceState = {
    dialogState: DialogState;
    whereToControl: WhereToControl;
    whereToPlayMusic: WhereToPlayMusic | undefined;
    playerChoice: PlayerChoice;
    browserPlayerOutputDevice: string | undefined;
};
export const usePlayerChoice = (renderingType: SessionRenderType) => {
    const [playerChoiceState, setPlayerChoiceState] = useSessionStorage<PlayerChoiceState>('player-choice', {
        dialogState: 'openInitial',
        whereToControl: 'controlOnThisDevice',
        whereToPlayMusic: 'thisDevice',
        playerChoice: isSafari || isIOS ? 'native' : 'browser',
        browserPlayerOutputDevice: undefined,
    });
    useEffect(() => {
        // Always open initially on page load, so we always have an explicit click action to start audio.
        const initState: PlayerChoiceState = { ...playerChoiceState, dialogState: 'openInitial' };
        if (
            (isSafari || isIOS) &&
            (playerChoiceState.whereToPlayMusic === 'thisDevice' ||
                playerChoiceState.whereToPlayMusic === 'thisAndRemote')
        ) {
            // On Safari, force the compatible player choice (as session rendering type may have changed from the last session)
            initState.playerChoice = 'native';
        }
        setPlayerChoiceState(initState);
    }, [renderingType]);
    const {
        dialogState,
        whereToControl,
        whereToPlayMusic,
        playerChoice,
        browserPlayerOutputDevice,
    } = playerChoiceState;
    return {
        isDialogOpen: dialogState === 'openInitial' || dialogState === 'reopen',
        isInitialDialog: dialogState === 'openInitial',
        openDialog: () => setPlayerChoiceState({ ...playerChoiceState, dialogState: 'reopen' }),
        closeDialog: () => setPlayerChoiceState({ ...playerChoiceState, dialogState: 'closed' }),
        update: (update: Partial<PlayerChoiceState>) => setPlayerChoiceState({ ...playerChoiceState, ...update }),
        closeDialogWithUpdate: (update: Partial<PlayerChoiceState>, callback?: () => void) => {
            setPlayerChoiceState({ ...playerChoiceState, dialogState: 'closed', ...update });
            if (callback) {
                callback();
            }
        },
        whereToControl,
        whereToPlayMusic,
        playerChoice,
        browserPlayerOutputDevice,
    };
};

export const PlayerChoiceContextWrapper = ({
    sessionRenderType,
    children,
}: {
    sessionRenderType: SessionRenderType;
    children: React.ReactNode;
}) => {
    const playerChoiceContextValue = usePlayerChoice(sessionRenderType);
    return (
        <PlayerChoiceContext.Provider value={playerChoiceContextValue}>
            <AudioPlayerContextWrapper>{children}</AudioPlayerContextWrapper>
        </PlayerChoiceContext.Provider>
    );
};

export const PlayerChoiceContext = createContext<ReturnType<typeof usePlayerChoice> | undefined>(undefined);
