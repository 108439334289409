import { makeStyles } from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';
import classNames from 'classnames';
import { omit } from 'lodash';
import React, { ReactElement, ReactNode } from 'react';

import { FONT_SIZES } from '../typography/variables';

const useStyles = makeStyles({
    // Base styles
    root: {
        minWidth: 0,
        borderRadius: '50px',
        borderStyle: 'solid',
        borderWidth: '1px',
        fontFamily: 'Inter',
        transition: '0.15s all',
        opacity: 1,
    },
    // Size variants
    sizeXs: {
        fontSize: FONT_SIZES.extraSmall,
        fontWeight: 600,
    },
    sizeS: {
        fontSize: FONT_SIZES.small,
        fontWeight: 600,
    },
    sizeM: {
        fontSize: FONT_SIZES.small,
        fontWeight: 600,
    },
    sizeL: {
        fontSize: FONT_SIZES.medium,
        fontWeight: 500,
    },
    // Variant styles
    solidLight: {
        backgroundColor: 'rgb(255,255,255)',
        borderColor: 'transparent',
        color: '#000000',
        '&:hover': {
            backgroundColor: 'rgb(255,255,255)',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            borderColor: 'transparent',
        },
        '&:disabled': {
            backgroundColor: '#FFFF',
            color: 'rgba(0, 0, 0, 0.3)',
        },
    },
    solidDark: {
        backgroundColor: 'rgb(0,0,0)',
        borderColor: 'transparent',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'rgb(0,0,0)',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            borderColor: 'transparent',
        },
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.3);',
            color: 'rgba(255, 255, 255, 0.3)',
        },
    },
    solidBlue: {
        backgroundColor: 'rgb(44, 57, 88)',
        borderColor: 'transparent',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'rgb(44, 57, 88)',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            borderColor: 'transparent',
        },
        '&:disabled': {
            backgroundColor: 'rgba(44, 57, 88, 0.5)',
            color: 'rgba(255, 255, 255, 0.3)',
        },
    },
    outlined: {
        backgroundColor: 'transparent',
        borderColor: 'rgba(0,0,0,0.12)',
        color: '#000000',
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: 'rgba(0,0,0,0.24)',
        },
        '&:disabled': {
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.3)',
        },
    },
    clear: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: '#000000',
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: 'rgba(0,0,0,0.12)',
        },
        '&:disabled': {
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.3)',
        },
    },
    clearLight: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: 'rgba(255,255,255,0.24)',
        },
        '&:disabled': {
            backgroundColor: 'transparent',
            color: 'rgba(255, 255, 255, 0.3)',
        },
    },
    clearUnderlined: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: '#000000',
        textDecoration: 'underline',
        textTransform: 'none',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:disabled': {
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.3)',
        },
    },
    // Padding variations
    paddingClearUnderlined: {
        padding: '0px 0px',
    },
    paddingXsIconWithText: {
        padding: '4px 12px 4px 8px',
    },
    paddingXsIconOnly: {
        padding: '4px 4px',
    },
    paddingXs: {
        padding: '4px 12px',
    },
    paddingSIconWithText: {
        padding: '4px 12px 4px 8px',
    },
    paddingSIconOnly: {
        padding: '4px 4px',
    },
    paddingS: {
        padding: '4px 12px',
    },
    paddingMIconWithText: {
        padding: '8px 16px 8px 12px',
    },
    paddingMIconOnly: {
        padding: '8px 8px',
    },
    paddingM: {
        padding: '8px 16px',
    },
    paddingLIconWithText: {
        padding: '12px 24px 12px 18px',
    },
    paddingLIconOnly: {
        padding: '12px 12px',
    },
    paddingL: {
        padding: '12px 24px',
    },
    // State styles
    disabled: {
        opacity: 0.5,
        cursor: 'default',
    },
    // Label styles
    label: {
        display: 'inline-grid',
        gridAutoFlow: 'column',
        alignContent: 'center',
    },
    labelGapSmall: {
        gap: '4px',
    },
    labelGapLarge: {
        gap: '8px',
    },
});

interface StyleProps {
    variant?: 'clear-underlined' | 'clear' | 'clear-light' | 'solid-light' | 'solid-dark' | 'solid-blue' | 'outlined';
    size?: 'xs' | 's' | 'm' | 'l';
}

export interface IButtonProps extends StyleProps {
    children?: ReactNode | ReactNode[];
    icon?: ReactNode;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    style?: React.CSSProperties;
    type?: 'button' | 'submit' | 'reset';
    className?: string;
}

function getVariantClass(
    variant?: string,
): 'solidLight' | 'solidDark' | 'solidBlue' | 'outlined' | 'clear' | 'clearLight' | 'clearUnderlined' {
    switch (variant) {
        case 'solid-light':
            return 'solidLight';
        case 'solid-dark':
            return 'solidDark';
        case 'solid-blue':
            return 'solidBlue';
        case 'outlined':
            return 'outlined';
        case 'clear':
            return 'clear';
        case 'clear-light':
            return 'clearLight';
        case 'clear-underlined':
            return 'clearUnderlined';
        default:
            return 'clear';
    }
}

function getSizeClass(size?: string): 'sizeXs' | 'sizeS' | 'sizeM' | 'sizeL' {
    switch (size) {
        case 'xs':
            return 'sizeXs';
        case 's':
            return 'sizeS';
        case 'm':
            return 'sizeM';
        case 'l':
            return 'sizeL';
        default:
            return 'sizeS';
    }
}

function getPaddingClass(
    props: IButtonProps,
):
    | 'paddingClearUnderlined'
    | 'paddingXsIconWithText'
    | 'paddingXsIconOnly'
    | 'paddingXs'
    | 'paddingSIconWithText'
    | 'paddingSIconOnly'
    | 'paddingS'
    | 'paddingMIconWithText'
    | 'paddingMIconOnly'
    | 'paddingM'
    | 'paddingLIconWithText'
    | 'paddingLIconOnly'
    | 'paddingL' {
    const { variant, size, icon, children } = props;

    if (variant === 'clear-underlined') return 'paddingClearUnderlined';

    if (size === 'xs' && icon && children) return 'paddingXsIconWithText';
    if (size === 'xs' && icon && !children) return 'paddingXsIconOnly';
    if (size === 'xs') return 'paddingXs';

    if (size === 's' && icon && children) return 'paddingSIconWithText';
    if (size === 's' && icon && !children) return 'paddingSIconOnly';
    if (size === 's') return 'paddingS';

    if (size === 'm' && icon && children) return 'paddingMIconWithText';
    if (size === 'm' && icon && !children) return 'paddingMIconOnly';
    if (size === 'm') return 'paddingM';

    if (size === 'l' && icon && children) return 'paddingLIconWithText';
    if (size === 'l' && icon && !children) return 'paddingLIconOnly';
    if (size === 'l') return 'paddingL';

    return 'paddingS';
}

export default function Button(props: IButtonProps): ReactElement {
    const { icon, children, onClick, variant, size, disabled, ...rest } = props;
    const classes = useStyles();

    const rootClasses = classNames(
        classes.root,
        classes[getVariantClass(variant)],
        classes[getSizeClass(size)],
        classes[getPaddingClass(props)],
        disabled && classes.disabled,
    );

    const labelClasses = classNames(classes.label, size === 'l' ? classes.labelGapLarge : classes.labelGapSmall);

    return (
        <MaterialButton
            classes={{ root: rootClasses, label: labelClasses }}
            className={props.className}
            onClick={onClick}
            disabled={disabled}
            {...omit(rest, ['variant', 'size'])}
        >
            {icon}
            {children}
        </MaterialButton>
    );
}
