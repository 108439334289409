import styled from '@emotion/styled';
import React from 'react';
import { CoreEmotionalAtmosphere, SessionScoreEmotionalIntensity } from 'wavepaths-shared/core';
import { formatDurationMinutes } from 'wavepaths-shared/util/dateUtils';

import { IntensityIconProps } from '@/component-library/components/icons/IntensityIcon';
import TemplateArtwork from '@/component-library/components/TemplateArtwork';

const SessionCardContainer = styled.div`
    cursor: pointer;
    width: 220px;
    flex: 0 0 auto;
`;

const Artwork = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    transition: filter 0.2s ease;
    &:hover {
        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.1));
    }
    svg {
        display: block;
        width: 100%;
        height: auto;
    }
    margin-bottom: 7px;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Title = styled.p<{ variant: 'dark' | 'light' }>`
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    line-height: 1;
    margin: 0;
    color: ${(props) => (props.variant === 'dark' ? 'var(--color-primary)' : 'rgba(255, 255, 255, 1)')};
`;

const Description = styled.div<{ variant: 'dark' | 'light' }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${(props) => (props.variant === 'dark' ? 'var(--color-muted)' : 'rgba(255, 255, 255, 0.75)')};
`;

const Meta = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const MetaItem = styled.div`
    display: flex;
    gap: 4px;
    font-size: var(--font-size-xsmall);
    line-height: 1;
    margin: 0;
`;

export interface SessionCardProps {
    onCardClick: (id: string) => void;
    className?: string;
    id: string;
    title: string;
    duration: number;
    emotionalities: {
        primary: CoreEmotionalAtmosphere;
        secondary: CoreEmotionalAtmosphere;
        tertiary: CoreEmotionalAtmosphere;
    };
    variant?: 'dark' | 'light';
    isQuickStart?: boolean;
    createdBy?: string;
    intensity: IntensityIconProps['intensity'];
}

const SessionCard: React.FC<SessionCardProps> = ({
    onCardClick,
    id,
    title,
    emotionalities,
    variant = 'dark',
    duration,
    intensity = SessionScoreEmotionalIntensity.LOW,
}) => (
    <SessionCardContainer
        onClick={() => {
            onCardClick('id');
        }}
    >
        <Artwork>
            <TemplateArtwork
                randomSeed={id}
                intensity={intensity}
                primaryEmotion={emotionalities?.primary ?? CoreEmotionalAtmosphere.SILENCE}
                secondaryEmotion={emotionalities?.secondary ?? CoreEmotionalAtmosphere.SILENCE}
                tertiaryEmotion={emotionalities?.tertiary ?? CoreEmotionalAtmosphere.SILENCE}
            />
        </Artwork>
        <Info>
            <Title variant={variant}>{title}</Title>
            <Description variant={variant}>
                <Meta>
                    <MetaItem>
                        <span>{formatDurationMinutes(duration * 60 * 1000)}</span>
                    </MetaItem>
                </Meta>
            </Description>
        </Info>
    </SessionCardContainer>
);

export default SessionCard;
