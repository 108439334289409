import styled from '@emotion/styled';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { ScheduledWavepath } from 'wavepaths-shared/core';

import {
    Instrumentation,
    useAvailableInstruments,
    WaveInstrumentation,
} from '@/components/WavePathSelector/useWaveInstruments';
import { InstrumentPreview } from '@/hooks/useInstrumentPreview';

import IconClose from '../../../../images/icons/icon-close.svg';
import { SoundDetails } from './SoundDetails';
import SoundLayer from './SoundLayer';
import { SoundPreview } from './SoundPreview';

const Background = styled.div`
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 24px 24px 0 0;
    background: #fff;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(43, 45, 62, 0.1);
`;

const ButtonClose = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 0.2s ease;
    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
    svg {
        width: 24px;
        height: 24px;
    }
`;

const Content = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;

const SelectButton = styled.button`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    gap: 8px;
    border-radius: 8px;
    border: none;
    background: var(--color-primary);
    border-radius: 8px;
    height: 44px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    color: #fff;
    svg {
        width: 16px;
        height: 16px;
        fill: none;
        path {
            stroke: white;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
    }
`;

interface SoundSelectorProps {
    wavepath: ScheduledWavepath;
    current: WaveInstrumentation;
    instrumentPreview: InstrumentPreview;
    onSelect: (instrument: Instrumentation) => void;
    onCancel: () => void;
}

export const SoundSelector: React.FC<SoundSelectorProps> = ({
    wavepath,
    current,
    instrumentPreview,
    onSelect,
    onCancel,
}) => {
    const available = useAvailableInstruments(wavepath, current.group.id);
    const replacements = available.filter((i) => !isEqual(i, current.instrumentation));

    const [viewingDetailsFor, setViewingDetailsFor] = useState<Instrumentation | null>(null);

    const closeDetails = () => {
        setViewingDetailsFor(null);
        instrumentPreview.stop();
    };

    const selectCurrent = () => {
        if (viewingDetailsFor) {
            closeDetails();
            onSelect(viewingDetailsFor);
        }
    };

    return (
        <Background>
            <Container>
                <Header>
                    <h3>Replace with</h3>
                    <ButtonClose onClick={onCancel}>
                        <img src={IconClose} alt="Close" />
                    </ButtonClose>
                </Header>
                <Content>
                    {replacements.map((i) => (
                        <SoundLayer
                            key={getInstrumentationKey(i)}
                            instrument={i}
                            isCurrent={false}
                            onSelect={() => setViewingDetailsFor(i)}
                        />
                    ))}
                </Content>
                {viewingDetailsFor && (
                    <SoundDetails instrumentation={viewingDetailsFor} onClose={closeDetails}>
                        <SoundPreview instrumentPreview={instrumentPreview} instrumentation={viewingDetailsFor} />
                        <SelectButton onClick={selectCurrent}>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M16.6663 5L7.49967 14.1667L3.33301 10" />
                            </svg>
                            Select
                        </SelectButton>
                    </SoundDetails>
                )}
            </Container>
        </Background>
    );
};

function getInstrumentationKey(instrument: Instrumentation) {
    if (instrument.type === 'determinedWithLayers') {
        return instrument.layers.map((l) => l.id).join(',');
    } else if (instrument.type === 'determinedWithPathScore') {
        return instrument.pathScore.id;
    } else if (instrument.type === 'determinedWithNoContent') {
        return 'nocontent';
    } else if (instrument.type === 'undetermined') {
        return 'undetermined';
    }
}
