import styled from '@emotion/styled';
import React from 'react';
import { isScheduledWavepath, PathScore, Wavepath } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';

import { WaveSparkline } from '../../../pages/inSession/timeline/WaveSparkline';
import WaveCardTopBar from './WaveCardTopBar';
import { WaveCardWaveDuration } from './WaveCardWaveDuration';

const HeaderContent = styled.div({
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateAreas: '"title sparkline duration actions"',
    gridTemplateColumns: '1fr 150px 45px minmax(min-content, auto)',
    alignItems: 'center',
    gap: '8px',
});

const WaveTitle = styled(Typography)({
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#2c3958',
});

const SparklineWrapper = styled.div({
    gridArea: 'sparkline',
    height: '100%',
    position: 'relative',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'start',
    overflow: 'hidden',
});

const SparklineOverflow = styled.div({
    width: '100%',
    height: '100%',
    padding: '4px', // Preventing overflow issue
});

const Duration = styled(Typography)({
    gridArea: 'duration',
    textAlign: 'right',
    color: '#2c3958',
});

export function WaveCardHeaderContentContainer({
    name,
    wave,
    previousWave,
    isOpen,
    pathScore,
    onRemoveWave,
    onMoveWaveUp,
    onMoveWaveDown,
}: {
    name: string;
    wave: Wavepath;
    previousWave: Wavepath | undefined;
    isOpen: boolean;
    pathScore: PathScore;
    onRemoveWave: (e: any) => void;
    onMoveWaveUp?: (e: any) => void;
    onMoveWaveDown?: (e: any) => void;
}) {
    return (
        <HeaderContent>
            <div
                style={{
                    gridArea: 'title',
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                }}
            >
                <WaveTitle variant="body2">{name}</WaveTitle>
            </div>
            {isScheduledWavepath(wave) && wave.plan && (
                <SparklineWrapper>
                    <SparklineOverflow>
                        <WaveSparkline
                            wave={wave}
                            previousWave={previousWave && isScheduledWavepath(previousWave) ? previousWave : undefined}
                        />
                    </SparklineOverflow>
                </SparklineWrapper>
            )}

            {/* <Intensity variant="body3">{capitalize(emotionalIntensity)}</Intensity> */}
            <Duration variant="body3">
                <WaveCardWaveDuration wave={wave} />
            </Duration>
            <WaveCardTopBar
                isOpen={isOpen}
                pathScore={pathScore}
                onRemoveWave={onRemoveWave}
                onMoveWaveUp={onMoveWaveUp}
                onMoveWaveDown={onMoveWaveDown}
            />
        </HeaderContent>
    );
}
