import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import React from 'react';
import { Wavepath } from 'wavepaths-shared/core';

import {
    isWaveSelected,
    selectNoWave,
    selectWave,
    WaveSelection,
} from '../../../pages/inSession/autoGuide/waveSelection';
import UserEvents from '../../../UserEvents';
import { WaveCardDialog } from './WaveCardDialog';
import { WaveCardHeaderContentContainer } from './WaveCardHeaderContentContainer';

export const SKIP_WAVE_LABEL = 'Skip To This Wave';

const StyledCard = styled(Card)({
    width: '100%',
    height: 'auto',
    display: 'grid',
    // gridAutoFlow: 'row',
    justifyItems: 'center',
    padding: '0 12px 0 20px',
    borderRadius: 4,
    background: 'rgba(255,255,255,1)',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 'auto',
    overflow: 'visible',

    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderRadius: 4,
        boxShadow: '0px 0px 20px rgba(0,0,0,0.1)',
    },
});

const Header = styled.div({
    width: '100%',
    height: '52px',
    display: 'flex',
    placeContent: 'center',
    cursor: 'pointer',
});

export const CARD_HEIGHT_PADDING = 12;
const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: CARD_HEIGHT_PADDING,
    // position: 'relative',
});

function WaveCard({
    index,
    wave,
    previousWave,
    waveSelection,
    shouldAllowAudioPreviews,
    onUpdateWave,
    onSkipToWave,
    onRemoveWave,
    onMoveWaveUp,
    onMoveWaveDown,
    onSetWaveSelection,
}: {
    wave: Wavepath;
    index: number;
    previousWave?: Wavepath;
    waveSelection: WaveSelection;
    shouldAllowAudioPreviews: boolean;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
    onSkipToWave?: (e: any) => void;
    onRemoveWave: (e: any) => void;
    onMoveWaveUp: (e: any) => void;
    onMoveWaveDown: (e: any) => void;
    onSetWaveSelection: (waveSelection: WaveSelection, force?: boolean) => void;
}): JSX.Element {
    const isOpen = isWaveSelected(waveSelection, wave.id);
    const { pathScore } = wave;

    const waveDirection = pathScore.direction;
    const waveEmotionality = pathScore.name;
    const isPercussive = 'mode' in pathScore && pathScore.mode === 'Percussive';
    const isStructured = 'mode' in pathScore && pathScore.mode === 'Structured';
    const name = `${isPercussive ? 'Percussive' : ''} ${
        isStructured ? 'Structured' : ''
    } ${waveDirection}: ${waveEmotionality}`;

    const onCardClick = () => {
        if (isOpen) {
            onSetWaveSelection(selectNoWave());
            UserEvents.closeWaveViaQueue();
        } else {
            onSetWaveSelection(selectWave(wave));
            UserEvents.openWaveViaQueue();
        }
    };

    return (
        <>
            <Container>
                <StyledCard>
                    <Header aria-label="Edit upcoming wave" onClick={onCardClick}>
                        <WaveCardHeaderContentContainer
                            name={name}
                            wave={wave}
                            previousWave={previousWave}
                            isOpen={isOpen}
                            pathScore={pathScore}
                            onRemoveWave={onRemoveWave}
                            onMoveWaveUp={onMoveWaveUp}
                            onMoveWaveDown={onMoveWaveDown}
                        />
                    </Header>
                </StyledCard>
            </Container>
            {isOpen && (
                <WaveCardDialog
                    isOpen={isOpen}
                    wave={wave}
                    waveIndex={index}
                    previousWave={previousWave}
                    shouldAllowAudioPreviews={shouldAllowAudioPreviews}
                    onUpdateWave={onUpdateWave}
                    onRemoveWave={onRemoveWave}
                    onSkipToWave={onSkipToWave}
                    onClose={onCardClick}
                />
            )}
        </>
    );
}

export default WaveCard;
