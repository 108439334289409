import styled from '@emotion/styled';
import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';
import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';

import { getWaveformData, SessionVisualPreview } from '@/hooks/useSessionVisualPreview';

import { WaveInstrumentation } from '../WavePathSelector/useWaveInstruments';

const WAVEFORM_HEIGHT = 56;

const Container = styled.svg`
    display: block;
    height: ${WAVEFORM_HEIGHT}px;
`;

const Marker = styled.rect<{ emotion: CoreEmotionalAtmosphere }>`
    fill: ${({ emotion }) =>
        emotion === 'Stillness'
            ? 'var(--color-stillness-secondary)'
            : emotion === 'Vitality'
            ? 'var(--color-vitality-secondary)'
            : emotion === 'Bittersweet'
            ? 'var(--color-bittersweet-secondary)'
            : emotion === 'Tension'
            ? 'var(--color-tension-secondary)'
            : 'var(--color-primary)'};
`;

const SECONDS_PER_MEASUREMENT = 0.5;
const MIN_DB = -100;
const MAX_DB = -15;

function lerp(n: number, fromMin: number, fromMax: number, toMin: number, toMax: number) {
    return toMin + ((n - fromMin) / (fromMax - fromMin)) * (toMax - toMin);
}

interface WaveVolumeWaveformProps {
    waveInstrumentation: WaveInstrumentation;
    preview: SessionVisualPreview;
    pixelsPerSecond: number;
    emotion: CoreEmotionalAtmosphere;
}

export const WaveVolumeWaveform = React.memo(
    ({ waveInstrumentation, preview, pixelsPerSecond, emotion }: WaveVolumeWaveformProps) => {
        const waveformData = useMemo(
            () =>
                getWaveformData({
                    preview,
                    layerGroup: waveInstrumentation.group.layerNumbers,
                    secondsPerMeasurement: SECONDS_PER_MEASUREMENT,
                })
                    .map((value, index) => ({ value, index }))
                    .filter(({ value }) => value > MIN_DB),
            [preview, waveInstrumentation.group.layerNumbers.join(',')],
        );
        const width = waveformData.length * pixelsPerSecond;
        const markerWidth = (pixelsPerSecond * SECONDS_PER_MEASUREMENT) / 2;
        const maxMarkerHeight = 28;

        return (
            <Container width={width} height={WAVEFORM_HEIGHT}>
                {waveformData.map(({ value, index }) => {
                    const h = lerp(value, MIN_DB, MAX_DB, 0, maxMarkerHeight);
                    return (
                        <Marker
                            emotion={emotion}
                            key={index}
                            rx={1}
                            ry={1}
                            x={index * pixelsPerSecond * SECONDS_PER_MEASUREMENT}
                            y={WAVEFORM_HEIGHT - h}
                            width={markerWidth}
                            height={h}
                        />
                    );
                })}
            </Container>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.pixelsPerSecond === nextProps.pixelsPerSecond &&
            isEqual(prevProps.waveInstrumentation, nextProps.waveInstrumentation) &&
            prevProps.preview === nextProps.preview
        );
    },
);

WaveVolumeWaveform.displayName = 'WaveVolumeWaveform';
