import styled from '@emotion/styled';
import React from 'react';
import { ScheduledWavepath } from 'wavepaths-shared/core';

import { pointsToSvgPath } from '../../../pages/inSession/timeline/svgUtils';
import { getCurvePointsFromWave, TimelineWaveProperties } from '../../../pages/inSession/timeline/timelineWaveUtils';

const Svg = styled.svg`
    width: 100%;
    height: 100%;
    display: block;
    overflow: visible;
`;

const WavePath = styled.path`
    stroke: white;
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
    fill: none;
    vector-effect: non-scaling-stroke;
`;

const PIXELS_PER_MINUTE = 8;
const PIXELS_PER_MILLIS = PIXELS_PER_MINUTE / (60 * 1000);
const MAX_HEIGHT = 24;

interface MinimapWaveProps {
    wave: ScheduledWavepath;
    height: number;
}

const MinimapWave: React.FC<MinimapWaveProps> = ({ wave, height }) => {
    const baseWidth = wave.plan ? (wave.plan.toTime - wave.plan.fromTime) * PIXELS_PER_MILLIS : 0;

    const timelineWave: TimelineWaveProperties = {
        wave,
        x: 0,
        width: baseWidth,
        height: MAX_HEIGHT,
    };

    const points = getCurvePointsFromWave(timelineWave, 0);
    const { d: svgPath } = pointsToSvgPath(points);

    // Set the viewBox to use the full width of the container
    const viewBoxX = 0; // Start at 0
    const viewBoxWidth = baseWidth; // Use the full width

    return (
        <Svg
            height={height}
            width="100%"
            viewBox={`${viewBoxX} 0 ${viewBoxWidth} ${MAX_HEIGHT}`}
            preserveAspectRatio="none"
        >
            <WavePath d={svgPath} />
        </Svg>
    );
};

export default MinimapWave;
