import React, { useContext } from 'react';
import { ReactElement } from 'react';
import { CoreEmotionalAtmosphere, TickDepth } from 'wavepaths-shared/core';

import { GlobalSnackbarContext } from '@/components/Snackbar';

import { useRemoteSessionState } from '../../../common/hooks/useSessionTick';
import { RemoteSessionControlsContext } from '../Guide';
import CeaSelector from './CeaSelector';
import { useRemoteCEA } from './useCEA';

function CeaControllerContainer({ ...props }): ReactElement | null {
    const remoteSessionControlsContext = useContext(RemoteSessionControlsContext);
    if (!remoteSessionControlsContext) {
        throw new Error('no RemoteSessionControlsContext');
    }
    if (!remoteSessionControlsContext.connection) {
        throw new Error('no connection');
    }
    const ceaState = useRemoteCEA(remoteSessionControlsContext.connection, remoteSessionControlsContext.queueFunction);
    const { started } = useRemoteSessionState();

    if (ceaState === 'loading') return null;

    return <CeaController {...ceaState} {...props} sessionStarted={started} />;
}

interface ICeaController {
    targetCea?: CoreEmotionalAtmosphere;
    depth?: TickDepth;
    transitionTimeSecs?: number;
    sessionStarted: boolean;
    setTargetCea: (cea: CoreEmotionalAtmosphere, onQueueCallback?: () => void) => void;
    onClose?: () => void;
}

export const DISABLED_LABEL = 'This is disabled until you start your session';

export const DEEPEN_TRANSITION_LABEL = 'Automatically decreasing intensity as we move to ';

const CeaController = React.memo(
    ({ targetCea, setTargetCea, transitionTimeSecs, sessionStarted, onClose }: ICeaController): ReactElement => {
        const globalSnackbarContext = useContext(GlobalSnackbarContext);

        const changeCea = (cea: CoreEmotionalAtmosphere) => {
            if (!sessionStarted) {
                return globalSnackbarContext.setSnackbarContent(DISABLED_LABEL);
            }
            setTargetCea(cea);
            onClose && onClose();
        };

        return (
            <>
                <CeaSelector targetCea={targetCea} transitionTimeSecs={transitionTimeSecs} onChange={changeCea} />
            </>
        );
    },
);

export default CeaControllerContainer;
