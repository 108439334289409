import styled from '@emotion/styled';
import React from 'react';

import { getComposerName, getInstrumentName, Instrumentation } from '@/components/WavePathSelector/useWaveInstruments';

const Container = styled.div`
    width: 100%;
`;

const CurrentLayer = styled.div`
    width: 100%;
    min-height: 56px;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const InstrumentTag = styled.div<{ emotion?: string }>`
    position: relative;
    display: inline-flex;
    margin-top: 4px;
    font-size: var(--font-size-small);
    font-weight: 500;
    line-height: 1;
    color: var(--color-primary);
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${({ emotion }) =>
            emotion === 'Stillness'
                ? 'var(--color-stillness-primary)'
                : emotion === 'Vitality'
                ? 'var(--color-vitality-primary)'
                : emotion === 'Bittersweet'
                ? 'var(--color-bittersweet-primary)'
                : emotion === 'Tension'
                ? 'var(--color-tension-primary)'
                : 'var(--color-primary)'};
        z-index: -1;
    }
`;

export const EllipsisButton = styled.button<{ emotion?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    border-radius: 20px;
    border: none;
    background-color: rgba(0, 0, 0, 0.05);
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: ${({ emotion }) =>
            emotion === 'Stillness'
                ? 'var(--color-stillness-primary)'
                : emotion === 'Vitality'
                ? 'var(--color-vitality-primary)'
                : emotion === 'Bittersweet'
                ? 'var(--color-bittersweet-primary)'
                : emotion === 'Tension'
                ? 'var(--color-tension-primary)'
                : 'rgba(0, 0, 0, 0.05)'};
        z-index: -1;
    }
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    svg {
        width: 24px;
        height: 24px;
        path {
            stroke: var(--color-primary);
        }
    }
`;

const SwappableLayer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 24px;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const InstrumentLabel = styled.div`
    font-size: var(--font-size-small);
    line-height: 1;
`;

const ArtistLabel = styled.div`
    font-size: var(--font-size-small);
    color: rgba(0, 0, 0, 0.6);
    line-height: 1;
`;

const Actions = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 12px;
`;

const Ellipsis = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.99967 10.8334C10.4599 10.8334 10.833 10.4603 10.833 10.0001C10.833 9.53984 10.4599 9.16675 9.99967 9.16675C9.53944 9.16675 9.16634 9.53984 9.16634 10.0001C9.16634 10.4603 9.53944 10.8334 9.99967 10.8334Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.833 10.8334C16.2932 10.8334 16.6663 10.4603 16.6663 10.0001C16.6663 9.53984 16.2932 9.16675 15.833 9.16675C15.3728 9.16675 14.9997 9.53984 14.9997 10.0001C14.9997 10.4603 15.3728 10.8334 15.833 10.8334Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.16634 10.8334C4.62658 10.8334 4.99967 10.4603 4.99967 10.0001C4.99967 9.53984 4.62658 9.16675 4.16634 9.16675C3.7061 9.16675 3.33301 9.53984 3.33301 10.0001C3.33301 10.4603 3.7061 10.8334 4.16634 10.8334Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
interface SoundLayerProps {
    instrument: Instrumentation;
    isCurrent?: boolean;
    onSelect?: () => void;
    emotion?: string;
}

const SoundLayer: React.FC<SoundLayerProps> = ({ instrument, isCurrent, onSelect, emotion }) => {
    return (
        <Container>
            {isCurrent ? (
                <CurrentLayer>
                    <Info>
                        <InstrumentTag emotion={emotion}>{getInstrumentName(instrument)}</InstrumentTag>
                    </Info>
                    <Actions>
                        <EllipsisButton
                            emotion={emotion}
                            onClick={(e) => {
                                e.stopPropagation();
                                onSelect?.();
                            }}
                        >
                            <Ellipsis />
                        </EllipsisButton>
                    </Actions>
                </CurrentLayer>
            ) : (
                <SwappableLayer
                    onClick={(e) => {
                        e.stopPropagation();
                        onSelect?.();
                    }}
                >
                    <Info>
                        <InstrumentLabel>{getInstrumentName(instrument)}</InstrumentLabel>
                        <ArtistLabel>{getComposerName(instrument)}</ArtistLabel>
                    </Info>
                    <Actions>
                        <EllipsisButton emotion={emotion}>
                            <Ellipsis />
                        </EllipsisButton>
                    </Actions>
                </SwappableLayer>
            )}
        </Container>
    );
};

export default SoundLayer;
