import { PROLONG_RECORDING_EXPIRATION_DAYS, Session, SessionHeader } from 'wavepaths-shared/core';

import { formatDateTime } from '../../dateUtilsV2';

export function fetchExpirationInfo(session: Session | SessionHeader) {
    let isExpired = session.isDeletedRecording;
    let expirationMessage = '';
    let prolongMessage = '';
    const expirationDate = session.deleteRecordingAt ? new Date(session.deleteRecordingAt) : null;

    if (session.endedAt && session.startedAt && expirationDate) {
        const now = new Date();

        if (now > expirationDate || isExpired) {
            isExpired = true;
            expirationMessage = 'The recording of this session has expired';
        } else {
            expirationMessage = `This recording is currently set to expire on ${formatDateTime(expirationDate)}`;
            prolongMessage = `The expiry date will automatically extend by ${PROLONG_RECORDING_EXPIRATION_DAYS} days, each time it is played`;
        }
    }

    return { isExpired, expirationMessage, prolongMessage };
}
