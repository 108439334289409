import styled from '@emotion/styled';
import React from 'react';

import { SupportPostRichEditor } from './SupportPostRichEditor';
import { SupportPost } from './useSupportPosts';

const Container = styled.div`
    position: relative;
`;

const Header = styled.div`
    padding: 16px 0 var(--global-spacing) 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-top: 4px solid var(--color-background-highlight);
`;

const Tag = styled.p`
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 8px 0;
`;

type SupportPostProps = React.PropsWithChildren<{
    post: SupportPost;
    isExpanded?: boolean;
    onClick?: () => void;
}>;

export function SupportPost({ post, isExpanded = false, onClick, children }: SupportPostProps) {
    return (
        <Container>
            <Header onClick={onClick}>
                <Tag>{post.tag}</Tag>
                <h3>{post.shortDescription}</h3>
            </Header>
            {isExpanded && post.description && <SupportPostRichEditor value={post.description} isEditable={false} />}
            {children}
        </Container>
    );
}
