import styled from '@emotion/styled';
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { setSeconds } from 'date-fns';
import { capitalize } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
    AdministrationRoute,
    clientOptionType,
    ClientVariablesMusicPreference,
    CoreEmotionalAtmosphere,
    DosageLevel,
    SessionRenderType,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
} from 'wavepaths-shared/core';
import { formatDurationMinutes } from 'wavepaths-shared/util/dateUtils';

import RangeInput from '@/components/Form/RangeInput';
import DateTimeInput from '@/components/Inputs/DateTimeInput';
import { Features } from '@/features';
import { useDebouncedState } from '@/hooks';

import { useAuthContext } from '../../../auth';
import { getClients } from '../../api/bonnyApi';
import Button from './Button';
import { DropdownControl, SegmentedControl } from './Control';
import Container from './Control/Container';
import Heading from './Control/Heading';
import Dialog from './Dialog';
import EvaIcon from './EvaIcon';
import AdministrationIcon from './icons/AdministrationIcon';
import DosageIcon from './icons/DosageIcon';
import IntensityIcon from './icons/IntensityIcon';
import ModalityIcon from './icons/ModalityIcon';
import {
    clientStartingTypeOptions,
    contentStatusOptions,
    getRenderTypeLabel,
    musicalPreferenceOptions,
    renderTypeOptions,
    schedulingOptions,
    SchedulingStyle,
} from './sessionOptions';
import TemplateArtwork from './TemplateArtwork';
import TextInput from './TextInput';

export interface TemplateDetailProps {
    imageUrl?: string;
    id: string;
    title: string;
    subtitle: string;
    description: string;
    intensity: SessionScoreEmotionalIntensity;
    emotionalities?: {
        primary: CoreEmotionalAtmosphere;
        secondary: CoreEmotionalAtmosphere;
        tertiary: CoreEmotionalAtmosphere;
    };
    minDurationMins: number;
    maxDurationMins: number;
    tracklistComponent: JSX.Element | null;
    timelineComponent: JSX.Element | null;
    duration: number;
    onDurationChange: (duration: number) => void;
    renderType: SessionRenderType;
    onRenderTypeChange: (value: SessionRenderType) => void;
    sessionName: string;
    onSessionNameChange: (name: string) => void;
    contentStatuses: 'Approved' | 'Submitted' | 'All';
    onContentStatusChange: (value: 'Approved' | 'Submitted' | 'All') => void;
    musicalPreference: ClientVariablesMusicPreference;
    onMusicalPreferenceChange: (val: ClientVariablesMusicPreference) => void;
    schedulingType: SchedulingStyle;
    onSchedulingTypeChange: (val: SchedulingStyle) => void;
    canClientStartEarly: boolean;
    onCanClientStartEarlyChange: (val: boolean) => void;
    scheduledStart?: number;
    onScheduledStartChange: (val?: number) => void;
    onDelete: () => void;
    onSubmit: () => void;
    allowedToEditDelete: boolean;
    submitDisabled: boolean;
    modality: SessionScoreModality;
    administration?: AdministrationRoute;
    dosage?: DosageLevel;
    canSaveTemplates?: boolean;
    onCreateTemplate: () => void;
    onEditTemplate: () => void;
    showAdminFeatures: boolean;
    selectedClients: clientOptionType[];
    setSelectedClients: (value: clientOptionType[]) => void;
}

const TemplateDetailContainer = styled.div`
    display: grid;
    grid-template-areas: 'header' 'info' 'actions';
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    gap: 24px;

    @media (min-width: 1260px) {
        grid-template-areas: 'header header' 'info actions';
        grid-template-columns: 1fr 320px;
        grid-template-rows: repeat(3, min-content);
        gap: 24px 48px;
    }
`;

const Header = styled.div`
    grid-area: header;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    margin-bottom: 28px;
`;

const Artwork = styled.div`
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 6px;
    background: #f9fafb; // Grey 50
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.15s ease;
`;

const TitleInfo = styled.div`
    display: grid;
    gap: 8px;
    p {
        font-size: var(--font-size-small);
        color: var(--color-muted);
    }
`;

const Meta = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px 16px;
    padding: 8px 16px;
    border-top: 2px solid #ecebe9;
    border-bottom: 2px solid #ecebe9;
`;

const MetaIcon = styled.div``;

const MetaItem = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: var(--font-size-small);
    color: var(--color-primary);
`;

const ShowDescriptionButton = styled.span`
    display: inline-block;
    padding-left: 0.5em;
    text-decoration: underline;
    cursor: pointer;
`;

const Timeline = styled.div`
    border-radius: 4px;
    background: #ffffff;
    border-radius: 4px;
    padding: 8px;
`;

const TimelineContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--color-background);
`;

const TrackList = styled.div``;

const Actions = styled.div`
    grid-area: actions;
    display: grid;
    align-items: start;
    gap: 16px;
    z-index: 20;
`;

const StickyActions = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    gap: 16px;
    flex-direction: column;
`;

const Music = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
`;

const Info = styled.div`
    grid-area: info;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
`;

const DurationInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    font-size: var(--font-size-small);
    font-weight: 500;
`;

const DurationLabel = styled.label``;

const DurationBoundsContainer = styled.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
});

const DurationBoundsLabel = styled.div({
    display: 'flex',
});

const SAVE_AS_SESSION_TEMPLATE_LABEL = 'Save as a new Template';

const TemplateDetail: React.FC<TemplateDetailProps> = ({
    id,
    emotionalities,
    title,
    subtitle,
    description,
    intensity,
    minDurationMins,
    maxDurationMins,
    tracklistComponent,
    timelineComponent,
    duration,
    onDurationChange,
    renderType,
    onRenderTypeChange,
    onSessionNameChange,
    sessionName,
    contentStatuses,
    onContentStatusChange,
    musicalPreference,
    onMusicalPreferenceChange,
    schedulingType,
    onSchedulingTypeChange,
    canClientStartEarly,
    onCanClientStartEarlyChange,
    scheduledStart,
    onScheduledStartChange,
    onDelete,
    allowedToEditDelete,
    onSubmit,
    submitDisabled,
    dosage,
    modality,
    administration,
    onCreateTemplate,
    onEditTemplate,
    canSaveTemplates,
    showAdminFeatures,
    setSelectedClients,
    selectedClients,
}) => {
    const minRounded = Math.floor(minDurationMins);
    const maxRounded = Math.ceil(maxDurationMins);
    const [innerDuration, setInnerDuration] = React.useState<number>(duration);

    const { isPersonal, firebaseUser, isEnabled } = useAuthContext();
    const renderTypeAllowed = [
        ...(!isPersonal ? [SessionRenderType.PREDICTIVE_COMPOSED] : []),
        SessionRenderType.PRE_RENDERED,
    ];
    const renderOptions = renderTypeOptions.filter((x) => renderTypeAllowed.includes(x));
    useEffect(() => {
        if (!renderOptions.find((x) => x === renderType)) {
            if (renderOptions.length < 1) throw new Error('no options');
            onRenderTypeChange(renderOptions[0]);
        }
    }, [renderOptions.join(','), renderType]);
    if (firebaseUser === undefined) throw new Error('no firebaseUser');

    useEffect(() => {
        if (duration < minRounded) {
            handleDurationChange(minRounded);
        } else if (duration > maxRounded) {
            handleDurationChange(maxRounded);
        }
    }, [minRounded, maxRounded, duration]);

    const sliderCoolDownPeriodInMilliseconds = 200;
    const sliderTouchTimeout = useRef<any>();
    function handleDurationChange(newValue: number) {
        setInnerDuration(newValue);

        if (sliderTouchTimeout.current) {
            clearTimeout(sliderTouchTimeout.current);
        }

        sliderTouchTimeout.current = setTimeout(() => {
            onDurationChange(newValue);
        }, sliderCoolDownPeriodInMilliseconds);
    }

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [viewMoreOptions, setViewMore] = useState(false);

    const [clientQuery, setClientQuery] = useDebouncedState<string>('');
    const [clientOptions, setClientOptions] = useState<clientOptionType[]>([]);
    const [loadingClientOptions, setLoadingClientOptions] = useState<boolean>(false);
    useEffect(() => {
        setClientOptions([]);
        setLoadingClientOptions(true);
        (async () => {
            const clientsList = await getClients(firebaseUser, clientQuery);
            const firstSpaceIndex = clientQuery.indexOf(' ');
            const firstname = firstSpaceIndex > -1 ? clientQuery.substring(0, firstSpaceIndex) : clientQuery;
            const lastname = firstSpaceIndex > -1 ? clientQuery.substring(firstSpaceIndex + 1) : '';
            const effectiveSelectionOptions: clientOptionType[] = [
                ...clientsList,
                ...(clientQuery.length > 0
                    ? [
                          {
                              client_id: 'new',
                              firstname: firstname,
                              lastname: lastname,
                          },
                      ]
                    : []),
            ];
            setClientOptions(effectiveSelectionOptions);
            setLoadingClientOptions(false);
        })();
    }, [clientQuery]);

    const TemplateDescription: React.FC<{ description: string }> = ({ description }) => {
        const [showFullDescription, setShowFullDescription] = useState(false);
        const toggleDescription = () => {
            setShowFullDescription(!showFullDescription);
        };

        const firstSentenceEnd = description.indexOf('.') + 1;
        const truncatedDescription = description.substring(0, firstSentenceEnd);

        return (
            <>
                <p>
                    {showFullDescription ? description : truncatedDescription}
                    {description.length > truncatedDescription.length && (
                        <ShowDescriptionButton onClick={toggleDescription}>
                            {showFullDescription ? ' Less…' : ' More…'}
                        </ShowDescriptionButton>
                    )}
                </p>
            </>
        );
    };

    return (
        <TemplateDetailContainer>
            <Header>
                <Artwork>
                    <TemplateArtwork
                        randomSeed={id}
                        intensity={intensity}
                        primaryEmotion={emotionalities?.primary ?? CoreEmotionalAtmosphere.SILENCE}
                        secondaryEmotion={emotionalities?.secondary ?? CoreEmotionalAtmosphere.SILENCE}
                        tertiaryEmotion={emotionalities?.tertiary ?? CoreEmotionalAtmosphere.SILENCE}
                    />
                </Artwork>
                <TitleInfo>
                    <h2>{title}</h2>
                    <p>{subtitle}</p>
                </TitleInfo>
            </Header>
            <Info>
                <Meta>
                    <MetaItem>
                        <MetaIcon>
                            <IntensityIcon intensity={intensity} />
                        </MetaIcon>
                        {intensity} intensity
                    </MetaItem>
                    <MetaItem>
                        <MetaIcon>
                            <EvaIcon name="clock-outline" size={16} iconStyle={{ fill: '#667085' }} />
                        </MetaIcon>
                        {formatDurationMinutes(minDurationMins * 60 * 1000)} -{' '}
                        {formatDurationMinutes(maxDurationMins * 60 * 1000)}
                    </MetaItem>
                    {modality && (
                        <MetaItem>
                            <MetaIcon>
                                <ModalityIcon modality={modality} />
                            </MetaIcon>
                            {modality}
                        </MetaItem>
                    )}
                    {administration && (
                        <MetaItem>
                            <MetaIcon>
                                <AdministrationIcon administration={administration} />
                            </MetaIcon>
                            {capitalize(administration)}
                        </MetaItem>
                    )}
                    {dosage && (
                        <MetaItem>
                            <MetaIcon>
                                <DosageIcon dosage={dosage} />
                            </MetaIcon>
                            {capitalize(dosage.replace('_', ' '))} dose
                        </MetaItem>
                    )}
                </Meta>
                <TemplateDescription description={description} />
                <Music>
                    {timelineComponent && (
                        <TimelineContainer>
                            <Timeline>{timelineComponent}</Timeline>
                        </TimelineContainer>
                    )}
                    <TrackList>{tracklistComponent}</TrackList>
                </Music>
            </Info>
            <Actions>
                <StickyActions>
                    <DurationInputWrapper>
                        <DurationLabel>
                            Session Duration: {formatDurationMinutes(innerDuration * 60 * 1000)}
                        </DurationLabel>
                        <RangeInput
                            min={minRounded}
                            max={maxRounded}
                            step={1}
                            value={innerDuration}
                            onChange={(event: any) => handleDurationChange(+event.target.value)}
                        />
                        <DurationBoundsContainer>
                            <DurationBoundsLabel>{formatDurationMinutes(minRounded * 60 * 1000)}</DurationBoundsLabel>
                            <DurationBoundsLabel>{formatDurationMinutes(maxRounded * 60 * 1000)}</DurationBoundsLabel>
                        </DurationBoundsContainer>
                    </DurationInputWrapper>
                    {isPersonal && !isEnabled(Features.LIVE_SESSION_RENDERING) ? (
                        <></>
                    ) : (
                        <DropdownControl
                            canSave={false}
                            size={'large'}
                            heading="Session Type"
                            name="Session Type"
                            colour={'dark'}
                            info={sessionTypeInfo}
                            options={renderOptions.map((x) => ({ label: getRenderTypeLabel(x), value: x }))}
                            value={renderType}
                            onChange={onRenderTypeChange}
                        />
                    )}
                    <TextInput
                        variant="outlined"
                        size="small"
                        name="session_name"
                        heading="Session Name"
                        placeholder={title}
                        onChange={(e) => onSessionNameChange(e.target.value)}
                        value={sessionName}
                    />
                    {!isPersonal ? (
                        <Container inline={false}>
                            <Heading
                                id={'clients-label'}
                                heading={'Client(s)'}
                                info={undefined}
                                canSave={false}
                                inline={false}
                                colour={'dark'}
                            ></Heading>
                            <Autocomplete
                                multiple
                                id="clients-selection"
                                options={clientOptions}
                                defaultValue={selectedClients}
                                loading={loadingClientOptions}
                                renderOption={(option) => {
                                    return (
                                        <div>
                                            {option.client_id === 'new' ? 'New: ' : ''}
                                            {option.firstname} {option.lastname}
                                        </div>
                                    );
                                }}
                                filterOptions={(x) => x}
                                getOptionLabel={(option) => option.client_id}
                                getOptionSelected={(option, value) =>
                                    option.client_id === value.client_id && option.firstname === value.firstname
                                }
                                freeSolo
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            variant="outlined"
                                            label={`${option.firstname} ${option.lastname}`}
                                            {...getTagProps({ index })}
                                        />
                                    ));
                                }}
                                onChange={(_event, value) => {
                                    const strongValue = value as typeof clientOptions;
                                    setSelectedClients(strongValue);
                                }}
                                onInputChange={(_event, value) => {
                                    setClientQuery(value);
                                }}
                                renderInput={(params) => {
                                    return <TextField {...params} variant="standard" placeholder="Select or add new" />;
                                }}
                            />
                        </Container>
                    ) : (
                        <></>
                    )}
                    <Button variant="clear-underlined" onClick={() => setViewMore(!viewMoreOptions)}>
                        {viewMoreOptions ? 'Fewer' : 'More'} options
                    </Button>
                    {viewMoreOptions ? (
                        <>
                            <SegmentedControl
                                canSave={false}
                                size={'large'}
                                heading="Musical Preference"
                                name="Musical Preference"
                                colour={'dark'}
                                info={musicalPreferenceInfo}
                                options={musicalPreferenceOptions}
                                value={musicalPreference}
                                onChange={onMusicalPreferenceChange}
                            />
                            <DropdownControl
                                canSave={false}
                                size={'large'}
                                heading="When is this session for?"
                                name="scheduling"
                                colour={'dark'}
                                info={scheduleTypeInfo}
                                options={schedulingOptions}
                                value={schedulingType}
                                onChange={onSchedulingTypeChange}
                            />

                            {schedulingType === 'specificTime' && (
                                <DateTimeInput
                                    className="scheduled-start-picker"
                                    value={scheduledStart && setSeconds(new Date(scheduledStart), 0)}
                                    disabled={schedulingType !== 'specificTime'}
                                    onChange={(d: Date) => onScheduledStartChange(d ? d.getTime() : undefined)}
                                    clearIcon={null}
                                />
                            )}
                            <DropdownControl
                                canSave={false}
                                size={'large'}
                                heading="Who is able to control the playback?"
                                name="starting"
                                info={clientControlInfo}
                                colour={'dark'}
                                options={clientStartingTypeOptions}
                                value={canClientStartEarly ? 1 : 0} // thanks MUI
                                onChange={(val) => onCanClientStartEarlyChange(!!val)}
                            />
                            {showAdminFeatures ? (
                                <>
                                    <SegmentedControl
                                        canSave={false}
                                        size={'large'}
                                        heading="Statuses to include"
                                        colour={'dark'}
                                        options={contentStatusOptions}
                                        value={contentStatuses}
                                        onChange={onContentStatusChange}
                                    />
                                </>
                            ) : null}
                        </>
                    ) : null}
                    <Button disabled={submitDisabled} variant="solid-blue" size="m" onClick={onSubmit}>
                        {schedulingType !== 'now' ? 'Schedule Session' : 'Start Session'}
                    </Button>
                    {allowedToEditDelete ? (
                        <>
                            <Button
                                disabled={submitDisabled}
                                variant="outlined"
                                size="m"
                                style={{ opacity: canSaveTemplates ? 1 : 0.5 }}
                                icon={
                                    canSaveTemplates ? (
                                        <EvaIcon name="save-outline" size={16} fill={'#000'} />
                                    ) : (
                                        <EvaIcon name="lock-outline" size={16} fill={'#000'} />
                                    )
                                }
                                onClick={onEditTemplate}
                            >
                                Save
                            </Button>
                        </>
                    ) : null}

                    <Button
                        disabled={submitDisabled}
                        variant="outlined"
                        size="m"
                        style={{ opacity: 1 }}
                        icon={<EvaIcon name="save-outline" size={16} fill={'#000'} />}
                        onClick={onCreateTemplate}
                    >
                        {SAVE_AS_SESSION_TEMPLATE_LABEL}
                    </Button>

                    {allowedToEditDelete ? (
                        <>
                            <Button
                                disabled={submitDisabled}
                                variant="outlined"
                                size="m"
                                icon={<EvaIcon name="trash-outline" size={16} fill={'#000'} />}
                                onClick={() => setShowConfirmDelete(true)}
                            >
                                Delete
                            </Button>

                            <Dialog
                                fullWidth={true}
                                open={showConfirmDelete}
                                message={'Are you sure you want to delete this template?'}
                                onClose={() => setShowConfirmDelete(false)}
                                onConfirm={onDelete}
                                confirmText={'Yes, DELETE'}
                            />
                        </>
                    ) : null}
                </StickyActions>
            </Actions>
        </TemplateDetailContainer>
    );
};

export default TemplateDetail;

const sessionTypeInfo = {
    title: 'Session Type',
    content: (
        <>
            <p>
                <strong>Live (Real-time)</strong> will create all music on the fly and allows you to adapt various music
                variables at any time.
            </p>
            <p>
                <strong>Pre-recorded (Playback-only / Offline)</strong> will render music for the entire session
                immediately, and has reduced music adaptation controls. Recommended for users with slow or variable
                internet connections, or for those who wish to run the entire session offline.
            </p>
        </>
    ),
};

const musicalPreferenceInfo = {
    title: 'Musical Preference',
    content: (
        <>
            <p>Here you can select the dominant tone colour (or “timbre”) of the music created for your session.</p>
            <p>
                <strong>Acoustic</strong> will prioritise instruments such as wind, brass and strings, tuned percussion,
                as well as human voice.
            </p>
            <p>
                <strong>Electronic</strong> will prioritise electronic instruments such as synthesizers.
            </p>
            <p>
                <strong>Mixed acoustic/electronic</strong> includes both of the above, as well as acoustic instruments
                that have been processed electronically.
            </p>
        </>
    ),
};

const scheduleTypeInfo = {
    title: 'When is this session for',
    content: (
        <>
            <p>
                <strong>Now</strong> will start the system in prelude mode which plays low intensity Stillness music for
                up to four hours. When in prelude mode you can start the session at any time.
            </p>
            <p>
                <strong>Save for later</strong> will add the session to the ‘scheduled for later’ tab in your sessions
                dashboard.
            </p>
            <p>
                <strong>A specific time</strong> will allow you to set a date and time for this session to start
                automatically.
            </p>
        </>
    ),
};

const clientControlInfo = {
    title: 'Who is able to control the playback?',
    content: (
        <>
            <p>
                This determines whether the Client is able to start the session from the remote streaming link, or
                whether the session can only be started from within the Care-Providers control tool.
            </p>
        </>
    ),
};
