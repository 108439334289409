import styled from '@emotion/styled';
import { last, negate } from 'lodash';
import React from 'react';
import {
    CoreEmotionalAtmosphere,
    isPrePostLudePathScore,
    isScheduledWavepath,
    PathScore,
    SessionScoreEmotionalIntensity,
    Wavepath,
    WavepathType,
} from 'wavepaths-shared/core';

import { EvaIcon } from '@/component-library';
import { toggleCssScrollbar } from '@/components/StyledSrollbar';
import useScoreLibrary from '@/hooks/useScoreLibrary';

import { useAuthContext } from '../../../auth';
import { selectNoWave, WaveSelection } from '../../../pages/inSession/autoGuide/waveSelection';
import WaveCard from './WaveCard';
import { IconButton } from './WaveCardTopBar';
import WaveQueueEditorTrackingHandlers from './WaveQueueEditorTrackingHandlers';

export type IWaveQueueEditorProps = {
    shouldShow: boolean;
    shouldDisplayHeader?: boolean;
    shouldAllowAudioPreviews: boolean;
    currentWaveIndex: number;
    wavepaths: Wavepath[];
    waveSelection: WaveSelection;
    setWaveSelection: (selection: WaveSelection, force?: boolean) => void;
    skipToWave?: (index: number, showQueuingUIInline?: boolean | undefined) => void;
    updatePathAtIndex: (
        index: number,
        wp: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration'>,
        showQueuingUIInline?: boolean | undefined,
    ) => void;
    addPathAtIndex: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration'>) => void;
    removePathAtIndex: (index: number, name?: string) => void;
    movePathToIndex: (index: number, targetIndex: number) => void;
    setSnackbarContent: (content: string) => void;
    trackingHandlers: WaveQueueEditorTrackingHandlers;
};

const doesPathMatchEmotion = (emotionalAtmosphere: CoreEmotionalAtmosphere) => (pathScore: PathScore) => {
    return 'emotion' in pathScore ? pathScore.emotion === emotionalAtmosphere : false;
};

const doesPathMatchIntensity = (emotionalIntensity: SessionScoreEmotionalIntensity) => (pathScore: PathScore) => {
    return pathScore?.selectionCriteria?.emotionalIntensity === emotionalIntensity;
};

export const DEFAULT_WAVE_TO_ADD_ID = 's_cea1_a';
export const NEXT_UP_HEADER_TEXT = 'Next up';

const Container = styled.div<{ shouldShow?: boolean; scrollingEnabled: boolean }>(
    ({ shouldShow, scrollingEnabled }) => ({
        overflowY: 'scroll',
        position: 'relative',
        scrollbarGutter: 'stable',
        paddingTop: '25px',
        opacity: shouldShow ? 1 : 0,
        height: shouldShow ? '88%' : 0,
        transition: 'opacity 0.15s ease',
        margin: '0 auto',
        width: '100%',
        ...toggleCssScrollbar(scrollingEnabled),
    }),
);

const AddButton = styled(IconButton)`
    position: absolute;
    top: -23px;
    left: 50%;
    z-index: 1;
    background-color: white;

    &:hover {
        background-color: white;
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

export const ADD_WAVE_LABEL = 'Add';

const WaveQueueEditor = ({
    shouldShow,
    currentWaveIndex,
    wavepaths,
    waveSelection,
    setWaveSelection,
    skipToWave,
    updatePathAtIndex,
    removePathAtIndex,
    movePathToIndex,
    addPathAtIndex,
    setSnackbarContent,
    trackingHandlers,
    shouldDisplayHeader,
    shouldAllowAudioPreviews,
}: IWaveQueueEditorProps): JSX.Element | null => {
    const { firebaseUser } = useAuthContext();
    const scorelibrary = useScoreLibrary(firebaseUser);

    const pathScores = scorelibrary.loading === false ? scorelibrary.pathScores : [];

    const defaultPathScoreToAdd: PathScore =
        pathScores.find((pathScore: PathScore) => pathScore.id === DEFAULT_WAVE_TO_ADD_ID) ??
        pathScores
            .filter(negate(isPrePostLudePathScore))
            .filter(doesPathMatchIntensity(SessionScoreEmotionalIntensity.LOW))
            .filter(doesPathMatchEmotion(CoreEmotionalAtmosphere.STILLNESS))[0];

    const scheduledWavepaths = wavepaths.filter(isScheduledWavepath);

    const onUpdateWave = (index: number, oldWave: Wavepath) => (newWave: Wavepath) => {
        const showQueuingUIInline = true;

        updatePathAtIndex(index, newWave, showQueuingUIInline);

        trackingHandlers.updateWave({ index, newWave, oldWave, noOfWaves: wavepaths.length });
    };

    const onAddWave = (index: number) => {
        addPathAtIndex(index, {
            pathId: defaultPathScoreToAdd.id,
            pathScore: defaultPathScoreToAdd,
        });
        trackingHandlers.onAddWave({ index, noOfWaves: wavepaths.length });
        setWaveSelection(selectNoWave());
    };

    const onSkipToWave = (skipToIndex: number, currentWaveIndex: number) =>
        skipToWave
            ? () => {
                  const showQueuingUIInline = false;
                  skipToWave(skipToIndex, showQueuingUIInline);
                  trackingHandlers.skipToWave({ skipToIndex, currentWaveIndex, wavepaths });
                  setWaveSelection(selectNoWave());
              }
            : undefined;

    const onRemoveWave = (index: number, wave: Wavepath) => (event: React.UIEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        removePathAtIndex(index, wave.pathScore.name);
        trackingHandlers.removeWave({ index, wave, noOfWaves: wavepaths.length });
    };

    const onMoveWaveUp = (index: number, currentWaveIndex: number, wave: Wavepath) => (
        event: React.UIEvent<HTMLButtonElement>,
    ) => {
        event.stopPropagation();
        if (index > currentWaveIndex + 1) {
            movePathToIndex(index, index - 1);
            trackingHandlers.moveWaveUp({ index, wave, noOfWaves: wavepaths.length });
        } else {
            setSnackbarContent('You can’t move this wave any further up the list');
            trackingHandlers.waveCannotBeMovedFurtherUp({ index, wave, noOfWaves: wavepaths.length });
        }
    };

    const onMoveWaveDown = (index: number, wave: Wavepath) => (event: React.UIEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        if (index < scheduledWavepaths.length) {
            movePathToIndex(index, index + 1);
            trackingHandlers.moveWaveDown({ index, wave, noOfWaves: wavepaths.length });
        } else {
            setSnackbarContent('You can’t move this wave any further down the list');
            trackingHandlers.waveCannotBeMovedFurtherDown({ index, wave, noOfWaves: wavepaths.length });
        }
    };

    const wavepathsToShow = wavepaths
        .filter((x, index) => index > currentWaveIndex && !isPrePostLudePathScore(x.pathScore))
        .map((x) => {
            return { wavepath: x, index: wavepaths.indexOf(x) };
        });
    const lastScheduledWave = last(wavepaths.filter((x) => x.type === WavepathType.SCHEDULED));
    const lastScheduledWaveIndex = lastScheduledWave !== undefined ? wavepaths.indexOf(lastScheduledWave) : -1;

    return (
        <Container className={'tour-waveQueue'} shouldShow={shouldShow} scrollingEnabled={shouldDisplayHeader!}>
            {wavepathsToShow.map(({ wavepath, index }) => (
                <React.Fragment key={`${wavepath.id}-card`}>
                    <AddButtonContainer>
                        <AddButton
                            size="s"
                            onClick={() => {
                                onAddWave(index);
                            }}
                            variant="outlined"
                            aria-label={ADD_WAVE_LABEL}
                        >
                            <EvaIcon name="plus-outline" size={16} fill="rgb(44, 57, 88)" />
                        </AddButton>
                    </AddButtonContainer>
                    <WaveCard
                        key={`${wavepath.id}-card`}
                        index={index}
                        wave={wavepath}
                        previousWave={index > 0 ? wavepaths[index - 1] : undefined}
                        waveSelection={waveSelection}
                        shouldAllowAudioPreviews={shouldAllowAudioPreviews}
                        onUpdateWave={onUpdateWave(index, wavepath)}
                        onSkipToWave={onSkipToWave ? onSkipToWave(index, currentWaveIndex) : undefined}
                        onRemoveWave={onRemoveWave(index, wavepath)}
                        onMoveWaveUp={onMoveWaveUp(index, currentWaveIndex, wavepath)}
                        onMoveWaveDown={onMoveWaveDown(index, wavepath)}
                        onSetWaveSelection={setWaveSelection}
                    />
                    {wavepaths.find((x, index) => index > index && x.type === WavepathType.SCHEDULED) === undefined && (
                        <AddButtonContainer>
                            <AddButton
                                size="s"
                                onClick={() => {
                                    onAddWave(index + 1);
                                }}
                                variant="outlined"
                                aria-label={ADD_WAVE_LABEL}
                            >
                                <EvaIcon name="plus-outline" size={16} fill="rgb(44, 57, 88)" />
                            </AddButton>
                        </AddButtonContainer>
                    )}
                </React.Fragment>
            ))}

            {wavepathsToShow.length === 0 && (
                <AddButtonContainer>
                    <AddButton
                        size="s"
                        onClick={() => {
                            onAddWave(lastScheduledWaveIndex + 1);
                        }}
                        variant="outlined"
                        aria-label={ADD_WAVE_LABEL}
                    >
                        <EvaIcon name="plus-outline" size={16} fill="rgb(44, 57, 88)" />
                    </AddButton>
                </AddButtonContainer>
            )}
        </Container>
    );
};

export default React.memo(WaveQueueEditor);
WaveQueueEditor.displayName = 'WaveQueueEditor';
