import styled from '@emotion/styled';
import firebase from 'firebase/app';
import { sortedIndexBy, startCase, toPairs } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Session, SessionLog as SessionLogModel, TimestampedSessionEvent } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';

import * as api from '../../common/api/sessionApi';
import { formatDateTime } from '../../dateUtilsV2';
import { SessionLogEvent } from './SessionLogEvent';
import { SessionLogLayerTable } from './SessionLogLayerTable';
import { getCEAAtIndex, getCEAColourAtIndex, getPathNameAtIndex, getRelativeTime } from './sessionLogUtils';

interface SessionLogParams {
    sessionId: string;
}
export const SessionLog: React.FC<{ firebaseUser: firebase.User }> = ({ firebaseUser }) => {
    const { sessionId } = useParams<SessionLogParams>();
    const [log, setLog] = useState<SessionLogModel>();
    const [session, setSession] = useState<Session>();

    useEffect(() => {
        api.getSession(sessionId, firebaseUser)
            .then(async (session) => {
                setSession(session);
            })
            .catch(() => console.error('Could not find recording stream', sessionId));
        api.getLog(sessionId, firebaseUser).then(setLog);
    }, [sessionId, firebaseUser]);

    const [currentTime] = useState(-1);

    if (!log || !session) {
        return <></>;
    }

    const latestEventIndex = sortedIndexBy(
        log.log,
        { dspTimeMs: getEventTime(log.log[0]) + currentTime * 1000 } as any,
        getEventTime,
    );

    const sessionMeta = [
        ['Template', session.score.name],
        ['Broadcast Identifier', session.broadcastIdentifier],
        ['Started at', session.startedAt ? new Date(session.startedAt).toISOString() : '-'],
        ['Ended at', session.endedAt ? new Date(session.endedAt).toISOString() : '-'],
        ['User', `${log.user?.name} <${log.user?.email}>`],
        ['Type', session.type],
        ['Unlisted', session.unlisted ? 'Yes' : 'No'],
    ];

    const isPastEvent = (index: number) => {
        const idxToCheck = Math.min(index + 1, log.log.length - 1);
        const relTime = getRelativeTime(log.log[idxToCheck], log.log);
        return relTime < currentTime * 1000;
    };

    return (
        <Container>
            <Header>
                <Typography variant="h3">
                    {log.user?.name} &lt;{log.user?.email}&gt; {formatDateTime(log.startedAt)} -{' '}
                    {session.endedAt ? formatDateTime(session.endedAt) : 'Ongoing'}
                </Typography>
            </Header>
            <Main>
                {log.log.map((_, idx) => (
                    <GenericErrorBoundary key={idx}>
                        <SessionLogEvent
                            key={idx}
                            log={log.log}
                            index={idx}
                            isPastEvent={isPastEvent(idx)}
                            score={log.sessionScore}
                        />
                    </GenericErrorBoundary>
                ))}
            </Main>
            <Sidebar>
                {currentTime > 0 && (
                    <React.Fragment>
                        <Typography variant="h4">Currently</Typography>
                        <MetadataTable>
                            <Typography variant="body3">Wave</Typography>
                            <Typography variant="body3">{getPathNameAtIndex(latestEventIndex, log.log)}</Typography>
                            <Typography variant="body3">CEA</Typography>
                            <Typography
                                variant="body3"
                                style={{ color: getCEAColourAtIndex(latestEventIndex, log.log) }}
                            >
                                {getCEAAtIndex(latestEventIndex, log.log)}
                            </Typography>
                        </MetadataTable>
                        <StyledSessionLogLayerTable log={log.log} atEventIndex={latestEventIndex} condensed />
                    </React.Fragment>
                )}

                <Typography variant="h4">Session</Typography>
                <MetadataTable>
                    {[...sessionMeta, ...toPairs(log.variableInputs)].map(([key, val]) => (
                        <React.Fragment key={key}>
                            <Typography variant="body3">{startCase(key)}</Typography>
                            <Typography variant="body3">{val}</Typography>
                        </React.Fragment>
                    ))}
                    <Typography variant="body3">Session Runner Logs</Typography>
                    <Typography variant="body3">
                        <a href={getGCPLogURL(session)} target="_blank" rel="noopener noreferrer nofollow">
                            Here
                        </a>
                    </Typography>
                </MetadataTable>
            </Sidebar>
        </Container>
    );
};

function getEventTime(event: TimestampedSessionEvent) {
    return event.dspTimeMs ?? event.timestamp;
}

const Container = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 'header header' 'main sidebar';
    gap: 2px;
    overflow: hidden;
    background-color: #eee;
`;

const Header = styled.header`
    grid-area: header;
    padding: 16px;
    background-color: white;
`;

const Main = styled.div`
    grid-area: main;
    overflow: scroll;
    padding: 16px;
    background-color: white;
`;

const Sidebar = styled.div`
    grid-area: sidebar;
    overflow: scroll;
    background-color: white;
    padding: 16px;
`;

const MetadataTable = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: min-content;
    justify-content: space-between;
    gap: 4px;
    margin: 16px 0;
`;

const StyledSessionLogLayerTable = styled(SessionLogLayerTable)`
    padding-bottom: 16px;
`;

function getGCPLogURL(session: Session) {
    //TODO do not use Browser clock
    const start = session.startedAt ? new Date(session.startedAt) : new Date();
    const timeStart = start.toISOString();
    const timeEnd = session.endedAt
        ? new Date(session.endedAt).toISOString()
        : new Date(start.getTime() + 60 * 60 * 1000).toISOString();
    return `https://console.cloud.google.com/logs/query;query=${session.id};timeRange=${timeStart}%2F${timeEnd}?project=wavepaths`;
}

export async function awaitStream(streamUrl: string, maxTries = 50): Promise<string> {
    let triesLeft = maxTries,
        networkError;
    while (triesLeft-- > 0) {
        try {
            const res = await fetch(streamUrl, { method: 'HEAD' });
            if (res.status === 200) {
                return streamUrl;
            }
        } catch (e) {
            networkError = e;
        }
        await new Promise((res) => setTimeout(res, 2000));
    }
    throw networkError || new Error(`Stream ${streamUrl} does not seem to exist`);
}
