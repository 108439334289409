import { isEqual, isNumber, omit, sortBy } from 'lodash';
import React, { createContext, memo, useEffect, useMemo } from 'react';
import { BroadcastPersistentState, SessionRenderType, VoiceoverStage } from 'wavepaths-shared/core';

import configs from '../../configs';
import { useHLSAudioPlayer } from './useHLSAudioPlayer';
import { LONG_TIME_FROM_NOW, usePlayerLibAudioPlayer } from './usePlayerLibAudioPlayer';

type PlayerStatus = 'idle' | 'paused' | 'playing' | 'error' | 'loading';
type AudioState = 'init' | 'blocked' | 'active';

export enum AudioPlayerStreamPhase {
    Pre = 1,
    Session = 2,
    Post = 3,
}

export interface AudioPlayerStream {
    id: string;
    phase: AudioPlayerStreamPhase;
    url: string;
    fromTime: number;
    toTime: number;
    fadeOutTime: number;
    loopContent: boolean;
    gainOffset: number;
}
export interface AudioPlayerProps {
    outputDevice: string | undefined;
    streams: AudioPlayerStream[];
    broadcastElapsedTimeSecs: number;
    sessionDuration: number;
    voiceOverStages: VoiceoverStage[];
    playDemoVO?: boolean;
}
export type LegacyAudioPlayerProps = Omit<AudioPlayerProps, 'streams'> & {
    broadcastIdentifier: string;
    broadcastState: BroadcastPersistentState;
    renderType: SessionRenderType;
    mode: 'recording' | 'live';
    errorContext: string;
};

export interface AudioPlayer {
    type: 'hls' | 'playerlib' | 'null';
    actions: {
        play: (props?: { fadeMs?: number; loop?: boolean; offsetSeconds?: number }) => void;
        playPrelude: () => void;
        playPostlude: () => void;
        pause: (props: { fadeMs?: number; reason: string }) => void;
        setTime: (seekToTimeSecs: number) => void;
        unblock: () => Promise<void>;
        setVolume: (vol: number) => void;
        end: () => void;
    };
    audioStatus: AudioState;
    playerStatus: PlayerStatus;
    generalPlayerStatus: 'paused' | 'playing';
    warning: string | null;
    getCurrentTimeSecs: () => number;
    getCurrentBufferSizeSecs: () => number | undefined;
    volume: number;
    isVolumeControllable: boolean;
}

export const NULL_AUDIO_PLAYER: AudioPlayer = {
    type: 'null',
    actions: {
        play: () => {
            console.log('null play');
        },
        playPrelude: () => {
            console.log('null playPrelude');
        },
        playPostlude: () => {
            console.log('null playPostlude');
        },
        pause: () => {
            console.log('null pause');
        },
        setTime: () => {
            console.log('null setTime');
        },
        unblock: () => Promise.resolve(),
        setVolume: () => {
            console.log('null setVolume');
        },
        end: () => {
            console.log('null end');
        },
    },
    audioStatus: 'init',
    playerStatus: 'idle',
    generalPlayerStatus: 'paused',
    warning: null,
    getCurrentTimeSecs: () => 0,
    getCurrentBufferSizeSecs: () => undefined,
    volume: 0,
    isVolumeControllable: false,
};

const HLSAudioPlayer = ({
    playerProps,
    onPlayerChange,
}: {
    playerProps: LegacyAudioPlayerProps;
    onPlayerChange: (player: AudioPlayer) => void;
}) => {
    const player = useHLSAudioPlayer(playerProps);
    useEffect(() => {
        onPlayerChange({ type: 'hls', ...player });
    }, [player, onPlayerChange]);
    return null;
};

export const PlayerLibAudioPlayer = memo(
    ({
        playerProps,
        onPlayerChange,
    }: {
        playerProps: AudioPlayerProps;
        onPlayerChange: (player: AudioPlayer) => void;
    }) => {
        const player = usePlayerLibAudioPlayer(playerProps);
        useEffect(() => {
            onPlayerChange(player);
        }, [player, onPlayerChange]);
        return null;
    },
    isEqual,
);

export const AudioPlayerContext = createContext<AudioPlayer | undefined>(undefined);

// Working around react big time here, but should be short-lived code
// only for the duration of feature-flagging between the two audio players.

export type AudioPlayerTypeResolution = 'hls' | 'playerlib';

export const AudioPlayerMaker = memo<{
    playerTypeResolution?: AudioPlayerTypeResolution;
    playerProps: LegacyAudioPlayerProps;
    setAudioPlayer: (p: AudioPlayer) => void;
}>(({ playerProps, setAudioPlayer, playerTypeResolution = 'playerlib' }) => {
    const playerlibEnabled = playerTypeResolution === 'playerlib';

    const streams = useMemo(() => {
        const timelineInOrder = sortBy(playerProps.broadcastState.timeline, 'dspOffset');

        const getFadeOutTime = (index: number) => {
            if (index === timelineInOrder.length - 1 || !isNumber(timelineInOrder[index + 1].crossfadeTime)) {
                return 15 * 1000;
            }
            return timelineInOrder[index + 1].crossfadeTime;
        };

        const getToTime = (index: number) => {
            if (index === timelineInOrder.length - 1) {
                return LONG_TIME_FROM_NOW;
            }
            return timelineInOrder[index + 1].dspOffset + getFadeOutTime(index);
        };

        const tl: AudioPlayerStream[] = timelineInOrder.map((t, i) => ({
            id: t.sessionId,
            phase: AudioPlayerStreamPhase.Session,
            url: `${configs.freud.STREAM_BASE}/${playerProps.broadcastIdentifier}/${t.sessionId}/stream.m3u8`,
            fromTime: t.dspOffset,
            toTime: getToTime(i),
            fadeOutTime: getFadeOutTime(i),
            loopContent: false,
            gainOffset: 0,
        }));
        const prePost: AudioPlayerStream[] = [
            {
                id: 'prelude',
                phase: AudioPlayerStreamPhase.Pre,
                url: configs.freud.PRELUDE_POSTLUDE_MUSIC,
                fromTime: 0,
                toTime: LONG_TIME_FROM_NOW,
                fadeOutTime: 15 * 1000,
                loopContent: true,
                gainOffset: 0.0,
            },
            {
                id: 'postlude',
                phase: AudioPlayerStreamPhase.Post,
                url: configs.freud.PRELUDE_POSTLUDE_MUSIC,
                fromTime: 0,
                toTime: LONG_TIME_FROM_NOW,
                fadeOutTime: 15 * 1000,
                loopContent: true,
                gainOffset: 0.0,
            },
        ];
        return [...tl, ...prePost];
    }, [playerProps.broadcastState.timeline, playerProps.broadcastIdentifier]);

    const nonLegacyPlayerProps = {
        ...omit(playerProps, ['broadcastIdentifier', 'broadcastState']),
        streams,
    };

    return playerlibEnabled ? (
        <PlayerLibAudioPlayer playerProps={nonLegacyPlayerProps} onPlayerChange={setAudioPlayer} />
    ) : (
        <HLSAudioPlayer playerProps={playerProps} onPlayerChange={setAudioPlayer} />
    );
}, isEqual);
