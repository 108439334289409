import styled from '@emotion/styled';
import React from 'react';
import { ScheduledWavepath } from 'wavepaths-shared/core';

import {
    Instrumentation,
    WaveInstrumentation,
    WaveInstrumentations,
} from '@/components/WavePathSelector/useWaveInstruments';
import { useInstrumentPreview } from '@/hooks/useInstrumentPreview';

import IconSwap from '../../../../images/icons/icon-swap.svg';
import { PlayerLibAudioPlayer } from '../../../../pages/inSession/AudioPlayerProvider';
import { WaveEditorState, WavePreviewMode } from '../WaveEditor';
import { SoundDetails } from './SoundDetails';
import SoundLayer from './SoundLayer';
import { SoundPreview } from './SoundPreview';
import { SoundSelector } from './SoundSelector';

const Container = styled.div`
    width: 100%;
    flex: 1; /* Take up available space */
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 3;
`;

const ButtonSwap = styled.button`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    background: none;
    border: none;
    cursor: pointer;
    background: var(--color-primary);
    border-radius: 8px;
    height: 44px;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    color: #fff;
    svg {
        width: 24px;
        height: 24px;
    }
`;

interface SoundsEditorProps {
    wavepath: ScheduledWavepath;
    waveInstrumentations: WaveInstrumentations;
    previewMode: WavePreviewMode;
    editorState: WaveEditorState;
    onOpen: (instrument: WaveInstrumentation) => void;
    onStartSwap: () => void;
    onFinishSwap: (instrument: Instrumentation) => void;
    onClose: () => void;
    emotion: string;
}

const SoundsEditor: React.FC<SoundsEditorProps> = ({
    wavepath,
    waveInstrumentations,
    previewMode,
    editorState,
    onOpen,
    onStartSwap,
    onFinishSwap,
    onClose,
    emotion,
}) => {
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const instrumentPreview = useInstrumentPreview();
    const shouldEnableInstrumentPreview =
        previewMode === WavePreviewMode.AudioVisual &&
        (editorState.state === 'openedInstrument' || editorState.state === 'swappingInstrument');

    const closeDetails = () => {
        instrumentPreview.stop();
        onClose();
    };

    const openSwap = () => {
        instrumentPreview.stop();
        onStartSwap();
    };

    return (
        <>
            <Container>
                {waveInstrumentations.map((i) => (
                    <SoundLayer
                        key={i.group.id}
                        instrument={i.instrumentation}
                        isCurrent={true}
                        onSelect={() => onOpen(i)}
                        emotion={emotion}
                    />
                ))}
            </Container>
            <div onClick={stopPropagation}>
                {editorState.state === 'openedInstrument' && (
                    <SoundDetails instrumentation={editorState.instrument.instrumentation} onClose={closeDetails}>
                        <SoundPreview
                            instrumentPreview={instrumentPreview}
                            instrumentation={editorState.instrument.instrumentation}
                        />
                        <ButtonSwap onClick={openSwap}>
                            <img src={IconSwap} alt="Swap" />
                            Swap
                        </ButtonSwap>
                    </SoundDetails>
                )}
                {editorState.state === 'swappingInstrument' && (
                    <SoundSelector
                        wavepath={wavepath}
                        current={editorState.instrument}
                        instrumentPreview={instrumentPreview}
                        onSelect={onFinishSwap}
                        onCancel={closeDetails}
                    />
                )}
            </div>
            {shouldEnableInstrumentPreview && (
                <PlayerLibAudioPlayer
                    playerProps={instrumentPreview.playerProps}
                    onPlayerChange={instrumentPreview.setPlayer}
                />
            )}
        </>
    );
};

export default SoundsEditor;
