import '../../Guide.scss';

import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { SessionState } from 'wavepaths-shared/core';

import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';
import { useThreadMonitor } from '@/hooks/useThreadMonitor';

import * as sessionApi from '../../common/api/sessionApi';
import LoadingOrb from '../../common/components/LoadingOrb';
import { SessionContext } from './autoGuide/useSession';
import { Guide, GuideParams, RemoteSessionControlsContext, useRemoteSessionControls } from './Guide';

export const DISABLED_LABEL = 'This is disabled until you start your session';

export const GuideContainer: React.FC = () => {
    const { sessionId } = useParams<GuideParams>();

    const remoteSession = useRemoteSessionControls({ sessionId });
    useThreadMonitor({
        enabled: remoteSession.tick?.sessionState === SessionState.MAIN_PHASE,
    });

    useEffect(() => {
        if (sessionId) {
            sessionApi.prolongSession(sessionId);
        }
    }, []);

    return remoteSession.sessionError ? (
        <>Error</>
    ) : remoteSession.connection && remoteSession.session ? (
        <GenericErrorBoundary>
            <RemoteSessionControlsContext.Provider value={remoteSession}>
                <SessionContext.Provider value={remoteSession.session}>
                    <Guide remoteSession={remoteSession} />
                </SessionContext.Provider>
            </RemoteSessionControlsContext.Provider>
        </GenericErrorBoundary>
    ) : (
        // TODO: why does it have a different background color until it switches over to the guidewithconnection loader and
        <LoadingOrb>
            <p>Your Session is being prepared, please wait.</p>
        </LoadingOrb>
    );
};
