import { CircularProgress, IconButton, Link, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import firebase from 'firebase/app';
import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@/component-library';
import DashboardPageHeading from '@/components/Dashboard/DashboardPageHeading';
import Snackbar from '@/components/Snackbar';
import { useUserData } from '@/hooks';

import { IUpdateUserData } from '../../auth';
import { createUploadLinkForUserProfile } from '../../common/api/contentApi';
import { LayoutContainer } from '../../LayoutContainer';
import { InputGroup, ProfileSection, Text } from './components';
import ProfileImage from './ProfileIcon';
import { SubscriptionInfo } from './SubscriptionInfo';

type ProviderIdType = 'password' | 'google.com' | 'facebook.com' | undefined;

const useStyles = makeStyles({
    profile: {
        minHeight: '100%',
        display: 'grid',
        justifyItems: 'center',
        background: '#f1f3f8',
    },
    container: {
        position: 'relative',
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 20px 80px 20px',
        maxWidth: '1024px',
        margin: 'auto',
    },
    header: {
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '48px 0',
    },
    main: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: '32px',
    },
    inputGroup: {
        maxWidth: '350px',
        display: 'grid',
        gridAutoFlow: 'row',
        marginBottom: '16px',
    },
});

const addProtocolIfNeeded = (url: string) => {
    const regex = /^(https?:\/\/)/;
    return regex.test(url) ? url : `https://${url}`;
};

const Profile: React.FC<{ firebaseUser: firebase.User }> = ({ firebaseUser }) => {
    const { userData, setEmail, setUserData, mutateError } = useUserData();

    const classes = useStyles();
    const [providerId, setProviderId] = useState<ProviderIdType>(undefined);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [, setShowSuccess] = useState(false);

    const [isChangingEmail, setIsChangingEmail] = useState(false);
    const [isChangingUserData, setIsChangingUserData] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [mutationPending, setMutationPending] = useState(false);
    const [snackbarContent, setSnackbarContent] = useState<string | null>(null);
    const closeSnackbar = useCallback(() => setSnackbarContent(null), []);
    const [newUserData, setNewUserData] = useState<IUpdateUserData>({});
    const [isUploading, setIsUploading] = useState<boolean>(false);
    useEffect(() => {
        if (firebaseUser) {
            setProviderId(firebaseUser?.providerData[0]?.providerId as ProviderIdType);
        }
    }, [firebaseUser]);

    useEffect(() => {
        if (mutateError === 'email-in-use') {
            setSnackbarContent('Email already in use');
        } else if (mutateError === 'invalidEmail') {
            setSnackbarContent('Email invalid');
        } else if (mutateError === 'requires-recent-login') {
            setSnackbarContent('Session expired, please log in again to continue');
        }
    }, [mutateError]);

    const changePassword = async () => {
        if (newPassword !== confirmPassword) {
            return setSnackbarContent('Passwords do not match');
        }

        try {
            await reauthenticate();
        } catch (error: any) {
            return setSnackbarContent(error?.message || 'Reauthentication failed');
        }

        try {
            await firebaseUser.updatePassword(newPassword);
            setSnackbarContent('Password successfully updated');
            flashSavedLabel();
        } catch (error: any) {
            setSnackbarContent(error?.message || 'Failed to update password');
        }
    };

    const handleChangeEmail = async () => {
        if (newEmail !== confirmEmail) {
            return setSnackbarContent('Emails do not match');
        }
        if (!password) {
            return setSnackbarContent('Please provide a password');
        }

        setMutationPending(true);
        try {
            await reauthenticate();
            await setEmail(newEmail);
            setIsChangingEmail(false);
            setSnackbarContent('Email successfully updated');
        } catch (error: any) {
            setSnackbarContent(error?.message || 'Failed to update email');
        } finally {
            setMutationPending(false);
        }
    };

    const handleChangeUserData = async () => {
        if (newUserData?.name?.length === 0) {
            return setSnackbarContent('Please provide a name');
        }

        setMutationPending(true);
        try {
            await setUserData(newUserData);
            setIsChangingUserData(false);
            setSnackbarContent('User data successfully updated');
        } catch (error: any) {
            setSnackbarContent(error?.message || 'Failed to update user data');
        } finally {
            setMutationPending(false);
        }
    };

    const reauthenticate = async () => {
        const credentialPassword = password ? password : oldPassword;
        try {
            if (providerId === 'password') {
                const credential =
                    firebaseUser.email &&
                    firebase.auth.EmailAuthProvider.credential(firebaseUser.email, credentialPassword);
                if (credential) {
                    await firebaseUser.reauthenticateWithCredential(credential);
                }
            } else {
                throw Error('Wrong provider: ' + providerId);
            }
        } catch (error: any) {
            throw Error(error.message);
        }
    };

    const flashSavedLabel = () => {
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 1500);
    };

    const handleImageChange = async (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            setSnackbarContent('Please select an image first!');
            return;
        }

        const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
        if (file.size > MAX_SIZE) {
            setSnackbarContent('The file size is too large. Please select a file smaller than 5MB.');
            return;
        }

        if (!firebaseUser) {
            throw new Error('No user');
        }

        setIsUploading(true);
        try {
            const createUploadLinkResponse = await createUploadLinkForUserProfile(firebaseUser, {
                name: file.name,
                type: file.type,
                size: file.size,
            });

            if (!createUploadLinkResponse.URI) {
                console.debug('Error creating upload URL');
                return;
            }

            const uploadResponse = await axios.post(createUploadLinkResponse.URI, await file.arrayBuffer());

            if (uploadResponse.status !== 200) {
                setSnackbarContent('Upload response fail');
            } else {
                await setUserData({ profileImageUrl: uploadResponse.data.mediaLink });
                setSnackbarContent('Profile image updated successfully');
            }
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <LayoutContainer>
            <DashboardPageHeading pageTitle="Your Account" />
            <main className={classes.main}>
                <section>
                    <h3>Account details</h3>
                    <ProfileSection>
                        <InputGroup>
                            <span>
                                <label htmlFor="icon-button-file">
                                    <input
                                        accept="image/*"
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    ></input>
                                    <Tooltip
                                        title="Upload a photo"
                                        style={isUploading ? { pointerEvents: 'none' } : {}}
                                    >
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <ProfileImage
                                                name={userData?.name || ''}
                                                url={userData?.profileImageUrl}
                                                size={100}
                                            />
                                            {isUploading && (
                                                <CircularProgress size="50px" style={{ position: 'absolute' }} />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </label>
                            </span>
                        </InputGroup>
                        {isChangingUserData ? (
                            <ProfileSection>
                                <InputGroup>
                                    <label htmlFor="name">Name</label>
                                    <input
                                        value={newUserData?.name ?? userData?.name}
                                        onChange={(event) =>
                                            setNewUserData((prev) => ({
                                                ...prev,
                                                name: event.target.value,
                                            }))
                                        }
                                        name="name"
                                        type="text"
                                        placeholder="User name"
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <label htmlFor="link">Link</label>
                                    <input
                                        value={newUserData?.link ?? userData?.link}
                                        onChange={(event) =>
                                            setNewUserData((prev) => ({
                                                ...prev,
                                                link: event.target.value,
                                            }))
                                        }
                                        name="link"
                                        type="text"
                                        placeholder="..."
                                        autoComplete="new-password2"
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <label htmlFor="bio">Bio</label>
                                    <textarea
                                        value={newUserData?.bio ?? userData?.bio}
                                        onChange={(event) =>
                                            setNewUserData((prev) => ({
                                                ...prev,
                                                bio: event.target.value,
                                            }))
                                        }
                                        name="bio"
                                        placeholder="..."
                                    />
                                </InputGroup>
                                <Button
                                    disabled={mutationPending}
                                    variant="solid-blue"
                                    size="m"
                                    onClick={handleChangeUserData}
                                    style={{ marginRight: 8 }}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    disabled={mutationPending}
                                    variant="outlined"
                                    size="m"
                                    onClick={() => setIsChangingUserData(false)}
                                >
                                    Cancel
                                </Button>
                            </ProfileSection>
                        ) : (
                            <>
                                <InputGroup>
                                    <label>Name</label>
                                    <Text variant="body1">{userData?.name}</Text>
                                </InputGroup>
                                <InputGroup>
                                    <label>Link</label>
                                    {userData?.link ? (
                                        <Link href={addProtocolIfNeeded(userData?.link)} target="_blank">
                                            <Text variant="body1">{userData?.link}</Text>
                                        </Link>
                                    ) : (
                                        <Text variant="body1">—</Text>
                                    )}
                                    {/* <Text variant="body1">{userData?.link || '—'}</Text> */}
                                </InputGroup>
                                <InputGroup>
                                    <label>Bio</label>
                                    <Text variant="body1">{userData?.bio || '—'}</Text>
                                </InputGroup>
                            </>
                        )}
                        {isChangingEmail ? (
                            <>
                                <InputGroup>
                                    <label htmlFor="newEmail">New email</label>
                                    <input
                                        value={newEmail}
                                        onChange={(event) => setNewEmail(event.target.value)}
                                        type="email"
                                        name="newEmail"
                                        placeholder="New email"
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <label htmlFor="confirmEmail">Confirm new email</label>
                                    <input
                                        value={confirmEmail}
                                        onChange={(event) => setConfirmEmail(event.target.value)}
                                        type="email"
                                        name="confirmEmail"
                                        placeholder="Confirm email"
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <label htmlFor="password">Password</label>
                                    <input
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                    />
                                </InputGroup>
                                <Button
                                    disabled={mutationPending}
                                    variant="solid-blue"
                                    size="m"
                                    onClick={handleChangeEmail}
                                    style={{ marginRight: 8 }}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    disabled={mutationPending}
                                    variant="outlined"
                                    size="m"
                                    onClick={() => setIsChangingEmail(false)}
                                >
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <>
                                <InputGroup style={{ marginTop: 10 }}>
                                    <label>Email</label>
                                    <Text variant="body1">{userData?.email}</Text>
                                </InputGroup>
                                {providerId === 'password' && (
                                    <Button
                                        variant="outlined"
                                        size="m"
                                        onClick={() => setIsChangingEmail(true)}
                                        style={{ marginRight: 8 }}
                                    >
                                        Change Email
                                    </Button>
                                )}
                                <Button variant="outlined" size="m" onClick={() => setIsChangingUserData(true)}>
                                    Change user data
                                </Button>
                            </>
                        )}
                    </ProfileSection>
                </section>
                {providerId && (
                    <section>
                        <h3>Change password</h3>
                        <ProfileSection>
                            {providerId === 'password' ? (
                                <>
                                    <InputGroup>
                                        <label htmlFor="password">Old Password</label>
                                        <input
                                            value={oldPassword}
                                            onChange={(event) => setOldPassword(event.target.value)}
                                            type="password"
                                            name="password"
                                            placeholder="Old password"
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <label htmlFor="password">New Password</label>
                                        <input
                                            value={newPassword}
                                            onChange={(event) => setNewPassword(event.target.value)}
                                            type="password"
                                            name="password"
                                            placeholder="New password"
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <label htmlFor="confirmPassword">Confirm new password</label>
                                        <input
                                            value={confirmPassword}
                                            onChange={(event) => setConfirmPassword(event.target.value)}
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm new password"
                                        />
                                    </InputGroup>
                                    <Button variant="outlined" size="m" onClick={changePassword}>
                                        Change password
                                    </Button>
                                </>
                            ) : (
                                <p>{`You can change your password in your ${providerId} account settings`}</p>
                            )}
                        </ProfileSection>
                        <Snackbar
                            type={'error'}
                            isLongButton={false}
                            message={snackbarContent ?? ''}
                            confirmText={'OK'}
                            open={snackbarContent !== null}
                            closeSnackbar={closeSnackbar}
                        />
                    </section>
                )}
                <SubscriptionInfo fbUser={firebaseUser} />
            </main>
        </LayoutContainer>
    );
};

export default Profile;
