import '../../Guide.scss';

import { findLastIndex } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import { useParams } from 'react-router';
import { useEvent, useInterval } from 'react-use';
import styled from 'styled-components';
import { BroadcastPersistentState, isScheduledWavepath, SessionRenderType, SessionState } from 'wavepaths-shared/core';
import { getDurationFromScore } from 'wavepaths-shared/domain/sessionScore';

import { Button, Dialog, EvaIcon } from '@/component-library';
import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';
import { Header } from '@/components/Header';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import useAnonymousToken from '@/hooks/useAnonymousToken';
import { SessionCacheContext, useSessionCache } from '@/hooks/useAudioCache';
import {
    useRemoteElapsedTimeSecs,
    useRemoteSessionPlaybackControl,
    useRemoteSessionState,
} from '@/hooks/useSessionTick';
import { TestHooksContext } from '@/hooks/useTestHooks';
import { useThreadMonitor } from '@/hooks/useThreadMonitor';

import * as sessionApi from '../../common/api/sessionApi';
import LoadingOrb from '../../common/components/LoadingOrb';
import { formatDuration } from '../../dateUtilsV2';
import WavepathsLogo from '../../images/WavepathBlueLogo.svg';
import { SessionName } from '../inSession/SessionName';
import { AudioDownload } from './AudioDownload';
import { AudioPlayer, AudioPlayerContext, AudioPlayerMaker, NULL_AUDIO_PLAYER } from './AudioPlayerProvider';
import { SessionContext, useSessionByBroadcastIdentifier } from './autoGuide/useSession';
import PlaybackOptionsDialog from './deviceOptions/PlaybackOptionsDialog';
import { PlayerChoiceContext, usePlayerChoice } from './deviceOptions/PlayerChoiceContext';
import { useRemoteSessionControls } from './Guide';
import {
    AudioControlIconBlack,
    AudioControlIconWhite,
    PlayPauseButton,
    PrecomposedAudioControls,
    VolumeContainer,
    WaveJumpButton,
} from './Precomposed/PrecomposedGuide';
import { useSniffedAudioPlayerTypeStreamUrl } from './Precomposed/useSniffedAudioPlayerTypeStreamUrl';
import Timer from './Timer';
import { VolumeMeter } from './VolumeMeter';

export const DISABLED_LABEL = 'This is disabled until you start your session';

export const ListenContainer: React.FC = () => {
    const { broadcastIdentifier, freePaidPathPart } = useParams<{
        broadcastIdentifier: string;
        freePaidPathPart?: string;
    }>();
    const { session: websitePlayerSession } = useSessionByBroadcastIdentifier({ broadcastIdentifier });
    const testHooks = useContext(TestHooksContext);
    const { anonymousToken, loading: loadingAnonymousToken } = useAnonymousToken();

    useEffect(() => {
        testHooks?.debug && console.debug({ deprecatedWebsitePlayerSession: websitePlayerSession });
    }, [websitePlayerSession]);
    const remoteSession = useRemoteSessionControls({ sessionId: websitePlayerSession?.id, mode: 'listen' });
    useEffect(() => {
        if (testHooks?.debug) {
            console.debug(remoteSession);
        }
    }, [remoteSession]);

    useThreadMonitor({
        enabled: remoteSession.tick?.sessionState === SessionState.MAIN_PHASE,
    });

    const isReadyToLoadPlayer =
        websitePlayerSession &&
        !loadingAnonymousToken &&
        remoteSession &&
        remoteSession.session &&
        remoteSession.connection;

    const playerChoiceHook = usePlayerChoice(websitePlayerSession?.renderType ?? SessionRenderType.PREDICTIVE_COMPOSED);
    const webPlayerActive =
        (playerChoiceHook.whereToPlayMusic === 'thisDevice' || playerChoiceHook.whereToPlayMusic === 'thisAndRemote') &&
        playerChoiceHook.playerChoice !== 'native';

    return remoteSession && remoteSession.sessionError ? (
        <>Error loading session data</>
    ) : (
        <PlayerChoiceContext.Provider value={playerChoiceHook}>
            {isReadyToLoadPlayer ? (
                <>
                    <PlaybackOptionsDialog
                        broadcastIdentifier={broadcastIdentifier}
                        isPlayerAppSupported={true}
                        isWebPlayerSupported={!isSafari && !isIOS}
                        freePaidPathPartForPlayerAppLink={freePaidPathPart}
                    />

                    {webPlayerActive ? (
                        <GenericErrorBoundary>
                            <SessionContext.Provider value={remoteSession.session}>
                                <Listen
                                    outputDevice={playerChoiceHook.browserPlayerOutputDevice}
                                    remoteSession={remoteSession}
                                    anonymousToken={anonymousToken}
                                    onOpenAudioSettings={() => playerChoiceHook.openDialog()}
                                />
                            </SessionContext.Provider>
                        </GenericErrorBoundary>
                    ) : (
                        <NativePlay
                            remoteSession={remoteSession}
                            onOpenAudioSettings={() => playerChoiceHook.openDialog()}
                        />
                    )}
                </>
            ) : (
                <>
                    <LoadingOrb></LoadingOrb>
                </>
            )}
        </PlayerChoiceContext.Provider>
    );
};

const Logo = styled.img`
    display: block;
    height: 30px;
    margin: 10px;
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content 1fr min-content;
    background: lavender;
`; //TODO: coloring

const Top = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const StyledHeader = styled(Header)`
    width: 100%;
    padding: 8px 40px;
    @media (min-height: 720px) {
        padding: 24px 40px;
    }
`;

const SessionHeaderInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
`;

const SessionDuration = styled.div`
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    color: #2b2d3f;
`;

const Settings = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const SettingContainer = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    margin-right: 10px;
`;

const SessionInfo = styled.div`
    text-align: center;
    margin: auto auto;
`;

const Middle = styled.div`
    margin: auto auto;
    justify-items: center;
    display: grid;
    gap: 20px;
`;

const Bottom = styled.div`
    margin: auto auto;
`;

const BottomContent = styled.div``;

const Listen: FC<{
    outputDevice: string | undefined;
    remoteSession: ReturnType<typeof useRemoteSessionControls>;
    anonymousToken: string | undefined;
    onOpenAudioSettings: () => void;
}> = ({ outputDevice, remoteSession, anonymousToken, onOpenAudioSettings }) => {
    const { freePaidPathPart } = useParams<{
        freePaidPathPart?: string;
    }>();
    if (remoteSession.session === undefined) throw new Error('missing session');
    if (remoteSession.connection === undefined) throw new Error('missing connection');
    const session = remoteSession.session;
    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    const remoteElapsedTimeSecs = useRemoteElapsedTimeSecs();
    const [broadcastState, setBroadcastState] = useState<BroadcastPersistentState>(
        session.broadcastState || {
            timeline: [{ sessionId: session.id, dspOffset: 0, crossfadeTime: 0 }],
            discarded: [],
        },
    );

    useEffect(() => {
        if (!remoteSession.connection) return;
        console.debug('Waiting for broadcastStateUpdate');
        remoteSession.connection.on('broadcastStateUpdate', (data: BroadcastPersistentState) => {
            setBroadcastState(data);
            console.debug('New broadcastState', data);
        });
    }, [remoteSession.connection]);

    const [playerElapsedTimeSecs, setPlayerElapsedTimeSecs] = useState(
        session.renderType !== SessionRenderType.PRE_RENDERED ? remoteElapsedTimeSecs : 0,
    );
    const elapsedTimeSecsEffective =
        session.renderType !== SessionRenderType.PRE_RENDERED ? remoteElapsedTimeSecs : playerElapsedTimeSecs;

    const [audioPlayer, setAudioPlayer] = useState<AudioPlayer>(NULL_AUDIO_PLAYER);

    const { streamUrl, playerTypeResolution } = useSniffedAudioPlayerTypeStreamUrl(session);
    const sessionCache = useSessionCache({
        session,
        streamUrl,
        estimatedDurationSec: getDurationFromScore(session.score.wavepaths) / 1000,
    });

    const { pause, resume } = useRemoteSessionPlaybackControl(remoteSession.connection, (q) => q.callback());

    useInterval(() => {
        if (session.renderType == SessionRenderType.PRE_RENDERED) {
            const currentTimeSecs = audioPlayer.getCurrentTimeSecs();
            setPlayerElapsedTimeSecs(currentTimeSecs);
        }
    }, 1000);

    useEffect(() => {
        setSnackbarContent(audioPlayer.warning);
    }, [audioPlayer.warning]);

    useEffect(() => {
        setSnackbarContent(remoteSession.snackbarContent);
    }, [remoteSession.snackbarContent]);

    useEffect(() => {
        setSnackbarContent(remoteSession.warningContent ?? null);
    }, [remoteSession.warningContent]);

    const isPostlude =
        remoteSession.tick && [SessionState.POSTLUDE, SessionState.ENDED].includes(remoteSession.tick.sessionState);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [isPrelude, setIsPrelude] = useState<boolean>(false);

    const [clicked, setClicked] = useState(false);
    useEvent(
        'click',
        () => {
            if (clicked) return;
            setClicked(true);
            audioPlayer.actions.unblock();
        },
        document.body,
    );

    const { paused: remotePaused } = useRemoteSessionState();

    useEffect(() => {
        if (!clicked || !audioLoaded) {
            return;
        }
        if (remoteSession.session?.renderType !== SessionRenderType.PREDICTIVE_COMPOSED) return;

        if (remotePaused) {
            audioPlayer.actions.pause({ reason: 'remote' });
        } else {
            unblockAndPlay();
        }
    }, [clicked, remotePaused]);

    useEffect(() => {
        if (clicked && !isPostlude) {
            if (
                (session.renderType === SessionRenderType.PRE_RENDERED || remoteSession.sessionInitialised) &&
                !audioLoaded
            ) {
                (async () => {
                    await audioPlayer.actions.unblock();

                    if (!remoteSession.sessionStarted) {
                        console.debug('Playing prelude...');

                        setIsPrelude(true);
                        audioPlayer.actions.playPrelude();
                    } else {
                        if (!remotePaused) {
                            audioPlayer.actions.play({ offsetSeconds: elapsedTimeSecsEffective });
                        }
                    }
                    setAudioLoaded(true);
                })();
            }
            if (audioLoaded && remoteSession.sessionStarted) {
                setIsPrelude(false);
                if (!remotePaused) {
                    console.debug('Playing main phase...');
                    audioPlayer.actions.play({ offsetSeconds: elapsedTimeSecsEffective });
                }
            }
        } else {
            setIsPrelude(false);
            console.debug('Playing postlude...');
            audioPlayer.actions.pause({ fadeMs: 30 * 1000, reason: 'fadingIntoPostlude' });
            audioPlayer.actions.playPostlude();
        }
    }, [clicked, remoteSession.sessionInitialised, remoteSession.sessionStarted, audioLoaded, isPostlude]);

    const unblockAndPlay = () => {
        audioPlayer.actions.unblock().then(async () => {
            if (remoteSession.sessionStarted && !isPostlude) {
                setIsPrelude(false);
                console.debug('Playing main phase aftert unblock...');
                audioPlayer.actions.play({ offsetSeconds: elapsedTimeSecsEffective });
            } else {
                if (!isPostlude) {
                    setIsPrelude(true);
                }
                console.debug('Playing prelude/postlude after unblock...');
                audioPlayer.actions.playPrelude();
            }
        });
    };

    const togglePlaying = async () => {
        if (session.renderType == SessionRenderType.PREDICTIVE_COMPOSED) {
            if (remotePaused) {
                resume();
            } else {
                pause();
            }
        } else {
            if (audioPlayer.generalPlayerStatus === 'playing') {
                audioPlayer.actions.pause({ reason: 'user' });
            } else {
                await audioPlayer.actions.unblock();
                if (
                    (remoteSession.sessionStarted ||
                        remoteSession.session?.renderType === SessionRenderType.PRE_RENDERED) &&
                    !isPostlude
                ) {
                    setIsPrelude(false);
                    audioPlayer.actions.play({ offsetSeconds: elapsedTimeSecsEffective });
                } else {
                    if (!isPostlude) {
                        setIsPrelude(true);
                    }
                    audioPlayer.actions.playPrelude();
                }
            }
        }
    };

    // TODO: This could be optimised a bit: we update the component every 100ms, but the scheduled wavepath timings don't change that often.
    const scheduledWavepaths = session.score.wavepaths.filter(isScheduledWavepath);
    const scheduledWavepathStartTimesSeconds = scheduledWavepaths.map(
        (p) => (p.plan?.fromTime ?? Number.MAX_SAFE_INTEGER) / 1000,
    );
    const currentWavepathIndex = findLastIndex(
        scheduledWavepathStartTimesSeconds,
        (s) => s <= elapsedTimeSecsEffective,
    );
    const isPossibleToGoToPrev = currentWavepathIndex > 0;
    const isPossibleToGoToNext = currentWavepathIndex < scheduledWavepathStartTimesSeconds.length;
    const goToPrev = async () => {
        setIsPrelude(false);
        await audioPlayer.actions.unblock();
        if (currentWavepathIndex <= 0) return;
        const prevStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex - 1];
        audioPlayer.actions.setTime(prevStartTime);
    };

    const goToNext = async () => {
        setIsPrelude(false);
        await audioPlayer.actions.unblock();
        if (currentWavepathIndex >= scheduledWavepathStartTimesSeconds.length - 1) {
            audioPlayer.actions.end();
        } else {
            const nextStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex + 1];
            audioPlayer.actions.setTime(nextStartTime);
        }
    };

    const isSessionInitializedForUX =
        (!!remoteSession.remoteSessionLogs &&
            remoteSession.sessionScoreState !== 'loading' &&
            remoteSession.sessionInitialised &&
            !!remoteSession.connection) ||
        session.renderType === SessionRenderType.PRE_RENDERED;
    // console.debug({
    //     isSessionInitializedForUX,
    //     remoteSessionLogs: remoteSession.remoteSessionLogs,
    //     sessionScoreState: remoteSession.sessionScoreState,
    //     connection: remoteSession.connection,
    //     renderType: session.renderType,
    //     sessionInitialised: remoteSession.sessionInitialised,
    //     canClientStartEarly: session.canClientStartEarly,
    // });
    const totalDuration = Number(session.variableInputs.totalDuration) * 1000 * 60;
    return (
        <GenericErrorBoundary>
            <AudioPlayerMaker
                playerProps={{
                    outputDevice,
                    broadcastIdentifier: session.broadcastIdentifier,
                    renderType: session.renderType,
                    errorContext: 'Listen Session',
                    mode: 'recording',
                    broadcastState,
                    broadcastElapsedTimeSecs: elapsedTimeSecsEffective,
                    sessionDuration: remoteSession.tick?.sessionDuration ?? 0,
                    voiceOverStages: session.score.voiceover ?? [],
                    playDemoVO: freePaidPathPart === 'free',
                }}
                setAudioPlayer={setAudioPlayer}
            />
            <AudioPlayerContext.Provider value={audioPlayer}>
                <>
                    <Dialog
                        open={audioPlayer.audioStatus === 'blocked'}
                        onConfirm={unblockAndPlay}
                        message="In order to play sound through this tab, you first need to enable audio"
                        confirmText="Enable Audio"
                        title="Enable Audio"
                        disableBackdropClick={true}
                    />
                    <Container>
                        <Top>
                            <SessionCacheContext.Provider value={sessionCache}>
                                <StyledHeader
                                    left={
                                        <SessionHeaderInfo>
                                            <SessionName session={session} />
                                            <SessionDuration>
                                                {totalDuration > 0 ? formatDuration(totalDuration) : '00:00'}
                                            </SessionDuration>
                                        </SessionHeaderInfo>
                                    }
                                    center={<Timer timeElapsedMs={elapsedTimeSecsEffective * 1000}></Timer>}
                                    right={
                                        <Settings>
                                            {session.renderType == SessionRenderType.PRE_RENDERED &&
                                                !isSafari &&
                                                playerTypeResolution !== 'unknown' && (
                                                    <>
                                                        <SettingContainer>
                                                            <SessionCacheContext.Provider value={sessionCache}>
                                                                <AudioDownload />
                                                            </SessionCacheContext.Provider>
                                                        </SettingContainer>
                                                    </>
                                                )}
                                            <SettingContainer onClick={onOpenAudioSettings}>
                                                <EvaIcon name="settings-2-outline" fill="rgba(0, 0, 0, 0.54)" />
                                            </SettingContainer>
                                        </Settings>
                                    }
                                />
                            </SessionCacheContext.Provider>
                        </Top>
                        <Middle>
                            {isSessionInitializedForUX ? (
                                <>
                                    {isPrelude && (
                                        <>
                                            <SessionInfo>Playing Prelude Music</SessionInfo>
                                            {remoteSession.tick?.sessionState == SessionState.PRELUDE &&
                                                session.canClientStartEarly && (
                                                    <Button
                                                        variant="solid-blue"
                                                        size="l"
                                                        onClick={async (event) => {
                                                            (event.target as HTMLButtonElement).disabled = true;
                                                            remoteSession.startSession();

                                                            (event.target as HTMLButtonElement).disabled = false;
                                                        }}
                                                    >
                                                        Start session
                                                    </Button>
                                                )}
                                        </>
                                    )}
                                    {session.renderType !== SessionRenderType.PRE_RENDERED &&
                                        remoteSession.tick?.sessionState &&
                                        [SessionState.MAIN_PHASE, SessionState.PLANNED].includes(
                                            remoteSession.tick?.sessionState,
                                        ) && <SessionInfo></SessionInfo>}
                                    {remoteSession.tick?.sessionState == SessionState.POSTLUDE && (
                                        <SessionInfo>Session Postlude Music</SessionInfo>
                                    )}
                                    {remoteSession.tick?.sessionState == SessionState.ENDED && (
                                        <SessionInfo>Session Ended</SessionInfo>
                                    )}

                                    {(session.renderType === SessionRenderType.PRE_RENDERED ||
                                        remoteSession.tick?.sessionState !== SessionState.ENDED) && (
                                        <>
                                            <PrecomposedAudioControls>
                                                {audioPlayer.playerStatus === 'loading' ? (
                                                    <>Loading...</>
                                                ) : (
                                                    <>
                                                        {(session.renderType === SessionRenderType.PRE_RENDERED ||
                                                            (session.canClientStartEarly &&
                                                                (remoteSession.tick?.sessionState ==
                                                                    SessionState.MAIN_PHASE ||
                                                                    remoteSession.tick?.sessionState ==
                                                                        SessionState.PAUSE))) && (
                                                            <>
                                                                {session.renderType ==
                                                                    SessionRenderType.PRE_RENDERED && (
                                                                    <WaveJumpButton
                                                                        aria-label="Jump to previous wave"
                                                                        onClick={goToPrev}
                                                                        disabled={!isPossibleToGoToPrev}
                                                                    >
                                                                        <AudioControlIconBlack
                                                                            width="40"
                                                                            height="40"
                                                                            viewBox="0 0 40 40"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M9.16667 30V10H11.9444V30H9.16667ZM30.8333 30L16.2223 20L30.8333 10V30Z" />
                                                                        </AudioControlIconBlack>
                                                                    </WaveJumpButton>
                                                                )}
                                                                <PlayPauseButton
                                                                    onClick={togglePlaying}
                                                                    style={{ width: '100px', height: '100px' }}
                                                                >
                                                                    {audioPlayer.generalPlayerStatus === 'playing' ? (
                                                                        <AudioControlIconWhite
                                                                            width="48"
                                                                            height="48"
                                                                            viewBox="0 0 48 48"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                                                                        </AudioControlIconWhite>
                                                                    ) : (
                                                                        <AudioControlIconWhite
                                                                            width="48"
                                                                            height="48"
                                                                            viewBox="0 0 48 48"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M14 38V10L36 24L14 38Z" />
                                                                        </AudioControlIconWhite>
                                                                    )}
                                                                </PlayPauseButton>
                                                                {session.renderType ==
                                                                    SessionRenderType.PRE_RENDERED && (
                                                                    <WaveJumpButton
                                                                        aria-label="Jump to next wave"
                                                                        onClick={goToNext}
                                                                        disabled={!isPossibleToGoToNext}
                                                                    >
                                                                        <AudioControlIconBlack
                                                                            width="40"
                                                                            height="40"
                                                                            viewBox="0 0 40 40"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path d="M28.0556 30V10H30.8333V30H28.0556ZM9.16667 30V10L23.7778 20L9.16667 30Z" />
                                                                        </AudioControlIconBlack>
                                                                    </WaveJumpButton>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </PrecomposedAudioControls>
                                            {audioPlayer.isVolumeControllable ? (
                                                <VolumeContainer>
                                                    <VolumeMeter />
                                                </VolumeContainer>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!remoteSession.sessionInitialised && (
                                        <>
                                            {!session.canClientStartEarly && (
                                                <div>
                                                    Session is not started yet. Please wait for your host to start the
                                                    session.
                                                </div>
                                            )}
                                            {session.canClientStartEarly && (
                                                <>
                                                    <div>Session is not started yet.</div>
                                                    <Button
                                                        variant="solid-blue"
                                                        size="l"
                                                        onClick={async (event) => {
                                                            (event.target as HTMLButtonElement).disabled = true;
                                                            await sessionApi.startSessionEarly(
                                                                session.id,
                                                                undefined,
                                                                anonymousToken,
                                                            );
                                                            unblockAndPlay();
                                                            (event.target as HTMLButtonElement).disabled = false;
                                                        }}
                                                    >
                                                        Start Session
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Middle>
                        <Bottom>
                            <BottomContent>
                                <a href="https://www.wavepaths.com" target="_blank'">
                                    <Logo src={WavepathsLogo} alt="Wavepaths" />
                                </a>
                            </BottomContent>
                        </Bottom>
                    </Container>
                </>
            </AudioPlayerContext.Provider>
        </GenericErrorBoundary>
    );
};

const NativePlay: FC<{
    remoteSession: ReturnType<typeof useRemoteSessionControls>;
    onOpenAudioSettings: () => void;
}> = ({ remoteSession, onOpenAudioSettings }) => {
    if (remoteSession.session === undefined) throw new Error('missing session');
    if (remoteSession.connection === undefined) throw new Error('missing connection');
    const session = remoteSession.session;

    return (
        <GenericErrorBoundary>
            <>
                <Container>
                    <Top>
                        <StyledHeader
                            left={
                                <SessionHeaderInfo>
                                    <SessionName session={session} />
                                </SessionHeaderInfo>
                            }
                            right={
                                <Settings>
                                    <SettingContainer onClick={onOpenAudioSettings}>
                                        <EvaIcon name="settings-2-outline" fill="rgba(0, 0, 0, 0.54)" />
                                    </SettingContainer>
                                </Settings>
                            }
                        />
                    </Top>
                    <Middle></Middle>
                    <Bottom>
                        <BottomContent>
                            <a href="https://www.wavepaths.com" target="_blank'">
                                <Logo src={WavepathsLogo} alt="Wavepaths" />
                            </a>
                        </BottomContent>
                    </Bottom>
                </Container>
            </>
        </GenericErrorBoundary>
    );
};
