import React from 'react';
import { isIOS, isSafari } from 'react-device-detect';

import PlayerAppMessage from './PlayerAppMessage';

export default function PlayerAppWarningMessage(): React.ReactElement {
    const text = getText();

    return <PlayerAppMessage heading="Please note: The player app is for music streaming only." text={text} />;

    function getText() {
        if (isIOS || isSafari) {
            return (
                <>
                    ✅ Due to Apple updates affecting browser audio playback, we decided to{' '}
                    <b>restrict music playback to the Wavepaths player app</b> for Safari (Apple desktops & laptops) or
                    any browser on iOS (iPhones & iPads).
                    <br />✅ For Playback-only, <b>Offline</b>, or <b>Session Recording Replays</b>, only{' '}
                    <b>play and pause</b> controls are available inside the player app.
                    <br />✅ To use <b>advanced music controls</b>, select <b>Live (Real-time)</b> as your session type
                    before starting the session & access these controls via your browser after launching the player app.
                    <br />
                    <br />
                    Click{' '}
                    <a
                        href="https://guide.wavepaths.com/customer-support?post=120c9a8d-5d8a-4b79-8873-0b8910ad2a57"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <b>here</b>
                    </a>{' '}
                    for more details.
                </>
            );
        } else {
            return (
                <>
                    ✅ To access your music controls, please <b>return to Wavepaths in your browser</b> after launching
                    the player app.
                    <br />✅ If you require advanced music controls, please ensure you selected{' '}
                    <b>"Live (Real-time)"</b> as your session type on the session planning page.
                    <br />
                    <br />
                    Click{' '}
                    <a
                        href="https://guide.wavepaths.com/customer-support?post=120c9a8d-5d8a-4b79-8873-0b8910ad2a57"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <b>here</b>
                    </a>{' '}
                    for more details.
                </>
            );
        }
    }
}
