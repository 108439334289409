import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import {
    AdministrationRoute,
    ClientVariablesMusicPreference,
    CoreEmotionalAtmosphere,
    DosageLevel,
    SessionRenderType,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
} from 'wavepaths-shared/core';

import TemplateDetail from '@/component-library/components/TemplateDetail';

const pulse = keyframes`
    from, to { 
        opacity: 0.5; 
    }

    50% { 
        opacity: 1; 
    }
`;

const LoadingDiv = styled.div({
    filter: 'blur(3px)',
    animation: `${pulse} 2s ease-in-out infinite`,
});

function LoadingTemplateInfo() {
    return (
        <LoadingDiv data-cy={'loading-div'}>
            <TemplateDetail
                id={'fake'}
                emotionalities={{
                    primary: CoreEmotionalAtmosphere.SILENCE,
                    secondary: CoreEmotionalAtmosphere.SILENCE,
                    tertiary: CoreEmotionalAtmosphere.SILENCE,
                }}
                title="Loading..."
                subtitle={'Lorem ipsum'}
                description={'Description'}
                intensity={SessionScoreEmotionalIntensity.HIGH}
                modality={SessionScoreModality.PSILOCYBIN}
                administration={AdministrationRoute.NASAL}
                dosage={DosageLevel.HIGH}
                renderType={SessionRenderType.PRE_RENDERED}
                onRenderTypeChange={() => {
                    console.log('avoid the void');
                }}
                duration={Number(60)}
                onDurationChange={() => {
                    console.log('avoid the void');
                }}
                onSubmit={() => {
                    console.log('avoid the void');
                }}
                submitDisabled={true}
                timelineComponent={null}
                tracklistComponent={null}
                minDurationMins={30}
                maxDurationMins={60}
                sessionName={'Example session name'}
                onSessionNameChange={() => {
                    console.log('avoid the void');
                }}
                musicalPreference={ClientVariablesMusicPreference.ACOUSTIC}
                onMusicalPreferenceChange={() => {
                    console.log('avoid the void');
                }}
                canSaveTemplates={true}
                onCreateTemplate={() => {
                    console.log('avoid the void');
                }}
                onDelete={() => {
                    console.log('avoid the void');
                }}
                allowedToEditDelete={false}
                onEditTemplate={() => {
                    console.log('avoid the void');
                }}
                contentStatuses={'All'}
                onContentStatusChange={() => {
                    console.log('avoid the void');
                }}
                showAdminFeatures={false}
                schedulingType={'now'}
                onSchedulingTypeChange={() => {
                    console.log('avoid the void');
                }}
                canClientStartEarly={false}
                onCanClientStartEarlyChange={() => {
                    console.log('avoid the void');
                }}
                onScheduledStartChange={() => {
                    console.log('avoid the void');
                }}
                selectedClients={[]}
                setSelectedClients={() => {
                    console.log('avoid the void');
                }}
            />
        </LoadingDiv>
    );
}

export default LoadingTemplateInfo;
