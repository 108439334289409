import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import firebase from 'firebase/app';
import { AnimatePresence, motion } from 'framer-motion';
import { isNil, maxBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    ClientVariablesMusicPreference,
    SessionRenderType,
    SessionScoreSessionUse,
    SessionType,
    SessionVariables,
} from 'wavepaths-shared/core';
import * as scoreUtils from 'wavepaths-shared/domain/scores';
import { planSessionFromPreset } from 'wavepaths-shared/domain/sessionPlanner';

import * as api from '../../../common/api/sessionApi';
import { getDefaultVariableInputs } from '../../../pages/templateInfo/useScorePlanner';
import { useSessionRenderingType } from '../../../pages/templateInfo/useSessionRenderingType';
import { getTemplateById, ScoreTemplate } from '../../api/contentApi';
import { getSavedTemplateById, ListSavedTemplatesItem } from '../../api/savedTemplatesApi';
import * as sessionApi from '../../api/sessionApi';
import TemplateCard from './TemplateCard';

const fadeInOut = keyframes`
    0%, 100% { opacity: 0.5; }
    50% { opacity: 1; }
`;

const LoadingContainer = styled.div`
    width: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const LoadingArtwork = styled.div<{ variant: 'dark' | 'light' }>`
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    background: ${(props) => (props.variant === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)')};
    border-radius: 6px;
    animation: ${fadeInOut} 2s infinite;
`;

const EmptyState = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const PlaceholderArtwork = styled.div`
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-xsmall);
    color: #fff;
    border-radius: 8px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
`;

const TemplatesButton = styled(Link)`
    justify-self: flex-start;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 100%;
    background: var(--color-background);
    color: var(--color-primary);
    border: none;
    border-radius: 8px;
    padding: 0 16px;
    cursor: pointer;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    text-decoration: none;
`;

const LoadingInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const LoadingTitle = styled.p<{ variant: 'dark' | 'light' }>`
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-small-bold);
    line-height: 1;
    margin: 0;
    color: ${(props) => (props.variant === 'dark' ? 'var(--color-primary)' : 'rgba(255, 255, 255, 1)')};
`;

const LoadingDescription = styled.div<{ variant: 'dark' | 'light' }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${(props) => (props.variant === 'dark' ? 'var(--color-muted)' : 'rgba(255, 255, 255, 0.75)')};
`;

const LoadingSubtitle = styled.p`
    font-size: var(--font-size-xsmall);
    line-height: 1;
    margin: 0;
`;

const LoadingMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

type MinimalScoreTemplate = Pick<
    ScoreTemplate,
    'id' | 'name' | 'subtitle' | 'intensity' | 'modality' | 'durationMins' | 'emotionalities'
>;
type TemplateCardLoaderStateLoading = { state: 'loading' };
type TemplateCardLoaderStateLoaded<T extends MinimalScoreTemplate> = {
    state: 'loaded';
    template: T;
};
type TemplateCardLoaderStateEmpty = { state: 'empty' };
type TemplateCardLoaderState<T extends MinimalScoreTemplate> =
    | TemplateCardLoaderStateLoading
    | TemplateCardLoaderStateLoaded<T>
    | TemplateCardLoaderStateEmpty;

export const TemplateCardLoader: React.FC<{
    state: TemplateCardLoaderState<MinimalScoreTemplate>;
    variant?: 'dark' | 'light';
    isQuickStart?: boolean;
    onCardClick: () => void;
}> = ({ state, variant = 'dark', isQuickStart = false, onCardClick }) => {
    return (
        <AnimatePresence exitBeforeEnter>
            {state.state === 'loading' && (
                <motion.div
                    key="loading"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    style={{ width: '100%' }}
                >
                    <LoadingContainer>
                        <LoadingArtwork variant={variant} />
                        <LoadingInfo>
                            <LoadingTitle variant={variant} />
                            <LoadingDescription variant={variant} />
                            <LoadingSubtitle />
                            <LoadingMeta />
                        </LoadingInfo>
                    </LoadingContainer>
                </motion.div>
            )}
            {state.state === 'loaded' && (
                <motion.div
                    key={state.template.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    style={{ width: '100%' }}
                >
                    <TemplateCard
                        key={'template-' + state.template.id}
                        onCardClick={onCardClick}
                        title={state.template.name}
                        subtitle={state.template.subtitle}
                        intensity={state.template.intensity}
                        modality={state.template.modality}
                        minDurationMins={Math.ceil(state.template.durationMins.min)}
                        maxDurationMins={Math.floor(state.template.durationMins.max)}
                        emotionalities={state.template.emotionalities}
                        id={state.template.id}
                        variant={variant}
                        isQuickStart={isQuickStart}
                    />
                </motion.div>
            )}
            {state.state === 'empty' && (
                <motion.div
                    key="empty"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    style={{ width: '100%' }}
                >
                    <EmptyState>
                        <PlaceholderArtwork>
                            Save a Template to
                            <br /> launch it from here
                        </PlaceholderArtwork>
                        <TemplatesButton to="/templates">Explore Templates</TemplatesButton>
                    </EmptyState>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export const ScoreTemplateCardLoader: React.FC<{
    templateId: string;
    fbUser: firebase.User;
    variant?: 'dark' | 'light';
    quickStart?: boolean;
}> = ({ templateId, fbUser, variant = 'dark', quickStart = false }) => {
    const [state, setState] = useState<TemplateCardLoaderState<ScoreTemplate>>({ state: 'loading' });

    useEffect(() => {
        let isMounted = true;

        const getTemplate = async () => {
            try {
                const fetchedTemplate = await getTemplateById({ fbUser, id: templateId });
                if (isMounted) {
                    setState({ state: 'loaded', template: fetchedTemplate });
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Failed to fetch template:', error);
                    setState({ state: 'empty' });
                }
            }
        };

        if (templateId) {
            getTemplate();
        }

        return () => {
            isMounted = false;
        };
    }, [templateId, fbUser]);

    const onCardClick = async () => {
        if (state.state === 'loaded') {
            if (quickStart) {
                const scoreTemplate = state.template;
                const scoreLibrary = await api.loadScoreLibrary(fbUser);
                const variableInputs: SessionVariables = {
                    sessionUse: SessionScoreSessionUse.IN_PERSON,
                    name: '',
                    voiceover: 'none',
                    Acousticness: ClientVariablesMusicPreference.MIXED,
                    totalDuration: scoreTemplate.durationMins.min,
                };
                const attributeInputs = {
                    schedulingStyle: 'now',
                    unlisted: true,
                    canClientStartEarly: false,
                    scheduledStart: 1,
                };
                const templatePlan = planSessionFromPreset(scoreTemplate, scoreLibrary, variableInputs);
                const scoreFromTemplatePlan = scoreUtils.setWavepaths(
                    {
                        ...scoreTemplate,
                        showInMenu: true,
                        wavepaths: [],
                    },
                    templatePlan,
                );
                const createdSession = await sessionApi.startSession(
                    SessionType.ONE_ON_ONE,
                    SessionRenderType.PREDICTIVE_COMPOSED,
                    scoreFromTemplatePlan,
                    variableInputs,
                    attributeInputs,
                    [],
                    fbUser,
                    [],
                    ['Approved'],
                );
                window.location.href = `/session/${createdSession.id}`;
            } else {
                window.location.href = '/templates/' + state.template.id;
            }
        }
    };

    return <TemplateCardLoader state={state} variant={variant} onCardClick={onCardClick} />;
};

export const QuickStartTemplateCardLoader: React.FC<{
    savedTemplates?: ListSavedTemplatesItem[];
    fbUser: firebase.User;
    variant?: 'dark' | 'light';
}> = ({ savedTemplates, fbUser, variant = 'dark' }) => {
    const template = savedTemplates ? maxBy(savedTemplates, 'createdAt')! : null;
    const state: TemplateCardLoaderState<ListSavedTemplatesItem> = template
        ? { state: 'loaded', template }
        : savedTemplates && savedTemplates.length === 0
        ? { state: 'empty' }
        : { state: 'loading' };

    const [renderingType] = useSessionRenderingType();

    const onCardClick = async () => {
        if (state.state !== 'loaded') {
            return;
        }
        const savedTemplate = await getSavedTemplateById({ fbUser, id: state.template.id });
        const variableInputs = getDefaultVariableInputs(savedTemplate);

        const attributeInputs = {
            schedulingStyle: 'now',
            unlisted: template && !isNil(savedTemplate.unlisted) ? savedTemplate.unlisted : true,
            canClientStartEarly: savedTemplate.canClientStartEarly,
            scheduledStart: 1,
        };

        const createdSession = await sessionApi.startSession(
            SessionType.ONE_ON_ONE,
            renderingType!,
            savedTemplate.score,
            variableInputs,
            attributeInputs,
            [],
            fbUser,
            [],
            ['Approved'],
        );

        if (renderingType === SessionRenderType.PREDICTIVE_COMPOSED) {
            window.location.href = `/session/${createdSession.id}`;
        } else if (renderingType === SessionRenderType.PRE_RENDERED) {
            window.location.href = `/session/precomposed/${createdSession.id}`;
        } else {
            throw new Error('Unknown session type');
        }
    };

    return <TemplateCardLoader state={state} variant={variant} isQuickStart={true} onCardClick={onCardClick} />;
};
