import { useCallback, useContext } from 'react';
import { CoreEmotionalAtmosphere, RequestType, TherapeuticDirection } from 'wavepaths-shared/core';
import { convertVaToCea } from 'wavepaths-shared/domain/wavepath';

import { Connection, useRemoteCurrentWave } from '@/hooks/useSessionTick';

import UserEvents from '../../../UserEvents';
import { Queueable } from '../actionQueue/useActionQueue';
import { RemoteSessionControlsContext } from '../Guide';

const DEFAULT_TRANSITION_TIME = 45;
export function useRemoteCEA(
    connection: Connection,
    queueFunction: (queueable: Queueable) => void,
):
    | {
          targetCea?: CoreEmotionalAtmosphere;
          transitionTimeSecs?: number;
          setTargetCea: (cea: CoreEmotionalAtmosphere) => void;
      }
    | 'loading' {
    // const { connection } = useConnectionStore();
    const remoteSession = useContext(RemoteSessionControlsContext);
    if (remoteSession === undefined) throw new Error('no remoteSession');

    const currentWave = useRemoteCurrentWave(remoteSession.sessionScore?.wavepaths ?? null);

    const musicAttributeTargets = currentWave?.wave?.pathScore.stages[0].contentSwitch?.musicAttributeTargets;

    // Get wave's emotion by musicAttributeTargets
    const _targetCeaFallback =
        musicAttributeTargets !== undefined
            ? convertVaToCea(musicAttributeTargets['Valence Potential'], musicAttributeTargets['Arousal'])
            : undefined;

    // Get wave's emotion from the "emotion" field if it's defined
    const _targetCeaBase =
        currentWave.wave?.pathScore && 'emotion' in currentWave.wave?.pathScore
            ? currentWave.wave?.pathScore.emotion
            : undefined;

    // Use value from _targetCeaBase if it's defined, otherwise use emotion from _targetCeaFallback
    const _targetCea = _targetCeaBase
        ? typeof _targetCeaBase === 'object'
            ? _targetCeaBase.to
            : _targetCeaBase
        : _targetCeaFallback;

    const transitionTimeSecs = DEFAULT_TRANSITION_TIME;

    const setTargetCea = useCallback(
        (cea: CoreEmotionalAtmosphere, whenQueued?: () => void) => {
            // Identify the silence wave by direction
            const isSilenceDirection = currentWave.wave?.pathScore.direction === TherapeuticDirection.SILENCE;

            if (_targetCea !== cea || isSilenceDirection) {
                queueFunction({
                    description: `Transitioning to ${cea}`,
                    callback: () => {
                        connection.sendRequest({ type: RequestType.MoveToCea, cea });
                        whenQueued && whenQueued();
                    },
                    onCancel: () => UserEvents.ceaSelectionCanceled(cea),
                    onSkipQueue: () => UserEvents.ceaSelectionSkippedQueue(cea),
                });
                UserEvents.ceaSelected(cea);
            }
        },
        [connection, queueFunction, _targetCea],
    );

    if (currentWave.wave === undefined) return 'loading';
    if (currentWave.wave?.pathScore === undefined) return 'loading';
    if (!currentWave.wave?.pathScore?.stages.length) return 'loading';

    return {
        targetCea: _targetCea,
        transitionTimeSecs,
        setTargetCea,
    };
}
