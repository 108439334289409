import styled from '@emotion/styled';
import React from 'react';

import { ResourceRichEditor } from './ResourceRichEditor';
import { Resource } from './useResources';

const Container = styled.div`
    position: relative;
`;

const Header = styled.div`
    padding: 16px 0 var(--global-spacing) 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-top: 4px solid var(--color-background-highlight);
`;

const Tag = styled.p`
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 8px 0;
`;

type ResourceProps = React.PropsWithChildren<{
    resource: Resource;
    isExpanded?: boolean;
    onClick?: () => void;
}>;

export function Resource({ resource, isExpanded = false, onClick, children }: ResourceProps) {
    return (
        <Container>
            <Header onClick={onClick}>
                <Tag>{resource.tag}</Tag>
                <h3>{resource.shortDescription}</h3>
            </Header>
            {isExpanded && resource.description && (
                <ResourceRichEditor value={resource.description} isEditable={false} />
            )}
            {children}
        </Container>
    );
}
