import { findLast } from 'lodash';
import { useEffect, useState } from 'react';
import { LayerNumber } from 'wavepaths-shared/core';

import configs from '../../configs';
import { WavePreviewState } from './useWavePreview';

const VOL_SAMPLE_RATE = 48000;
const VOL_FRAME_SIZE = 960;

export type SessionVisualPreview = {
    volume: RawVolChunk[];
    finished: boolean;
};

type RawVolChunk = {
    numFrames: number;
    vols: Partial<Record<LayerNumber, Int8Array>>;
};

export function useSessionVisualPreview(previewState: WavePreviewState): SessionVisualPreview {
    const [state, setState] = useState<SessionVisualPreview>({ volume: [], finished: false });
    useEffect(() => {
        if (previewState.state !== 'created') {
            setState({ volume: [], finished: true });
            return;
        }
        let present = true;

        const broadcastIdentifier = previewState.broadcastIdentifier;
        const sessionId = previewState.sessionId;
        const fromTime = previewState.timeWindow.fromTime / 1000;
        const toTime = previewState.timeWindow.toTime / 1000;

        setState({ volume: [], finished: false });

        const streamBaseUrl = `${configs.freud.STREAM_BASE}/${broadcastIdentifier}/${sessionId}`;
        const playlistUrl = `${streamBaseUrl}/stream.m3u8`;

        async function fetchVolData(chunkFilename: string) {
            const volFilename = chunkFilename.replace('.ogg', '-vol.dat');
            const volUrl = `${streamBaseUrl}/${volFilename}`;
            const data = await fetch(volUrl).then((res) => res.arrayBuffer());
            return decodeVolData(data);
        }

        const chunksHandled = new Set<string>();
        const allData: { t: number; data: RawVolChunk }[] = [];

        async function fetchPlaylist(chunkMeta: { filename: string; duration: number }[]) {
            let hadNewChunks = false;
            let t = 0;
            for (const { filename, duration } of chunkMeta) {
                if (!chunksHandled.has(filename) && t + duration > fromTime && t < toTime) {
                    allData.push({ t, data: await fetchVolData(filename) });
                    chunksHandled.add(filename);
                    hadNewChunks = true;
                }
                t += duration;
            }
            return hadNewChunks;
        }

        function getVolumeWindow(): RawVolChunk[] {
            const result: RawVolChunk[] = [];
            for (const chunk of allData) {
                if (chunk.t > toTime) {
                    break;
                } else if (chunk.t >= fromTime) {
                    result.push(chunk.data);
                } else if (chunk.t + getChunkDuration(chunk.data) > fromTime) {
                    const timeToTake = fromTime - chunk.t;
                    const sampleFramesToTake = timeToTake * VOL_SAMPLE_RATE;
                    const volFramesToTake = Math.floor(sampleFramesToTake / VOL_FRAME_SIZE);
                    const volKeys = Object.keys(chunk.data.vols).map((key) => parseInt(key)) as LayerNumber[];
                    const volWindows: Partial<Record<LayerNumber, Int8Array>> = {};
                    for (const layerNumber of volKeys) {
                        const chunkVols = chunk.data.vols[layerNumber]!;
                        volWindows[layerNumber] = chunkVols.subarray(chunkVols.length - volFramesToTake);
                    }
                    result.push({
                        numFrames: volFramesToTake,
                        vols: volWindows,
                    });
                }
            }
            return result;
        }

        async function fetchNext() {
            if (!present) return;
            const res = await fetch(playlistUrl);
            if (res.ok) {
                const content = await res.text();
                const { chunks, isFinished } = parsePlaylist(content);
                if (!present) return;
                const didFetchNewChunks = await fetchPlaylist(chunks);
                setState({
                    volume: getVolumeWindow(),
                    finished: isFinished,
                });
                if (!isFinished) {
                    setTimeout(fetchNext, didFetchNewChunks ? 100 : 1000);
                }
            } else {
                setTimeout(fetchNext, 1000);
            }
        }
        fetchNext();

        return () => {
            present = false;
        };
    }, [previewState]);

    return state;
}

function parsePlaylist(content: string): { chunks: { filename: string; duration: number }[]; isFinished: boolean } {
    const lines = content.split('\n');
    const chunks: { filename: string; duration: number }[] = [];
    for (let i = 0; i < lines.length - 1; i++) {
        const line = lines[i];
        const nextLine = lines[i + 1];
        if (line.startsWith('#EXTINF:') && nextLine.endsWith('.ogg')) {
            const durationMatch = line.match(/#EXTINF:(\d+\.\d+)/);
            const duration = durationMatch ? parseFloat(durationMatch[1]) : 0;
            chunks.push({
                filename: nextLine,
                duration: duration,
            });
            i++;
        }
    }
    const isFinished = findLast(lines, (line) => line.includes('#EXT-X-ENDLIST')) !== undefined;
    return { chunks, isFinished };
}

function decodeVolData(data: ArrayBuffer): RawVolChunk {
    const view = new DataView(data);
    let offset = 0;
    const numVolFrames = view.getInt32(offset, true);
    offset += 4;
    const numLayers = view.getInt8(offset);
    offset += 1;
    const layerNumbers: LayerNumber[] = [];
    for (let i = 0; i < numLayers; i++) {
        layerNumbers.push(view.getInt8(offset) as LayerNumber);
        offset += 1;
    }
    const vols: Partial<Record<LayerNumber, Int8Array>> = {};
    for (let i = 0; i < numLayers; i++) {
        const layerNumber = layerNumbers[i];
        vols[layerNumber] = new Int8Array(data, offset, numVolFrames);
        offset += numVolFrames;
    }

    return {
        numFrames: numVolFrames,
        vols,
    };
}

function getChunkDuration(chunk: RawVolChunk): number {
    return (chunk.numFrames * VOL_FRAME_SIZE) / VOL_SAMPLE_RATE;
}

export function getSecondsRendered(state: SessionVisualPreview): number {
    return state.volume.reduce((acc, chunk) => acc + getChunkDuration(chunk), 0);
}

export function getWaveformData({
    preview,
    layerGroup,
    secondsPerMeasurement,
}: {
    preview: SessionVisualPreview;
    layerGroup: LayerNumber[];
    secondsPerMeasurement: number;
}): number[] {
    const secondsPerFrame = VOL_FRAME_SIZE / VOL_SAMPLE_RATE;

    let frameIndex = 0;
    const measurementSums: number[] = [];
    const measurementFrameCounts: number[] = [];

    for (const chunk of preview.volume) {
        for (let i = 0; i < chunk.numFrames; i++) {
            let frameLinearGain = 0;
            for (const layerNumber of layerGroup) {
                const layerVols = chunk.vols[layerNumber];
                if (layerVols) {
                    const dbfs = layerVols[i];
                    frameLinearGain += Math.pow(10, dbfs / 20);
                }
            }

            const measurementIndex = Math.floor((frameIndex * secondsPerFrame) / secondsPerMeasurement);
            while (measurementSums.length <= measurementIndex) {
                measurementSums.push(0);
                measurementFrameCounts.push(0);
            }
            measurementSums[measurementIndex] += frameLinearGain;
            measurementFrameCounts[measurementIndex]++;
            frameIndex++;
        }
    }

    const measurements = measurementSums.map((sum, index) => {
        const frameCount = measurementFrameCounts[index];
        if (frameCount === 0) return -100;
        const avgLinearGain = sum / frameCount;
        return avgLinearGain > 0 ? 20 * Math.log10(avgLinearGain) : -100;
    });

    return measurements;
}
