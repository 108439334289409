import { Badge, Button, IconButton, Popover } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Snackbar from '@/components/Snackbar';

import Bell from '../../images/icons/bell.svg';
import { NewsArticle, NewsArticleVariant } from '../../pages/news/NewsArticle';
import {
    NewsArticle as NewsArticleData,
    useNews,
    useNewsViewAllArticle,
    useNewsViewArticle,
} from '../../pages/news/useNews';

const MenuItem = styled.div`
    width: 100%;
    min-width: 160px;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    width: 400px;
    white-space: normal;

    &:hover {
        opacity: 0.8;
    }
`;

const Icon = styled.span`
    display: block;
    width: 16px;
    height: 16px;
    img {
        display: block;
        width: 100%;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-weight: 400;
    font-size: 16px;
`;

export const NotificationBell = () => {
    const [error] = useState<string | null>(null);
    const { news, refresh, updateNewsItem } = useNews();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const history = useHistory();
    const [snackbarContent, setSnackbarContent] = useState<string | null>(null);
    const closeSnackbar = useCallback(() => setSnackbarContent(null), []);

    const { mutate: view } = useNewsViewArticle({
        onSuccess: refresh,
    });
    const { mutate: viewAll } = useNewsViewAllArticle({
        onSuccess: () => {
            refresh();
            setSnackbarContent('All notifications marked as read');
        },
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            refresh();
        }, 60000);

        return () => clearInterval(intervalId);
    }, [refresh]);

    const newNotifications = news.filter((n) => !n.isViewed).length;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleArticleClick = async (article: NewsArticleData) => {
        if (article && !article.isViewed) {
            updateNewsItem({ ...article, isViewed: true });
            await view(article.id);
        }
        handleClose();
        history.push(`/news?article=${article.id}`);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMarkAllAsRead = async () => {
        const unreadArticles = news.filter((n) => !n.isViewed);
        if (unreadArticles) {
            handleClose();
            viewAll();
        }
    };

    const newsItems = useMemo(() => {
        return news.map((n) => (
            <MenuItem key={n.id} onClick={() => handleArticleClick(n)}>
                <NewsArticle article={n} variant={NewsArticleVariant.Compact} />
            </MenuItem>
        ));
    }, [news]);

    return (
        <>
            <IconButton onClick={handleClick}>
                <Badge badgeContent={newNotifications} color="error">
                    <Icon>
                        <img src={Bell} alt="Notifications" />
                    </Icon>
                </Badge>
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Header>
                    <span>News</span>
                    {newNotifications > 0 && (
                        <Button
                            size="small"
                            onClick={handleMarkAllAsRead}
                            disabled={newNotifications === 0}
                            style={{ color: 'grey' }}
                        >
                            Mark all as read
                        </Button>
                    )}
                </Header>
                <div style={{ minWidth: 300, maxHeight: 310, overflowY: 'auto' }}>{newsItems}</div>
            </Popover>
            <Snackbar
                type={'error'}
                isLongButton={false}
                message={snackbarContent ?? ''}
                confirmText={'OK'}
                open={snackbarContent !== null}
                closeSnackbar={closeSnackbar}
            />
            {error && <div>Error: {error}</div>}
        </>
    );
};
